<div class="wrapper">
  <div class="form-section">
    <app-dropdown
      (checkForErrors)="checkForErrors($event)"
      [enableDetailedOptionView]="false"
      [enableSearch]="true"
      [error]="errorMessages['onSiteTest']"
      [options]="onSiteTestOptions"
      [parentFormGroup]="headerLevelForm"
      class="mb-[20px]"
      controlName="onSiteTest"
      label="OnSite Test"
      placeholder="Select"
    />
    <app-dropdown
      (checkForErrors)="checkForErrors($event)"
      [enableDetailedOptionView]="true"
      [enableSearch]="true"
      [error]="errorMessages['bid']"
      [options]="bidItemOptions"
      [parentFormGroup]="headerLevelForm"
      class="mb-[20px]"
      controlName="bidItem"
      label="Bid Item"
      placeholder="Select"
    />
    <app-dropdown
      (checkForErrors)="checkForErrors($event)"
      [enableDetailedOptionView]="false"
      [enableSearch]="true"
      [error]="errorMessages['heading']"
      [options]="headingsOptions"
      [parentFormGroup]="headerLevelForm"
      class="mb-[20px]"
      controlName="heading"
      label="heading"
      placeholder="Select"
    />
  </div>
  <ng-container *ngIf="internalTestWithStation; else internalTestWithoutStation">
    <app-internal-test-details-with-station
      [data]="internalTestDetails"
      [form]="form"
    />
  </ng-container>
  <ng-template #internalTestWithoutStation>
    <app-internal-test-details-without-station
      [data]="internalTestDetails"
      [form]="form"
    />
  </ng-template>
</div>
