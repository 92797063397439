import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-test-summary-internal',
  templateUrl: './material-test-summary-internal.component.html',
  styleUrls: ['./material-test-summary-internal.component.scss'],
})
export class MaterialTestSummaryInternalComponent implements OnInit {

  @Input() data;

  constructor() {
  }

  ngOnInit() {

  }

}
