<div class="wrapper">
  <!--  WEATHER  -->
  <app-daily-report-expandable-block
    (click)="handleExpansion('weather')"
    [isExpanded]="expandedSection === 'weather'"
    [subheading]="(weather?.avgtemp ? weather?.avgtemp + '&deg;F' : '') + (weather?.condition?.text ?
    ' ' + weather?.condition?.text : '')"
    heading="Weather"
    icon="daily-report-weather"
    id="weather"
  >
    <ng-container>
      <ng-container *ngIf="weather && Object.keys(weather).length > 0; else noWeatherData">
        <div (click)="$event.stopPropagation()" class="panel weather-panel">
          <app-weather-summary
            (click)="triggerOpenDetailsPanel('weather', weather)"
            [data]="weather"
          />
        </div>
      </ng-container>
      <ng-template #noWeatherData>
        <div class="no-data">
          No weather data available.
        </div>
      </ng-template>
    </ng-container>
  </app-daily-report-expandable-block>

  <hr class="divider" />

  <!--  BID ITEM  -->
  <app-daily-report-expandable-block
    (click)="handleExpansion('bid-item')"
    [isExpanded]="expandedSection === 'bid-item'"
    [subheading]="bidItems.length + ' item' + (bidItems.length !== 1 ? 's' : '')"
    heading="Bid item"
    icon="daily-report-bid-item"
    id="bid-item"
  >
    <ng-container>
      <ng-container *ngIf="bidItems.length; else noBidItemData">
        <div (click)="$event.stopPropagation()" class="panel bid-items-panel">
          <ng-container
            *ngFor="let eachBidItem of bidItems"
          >
            <app-station-wise-bid-item-summary
              (click)="eachBidItem.id !== selectedId && triggerOpenDetailsPanel('bidItem', eachBidItem)"
              *ngIf="eachBidItem.stations !== undefined"
              [class.selected]="eachBidItem.id === selectedId"
              [data]="eachBidItem"
            />
            <app-multiline-bid-item-summary
              (click)="eachBidItem.id !== selectedId && triggerOpenDetailsPanel('bidItem', eachBidItem)"
              *ngIf="eachBidItem.lineItems !== undefined"
              [class.selected]="eachBidItem.id === selectedId"
              [data]="eachBidItem"
            />
          </ng-container>
        </div>
      </ng-container>
      <ng-template #noBidItemData>
        <div class="no-data">
          No bid item data available.
        </div>
      </ng-template>
    </ng-container>
  </app-daily-report-expandable-block>

  <hr class="divider" />

  <!--  DELIVERY-USAGE  -->
  <app-daily-report-expandable-block
    (click)="handleExpansion('delivery-usage')"
    [isExpanded]="expandedSection === 'delivery-usage'"
    [subheading]="(deliveriesCount + usagesCount) + ' item' + ((deliveriesCount + usagesCount) === 1 ? '' : 's')"
    heading="Delivery & usage"
    icon="daily-report-material-delivery"
    id="delivery-usage"
  >
    <div (click)="$event.stopPropagation()">
      <ng-container *ngIf="(deliveriesCount + usagesCount) > 0; else noDeliveryUsageData">
        <mat-tab-group
          #deliveryUsageTabs
          [selectedIndex]="expandedSectionTabIndex"
          class="contents-tab-group"
        >
          <mat-tab
            [label]="'Delivery'"
          >
            <ng-container *ngIf="deliveriesCount; else noDeliveryData">
              <div class="panel delivery-panel">
                <ng-container *ngFor="let delivery of deliveries">
                  <app-material-delivery-and-usage-summary
                    (click)="delivery.id !== selectedId && triggerOpenDetailsPanel('delivery', delivery)"
                    [class.selected]="delivery.id === selectedId"
                    [data]="delivery"
                  />
                </ng-container>
              </div>
            </ng-container>
            <ng-template #noDeliveryData>
              <div class="no-data">
                No delivery available.
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab
            [label]="'Usage'"
          >
            <ng-container *ngIf="usagesCount; else noUsageData">
              <div class="panel usage-panel">
                <ng-container *ngFor="let usage of usages">
                  <app-material-delivery-and-usage-summary
                    (click)="usage.id !== selectedId && triggerOpenDetailsPanel('usage', usage)"
                    [class.selected]="usage.id === selectedId"
                    [data]="usage"
                  />
                </ng-container>
              </div>
            </ng-container>
            <ng-template #noUsageData>
              <div class="no-data">
                No usage available.
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
      <ng-template #noDeliveryUsageData>
        <div class="no-data">
          No delivery and usage available.
        </div>
      </ng-template>
    </div>
  </app-daily-report-expandable-block>

  <hr class="divider" />

  <!--  MATERIAL-TESTING  -->
  <app-daily-report-expandable-block
    (click)="handleExpansion('material-testing')"
    [isExpanded]="expandedSection === 'material-testing'"
    [subheading]="openExternalTestCount + ' Open'+' '+  closedExternalTestCount + ' Closed' +' '+ internalMaterialTestingCount + ' Onsite'"
    heading="Material testing"
    icon="daily-report-material-testing"
    id="material-testing"
  >

    <ng-container *ngIf="(internalMaterialTestingCount + externalMaterialTestingCount) > 0; else noMaterialTestingData">
      <mat-tab-group
        #tabGroup
        (click)="$event.stopPropagation()"
        class="contents-tab-group"
      >
        <mat-tab label="Internal">
          <ng-container *ngIf="internalMaterialTestingCount; else noInternalData">
            <div (click)="$event.stopPropagation()" class="panel internal-panel">
              <ng-container *ngFor="let eachInternalTest of materialTesting?.internal?.['groupedInternalTests']">
                <app-material-test-summary-internal
                  (click)="eachInternalTest.id !== selectedId && triggerOpenDetailsPanel('internalTest', eachInternalTest)"
                  [class.selected]="eachInternalTest.id === selectedId"
                  [data]="eachInternalTest"
                />
              </ng-container>
            </div>
          </ng-container>
          <ng-template #noInternalData>
            <div class="no-data">
              No internal tests available.
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="External">
          <ng-container *ngIf="externalMaterialTestingCount; else noExternalData">
            <div (click)="$event.stopPropagation()" class="panel external-panel">
              External tests
            </div>
          </ng-container>
          <ng-template #noExternalData>
            <div class="no-data">
              No external tests available.
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-template #noMaterialTestingData>
      <div class="no-data">
        No material testing data available.
      </div>
    </ng-template>

  </app-daily-report-expandable-block>

  <hr class="divider" />

  <!--  ISSUES  -->
  <app-daily-report-expandable-block
    (click)="handleExpansion('issues')"
    [isExpanded]="expandedSection === 'issues'"
    [subheading]="issues.length + ' item' + (issues.length === 1 ? '' : 's')"
    heading="Issues"
    icon="daily-report-issues"
    id="issues"
  >
    <ng-container>
      <ng-container *ngIf="issues.length; else noIssuesData">
        <ng-container *ngIf="openIssues.length">
          <div class="category-heading my-[20px] text-type-6">
            Open :
          </div>
          <div
            (click)="$event.stopPropagation()" [class.collapsed]="!isOpenIssuesPanelExpanded && openIssues?.length > 4"
            class="panel open-issues-panel"
          >
            <ng-container *ngFor="let issue of openIssues">
              <app-issue-summary
                (click)="triggerOpenDetailsPanel('issue', issue)"
                [class.selected]="issue.id === selectedId"
                [issue]="issue"
              />
            </ng-container>
          </div>
          <div
            (click)="isOpenIssuesPanelExpanded = !isOpenIssuesPanelExpanded; $event.stopPropagation()"
            *ngIf="openIssues?.length > 4"
            class="see-more text-type-9 flex"
          >
            {{ isOpenIssuesPanelExpanded ? 'See less' : 'See more' }}
          </div>
        </ng-container>
        <ng-container *ngIf="closedIssues.length">
          <div class="category-heading my-[20px] text-type-6">
            Closed :
          </div>
          <div (click)="$event.stopPropagation()"
               [class.collapsed]="!isClosedIssuesPanelExpanded && closedIssues?.length > 4"
               class="panel closed-issues-panel"
          >
            <ng-container *ngFor="let issue of closedIssues">
              <app-issue-summary
                (click)="triggerOpenDetailsPanel('issue', issue)"
                [class.selected]="issue.id === selectedId"
                [issue]="issue"
              />
            </ng-container>
          </div>
          <div
            (click)="isClosedIssuesPanelExpanded = !isClosedIssuesPanelExpanded; $event.stopPropagation()"
            *ngIf="closedIssues?.length > 4"
            class="see-more text-type-9 flex"
          >
            {{ isClosedIssuesPanelExpanded ? 'See less' : 'See more' }}
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noIssuesData>
        <div class="no-data">
          No issues data available.
        </div>
      </ng-template>
    </ng-container>
  </app-daily-report-expandable-block>

  <hr class="divider" />

  <!--  LABOR-EQUIPMENT  -->
  <app-daily-report-expandable-block
    (click)="handleExpansion('labor-equipment')"
    [isExpanded]="expandedSection === 'labor-equipment'"
    [subheading]="laborCount + ' personnel,' + ' ' + equipmentCount + ' equipment'"
    heading="Labor & equipment"
    icon="daily-report-labor-equipment"
    id="labor-equipment"
  >
    <div (click)="$event.stopPropagation()">
      <mat-tab-group
        #laborEquipmentTabs
        [selectedIndex]="expandedSectionTabIndex"
        class="contents-tab-group"
      >
        <mat-tab
          [label]="'Labor'"
        >
          <ng-container *ngIf="laborCount; else noLaborData">
            <div class="panel delivery-panel">
              <ng-container *ngFor="let labor of labors">
                <app-labor-summary
                  (click)="labor.id !== selectedId && triggerOpenDetailsPanel('labor', labor)"
                  [class.selected]="labor.id === selectedId"
                  [data]="labor"
                />
              </ng-container>
            </div>
          </ng-container>
          <ng-template #noLaborData>
            <div class="no-data">
              No labor available.
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab
          [label]="'Equipment'"
        >
          <ng-container *ngIf="equipmentCount; else noEquipmentData">
            <div class="panel usage-panel">
              <ng-container *ngFor="let equipment of equipments">
                <app-labor-summary
                  (click)="equipment.id !== selectedId && triggerOpenDetailsPanel('equipment', equipment)"
                  [class.selected]="equipment.id === selectedId"
                  [data]="equipment"
                />
              </ng-container>
            </div>
          </ng-container>
          <ng-template #noEquipmentData>
            <div class="no-data">
              No equipment available.
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>

  </app-daily-report-expandable-block>

  <hr class="divider" />

  <!-- ACTIVITIES -->
  <app-daily-report-expandable-block
    (click)="handleExpansion('activities')"
    [isExpanded]="expandedSection === 'activities'"
    heading="Activities"
    icon="daily-report-activity"
    id="activities"
    subheading="{{activities.length}} {{activities.length === 1 ? 'entry' : 'entries'}} "
  >
    <ng-container>
      <ng-container *ngIf="activities.length; else noActivitiesData">
        <div (click)="$event.stopPropagation()" class="panel activities-panel">
          <ng-container
            *ngFor="let eachActivity of activities; let i = index"
          >
            <app-activity-summary
              (click)="eachActivity.id !== selectedId && triggerOpenDetailsPanel('activity', eachActivity, i+1)"
              [class.selected]="eachActivity.id === selectedId"
              [data]="eachActivity"
            />
          </ng-container>
        </div>
      </ng-container>
      <ng-template #noActivitiesData>
        <div class="no-data">
          No activities data available.
        </div>
      </ng-template>
    </ng-container>
  </app-daily-report-expandable-block>

</div>
