import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { MapService } from '../../../../../shared/services/map.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { dailyReport, dailyReportsByDate, projectDetails, siteDetails } from '../../../core/projects.selectors';
import Extent from '@arcgis/core/geometry/Extent';
import { ConfirmDialogComponent, ConfirmDialogModel, EsriMapComponent } from '../../../../../shared/components';
import { SiteMaterialTestService } from '../../../../project/material-test/services';
import * as moment from 'moment/moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ProjectsService } from '../../../core/projects.service';
import { DailyReportService } from '../../../pages/site-details-page/tab-screens/daily-report/daily-report.service';
import {
  ClearDailyReportRequest,
  FetchDailyReportRequest,
  FetchDailyReportsByDateRequest,
  FetchProjectDetailsRequest,
} from '../../../core/projects.actions';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-site-details-map',
  templateUrl: './site-details-map.component.html',
  styleUrls: ['./site-details-map.component.scss'],
  animations: [
    trigger('legendAnimation', [
      state(
        'visible',
        style({
          transform: 'translate(0%, 0%) scale(1)',
          opacity: 1,
        }),
      ),
      state(
        'void, hidden',
        style({
          transform: 'translate(-30%, 25%) scale(0.4)',
          opacity: 0,
        }),
      ),
      transition('* => visible', animate('250ms')),
      transition('* => void, * => hidden', animate('250ms')),
    ]),
  ],
})
export class SiteDetailsMapComponent implements OnInit, OnDestroy {
  dailyReportsList: any[] = [];
  filteredDailyReportsList: any[] = [];
  @ViewChild('projectMap') projectMap: ElementRef;
  initialized = true;
  selectedDate;
  reportId = '';
  imageLoaded = true;
  showFilters = false;
  noLocatioImageList: any[] = [];
  showSidenav = false;
  mapOptions: any = {
    zoom: 16,
    height: '100%',
  };
  mapComponentInstance: any;
  showPopup = false;
  popupContent: any[];
  x: number;
  y: number;
  markersList: any[] = [];
  ghostLocations: any[] = [];
  alignmentPath: any[] = [];
  legendData = [
    {
      category: 'layers',
      label: 'Layers',
      isSelected: false,
      children: [
        {
          key: 'boundary',
          label: 'Boundary',
          icon: '',
          isSelected: true,
        },
        {
          key: 'alignment',
          label: 'Alignment',
          icon: '',
          isSelected: true,
        },
      ],
    },
    {
      category: 'types',
      label: 'Types',
      isSelected: true,
      children: [
        {
          key: 'activities',
          label: 'Activities',
          icon: 'activities',
          isSelected: true,
        },
        {
          key: 'bid_items',
          label: 'Bid Items',
          icon: 'bid-item',
          isSelected: true,
        },
        {
          key: 'deliveries',
          label: 'Deliveries',
          icon: 'delivery',
          isSelected: true,
        },
        {
          key: 'issues',
          label: 'Issues',
          icon: 'issues',
          isSelected: true,
        },
        {
          key: 'labor_equipment',
          label: 'Labor & Equipment',
          icon: 'labor-equipment',
          isSelected: true,
        },
        {
          key: 'quality_test',
          label: 'Material Testing',
          icon: 'quality',
          isSelected: true,
        },
      ],
    },
  ];
  screenPoint = {
    x: 0,
    y: 0,
  };
  selectedElement: any = null;
  mapPolygons = [];
  dataLevels = [];
  showTooltip = false;
  tooltipContent = '';
  project: any;
  site: any;
  dailyReports: any[];
  isFullscreen = false;
  isDateChange = false;
  mapLayers = [];
  isBaseLayerAdded = false;
  isExpanded = false;
  isExpanded1: boolean;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private siteMaterialTestService: SiteMaterialTestService,
    private mapService: MapService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private projectsService: ProjectsService,
    private dailyReportService: DailyReportService,
    public dialog: MatDialog,
  ) {
    this.mapService.showWrapperButtons.next(false);

    this.route.queryParams.subscribe(qp => {
      this.isFullscreen = 'fs' in qp;
      this.isDateChange = 'dc' in qp;

      if (this.isFullscreen && !this.isDateChange) {
        this.drawPointsOnMap();
      } else {
        this.createLevelOneMapData();
      }
    });
  }

  ngOnInit() {

    this.store.dispatch(FetchProjectDetailsRequest({
      payload: {
        projectId: this.route.snapshot.params.projectId,
        qp: {
          include: [
            'cover_photo',
            'owner_organization',
            'delivery_tracking_fields',
            'materials',
            {
              bid_items: [
                'rollup_fields',
                'materials',
                {
                  fields: ['recent_fields'],
                },
                {
                  headings: [
                    'stations',
                    'alignment',
                  ],
                },
              ],
            },
            {
              internal_tests: [
                'bid_item_ids',
                'site_ids',
                'fields',
              ],
            },
            {
              sites: [
                'delivery_tracking_fields',
                {
                  headings: ['stations'],
                },
              ],
            },
          ],
        },
      },
    }));

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (
          qp.date === undefined ||
          (
            qp.date &&
            !moment(qp.date, 'YYYY-MM-DD').isValid()
          )
        ) {
          this.router.navigate([], {
            queryParams: {
              date: moment().format('YYYY-MM-DD'),
            },
            queryParamsHandling: 'merge',
          });
        } else if (
          qp.date &&
          moment(qp.date, 'YYYY-MM-DD').isValid() &&
          qp.date !== this.selectedDate
        ) {
          this.selectedDate = qp.date;
          this.fetchReportsList();
        }

        if (!qp.reportId) {
          this.reportId = '';
          if (qp.fs === undefined || qp.dc === '1') {
            this.store.dispatch(ClearDailyReportRequest());
          }
        } else {
          if (qp.reportId !== this.reportId) {
            this.reportId = qp.reportId;
            this.fetchReportById(this.reportId);
          }
        }
      });

    this.dailyReportService.fetchDailyReportById
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.fetchReportById(this.reportId);
      });

    this.mapService.seeMore.pipe(takeUntil(this.onDestroy)).subscribe(data => {
      this.showPopup = false;
      this.showFilters = false;
      if (data?.type !== 'bid_items' && data?.type !== 'activities' && data?.markerCategory !== 'issues') {
        return;
      }
      if (data?.markerCategory === 'issues') {
        data.type = 'issues';
      }
      if ((!data?.child || data.child.length === 0) && data?.children?.length && (data.location === undefined || data.location === true)) {
        // Show Level Two Markers
        const params = {
          projectId: data.projectId,
          siteId: data.siteId,
          reportId: data.reportId,
          type: data.type,
          children: data.children.map(o => ({
            ...o,
            parent: data,
          })),
        };

        const levelTwoData: any = this.mapService.createLevelTwoData(params);
        this.removeAllMarkers();

        this.markersList = levelTwoData.markersList;
        this.ghostLocations = [];

        this.dataLevels = [...this.dataLevels, levelTwoData];

        this.drawPointsOnMap([], [], false, true);
      } else {

        let params = {
          projectId: data.projectId,
          siteId: data.siteId,
          reportId: data.reportId,
          type: data.type,
          element: data,
        };


        if (data?.child !== undefined) {

          params = {
            projectId: data.projectId,
            siteId: data.siteId,
            reportId: data.child.id,
            type: data.type,
            element: {
              ...data.child,
              children: data.children || undefined,
            },
          };

        }

        // Open Sidenav and show Level Three markers

        const levelThreeData: any = this.mapService.createLevelThreeData(params);
        const imagesList = levelThreeData.imagesList;
        this.noLocatioImageList = levelThreeData.noLocatioImageList;
        this.markersList = levelThreeData.markersList;

        this.dataLevels = [...this.dataLevels, levelThreeData];

        this.removeAllMarkers();
        this.ghostLocations = [];
        this.drawPointsOnMap(imagesList, this.noLocatioImageList,false,false,data);

        if (!this.markersList.length) {
          this.mapService.openSidenavOnMarkerClick(data);
        }

        if (this.markersList.length === 1 && this.markersList[0].data.level === 3) {
          this.mapService.openSidenavOnMarkerClick(data);
        }
        this.selectedElement = data.parent || data;
      }
    });

    this.mapService.closeSidenav.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.showSidenav = false;
    });

    this.mapService.closeFilters.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      setTimeout(() => {
        this.showFilters = false;
      }, 200);
    });

    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(project => {
        this.project = project;
      });

    this.store.select(siteDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(site => {
        this.site = JSON.parse(JSON.stringify(site));
        this.alignmentPath = this.mapService.createSiteAlignments(this.site);

        // TODO :: When map layers are added, they need to be added here.
        this.mapLayers?.forEach(layer => {
          const legendObj = {
            key: layer.type,
            label: layer.name,
            icon: '',
            isSelected: true,
          };

          const index = this.legendData.findIndex(o => o.category === 'layers');

          if (index > -1) {
            this.legendData[index].children.push(legendObj);
          }

          this.mapPolygons = [
            ...this.mapPolygons,
            {
              geoJson: site.geometry?.coordinates,
              type: 'site',
              id: site.id,
            },
          ];

          site?.headings?.forEach((o) => {
            const alignments = [];
            o.stations.forEach(s => {
              if (s?.properties?.Longitude && s?.properties?.Latitude) {
                alignments.push({
                  geometry: {
                    type: 'point',
                    longitude: s.properties.Longitude,
                    latitude: s.properties.Latitude,
                  },
                  symbol: 'alignment',
                  data: s,
                });
              } else {
                // console.log(s);
              }
            });
            this.alignmentPath.push(alignments);
          });

          this.drawMapPolygons();
          this.drawPointsOnMap();
        });
      });

    this.store.select(dailyReport)
      .pipe(
        filter(o => !!o.id),
        takeUntil(this.onDestroy),
      )
      .subscribe(report => {
        this.dailyReports = [JSON.parse(JSON.stringify(report))];
        if (this.dailyReports.length) {
          let externalTests = [];
          this.siteMaterialTestService
            .getRecords('project', { id: this.dailyReports[0]?.project_id }, { pageIndex: 0, pageSize: 1000 })
            .then(data => {
              externalTests = data.result || [];
            })
            .catch(() => {
              externalTests = [];
            })
            .finally(() => {
              if (this.dailyReports.length && this.dailyReports[0]) {
                // @ts-ignore
                this.dailyReports[0].external_tests = externalTests.filter(
                  test => moment(test.updated_at).isSame(moment(), 'week') && test.latitude && test.longitude,
                );
                this.createLevelOneMapData();
              }
            });
        }

        this.createLevelOneMapData();
      });

    this.store.select(dailyReportsByDate)
      .pipe(tap(data => console.log('Selector data:', data)), takeUntil(this.onDestroy))
      .subscribe(dailyReports => {
        console.log('Inside subscription:', dailyReports);
        this.dailyReportsList = dailyReports;
        this.filterReportsList();
      });

  }

  fetchReportsList() {
    this.router.navigate([], {
      queryParams: {
        date: this.selectedDate,
        idx: null,
      },
      queryParamsHandling: 'merge',
    }).then(() => {
      if (this.route.snapshot.queryParams.fs === undefined || this.route.snapshot.queryParams.dc === '1') {
        this.store.dispatch(ClearDailyReportRequest());
      }
      this.store.dispatch(FetchDailyReportsByDateRequest({
        payload: {
          project: { id: this.route.snapshot.params.projectId },
          site: this.route.snapshot.params.siteId,
          qp: {
            date: this.selectedDate,
            include: [
              'createdBy',
              'statusText',
              'site_id',
              'next-actions',
            ],
            total: 1000,
          },
        },
      }));
    });
  }

  handleDateChange(date) {
    const isFormDirty = this.projectsService.isAnyFormDirty.getValue();

    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes.<br/>Are you sure you want to discard?`,
        ),
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.projectsService.closeRightPanel.next();
          this.projectsService.isAnyFormDirty.next(false);
          this.router.navigate([], {
            queryParams: {
              date: moment(date).format('YYYY-MM-DD'),
              reportId: null,
            },
            queryParamsHandling: 'merge',
          }).then(() => {
            this.projectsService.isAnyFormDirty.next(false);
          });
        } else {
          this.selectedDate = new Date().getTime();
        }
      });
    } else {
      if (this.route.snapshot.queryParams.fs === undefined) {
        this.router.navigate([], {
          queryParams: {
            date: moment(date).format('YYYY-MM-DD'),
            fs: null,
            reportId: null,
          },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([], {
          queryParams: {
            date: moment(date).format('YYYY-MM-DD'),
            reportId: null,
            fs: 1,
            dc: 1,
          },
          queryParamsHandling: 'merge',
        });
      }
    }
  }

  fetchReportById(reportId: string) {
    this.store.dispatch(ClearDailyReportRequest());
    this.store.dispatch(FetchDailyReportRequest({
      payload: {
        qp: {
          include: [
            'project_id',
            'site_id',
            'site',
            'project',
            'next-actions',
            {
              bid_items: [
                'pictures',
                'videos',
                {
                  bid_item: ['fields'],
                },
                'heading',
                {
                  annotations: ['drawing'],
                },
                'bid_item_id',
                'heading_id',
                'station_id',
                'field_values',
                'station',
              ],
            },
            {
              material_deliveries: [
                'pictures',
                'videos',
                {
                  annotations: ['drawing'],
                },
                'field_values',
                'bid_item_id',
                'bid_item',
                'material',
                'latitude',
                'longitude',
                'field_values',
              ],
            },
            {
              activities: [
                'pictures',
                'videos',
                'address',
                {
                  annotation: ['drawing'],
                },
              ],
            },
            {
              internal_tests: [
                'bid_item_id',
                'internal_test_id',
                'station_id',
                'heading_id',
                'field_values',
                'pictures',
                'videos',
                {
                  annotations: ['drawing'],
                },
              ],
            },
            {
              issues: [
                'entity',
                'pictures',
                'videos',
                'attachments',
                'bid_item',
                'annotation',
                {
                  createdBy: ['picture'],
                },
                {
                  closedBy: ['picture'],
                },
              ],
            },
            {
              labor: [
                'sub_contractor',
              ],
            },
            {
              equipment: [
                'sub_contractor',
              ],
            },
          ],
        },
        reportId,
      },
    }));
  }

  filterReportsList() {
    if (this.site.id && this.dailyReportsList.length > 0) {
      this.filteredDailyReportsList = this.dailyReportsList.filter(o => o.site_id === this.site.id);
    } else {
      this.filteredDailyReportsList = [];
    }
    if (!this.reportId && this.filteredDailyReportsList.length > 0) {
      this.selectReportId(this.filteredDailyReportsList[0].id);
    }

  }

  selectReportId(reportId) {
    if (this.reportId === reportId) {
      reportId = null;
    }

    this.router.navigate([], {
      queryParams: {
        reportId,
        expandedSection: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  onMapComponentLoaded($event: EsriMapComponent) {
    this.mapComponentInstance = $event;

    // this.setInitialZoomAndCenter();
    this.mapComponentInstance.mapViewInstance.watch('zoom', () => {
      this.showPopup = false;
      this.showFilters = false;
    });


    this.drawMapPolygons();
    this.drawPointsOnMap();
  }

  createLevelOneMapData() {
    const mapData = this.mapService.createLevelOneMapData(this.dailyReports);
    this.markersList = mapData.markersList;
    this.ghostLocations = mapData.ghostLocations;

    this.dataLevels = [mapData];

    this.drawPointsOnMap();
  }

  setMapOptions() {
    if (this.site) {
      this.mapOptions.center = {
        lat: this.site?.latitude,
        lng: this.site?.longitude,
      };
      this.mapOptions.markers = [];

      this.mapOptions.markers.push({
        lat: this.site?.latitude,
        lng: this.site?.longitude,
        title: this.site?.name,
      });
    }
  }

  onMarkerClick($event: any) {
    this.mapService.closePicturesPanel.next();
    const category = $event?.graphic?.attributes?.category;
    this.commonService.updatelevelThreeClick(false);

    const data: any = {
      ...$event?.graphic?.attributes?.data,
      markerCategory: category,
    };

    this.screenPoint = $event?.screenPoint;

    if (data.level && data.level < 2) {
      switch (category) {
        case 'bid_items':
          this.popupContent = this.mapService.levelOneBidItemPopupData($event);
          break;
        case 'deliveries':
          this.popupContent = this.mapService.levelOneDeliveriesPopupData($event);
          if (this.popupContent?.length && this.popupContent[0]?.data?.markerData) {
            this.popupContent[0].data.markerData.fields = this.project.delivery_tracking_fields;
          }
          break;
        case 'activities':
          this.popupContent = this.mapService.levelOneActivitiesPopupData($event);
          break;
        case 'quality_test':
          this.popupContent = this.mapService.levelOneQualityPopupData($event);
          break;
        case 'issues':
          this.popupContent = this.mapService.levelOneIssuesPopupData($event);
          break;
        case 'labor_equipment':
          this.popupContent = this.mapService.levelOneLaborEquipmentPopupData($event);
          break;
        case 'grouped_locations':
          this.popupContent = this.mapService.levelOneGroupedLocationsPopupData($event);
          break;
        default:
          this.showPopup = false;
          this.popupContent = null;
      }

      this.commonService.levelThreeMarkerData.next(data);
      this.showPopup = true;
    }

    if (data.level && data.level >= 2) {
      if (data.type === 'image') {
        this.mapService.selectedPicturesData.next(data);
        this.mapService.openSidenavOnMarkerImageClick(data);
        return;
      }

      const params = {
        projectId: data.projectId,
        siteId: data.siteId,
        reportId: data.reportId,
        type: category,
        element: data,
      };

      const levelThreeData: any = this.mapService.createLevelThreeData(params);
      const imagesList = levelThreeData.imagesList;
      this.noLocatioImageList = levelThreeData.noLocatioImageList;

      this.removeAllMarkers();
      this.drawPointsOnMap(imagesList, this.noLocatioImageList, true);

      const element = data.test_type === 'external' ? data : data.parent || data;

      if (category !== 'images') {
        this.selectedElement = element;
      }

      setTimeout(() => {
        this.mapService.selectedMarkerData.next(data);
      }, 500);

      if (data.level > 1) {
        this.mapComponentInstance.mapViewInstance.center = {
          latitude: $event?.graphic?.geometry?.y,
          longitude: $event?.graphic?.geometry?.x,
        };

        this.mapComponentInstance.mapViewInstance.zoom = 22;
        if (this.dataLevels.length < 3) {
          this.dataLevels.push({
            markersList: this.markersList,
            ghostLocation: this.ghostLocations,
          });
        }
      }
      this.mapService.openSidenavOnMarkerClick(data);
      this.cdRef.detectChanges();
    }
  }

  onNoLocationImageClick() {
    this.mapService.selectedPicturesData.next(this.noLocatioImageList[0].data);
    this.mapService.openSidenavOnMarkerImageClick(this.noLocatioImageList[0].data);
  }

  onGhostLocationClick() {
    this.popupContent = this.mapService.levelOneGhostLocationsPopupData(this.ghostLocations);
    this.showPopup = true;
    this.screenPoint.x = 565;
    this.screenPoint.y = 56;
  }

  drawPointsOnMap(images = [], noLocatioImages = [], preventZoomReset = false, skipPolygon = false, levelThreeData = undefined) {
    try {
      let points = [];
      this.removeAllMarkers();

      // Level 1 Geometry from API response (this will be dynamic, so it can be a Polygon, Point, etc.)
      const level1Geometry = this.dailyReports ? this.dailyReports[0]?.site?.geometry : undefined;

      // Check if this is a valid level1Geometry and if it's not already added
      if (this.mapComponentInstance) {

        // Draw alignment paths (if any)
        if (this.alignmentPath.length) {
          this.alignmentPath.forEach((alignment) => {
            alignment = alignment.map((o) => ({
              ...o,
              data: { ...o.data, level: this.dataLevels.length > 1 ? 2 : 1 },
            }));
            this.mapComponentInstance?.drawProjectSiteMarkers(alignment);
          });
        }

        // Draw markers and images
        if (this.markersList?.length) {
          points = [...points, ...this.markersList, ...images];
          this.mapComponentInstance?.drawProjectSiteMarkers(this.markersList);
        }

        if (images?.length) {
          this.mapComponentInstance?.drawImageMarkers(images);
        }

        if(levelThreeData){
            points = this.drawGeoMetries(levelThreeData) || [];

        }

        // if(noLocatioImages?.length){
        //   console.log("no loc",noLocatioImages);
        //   this.mapComponentInstance?.drawNoLocationImageMarkers(noLocatioImages,level1Geometry);
        // }

        // Draw the Level 1 Geometry
        if (!this.isBaseLayerAdded && level1Geometry) {
          this.isBaseLayerAdded = this.mapService?.drawGeoJsonGeometry(
            this.mapComponentInstance,
            level1Geometry,
          );
        }

        if (level1Geometry && !preventZoomReset && images?.length === 0) {
          const geometryCoordinates = this.extractCoordinatesFromGeoJson(level1Geometry);
          if (geometryCoordinates.length) {
            points = [...points, ...geometryCoordinates.map(coord => ({ geometry: coord }))];
          }
        }

        if (images) {
          images.forEach(image => {
            if (image?.geometry) {
              const imagePoint = { geometry: image.geometry }; // Wrap geometry in an object
              points = [...points, imagePoint]; // Add to points array
            }
          });
        }

        // Add polygons to points
        if (this.mapPolygons?.length && !skipPolygon) {
          this.mapPolygons.forEach(polygon => {
            if (polygon.geoJson) {
              const polygonPoints = polygon.geoJson.map(coord => ({
                geometry: { latitude: coord[1], longitude: coord[0] },
              }));
              points = [...points, ...polygonPoints];
            }
          });
        }
        // Adjust zoom based on collected points
        if (points.length) {
          const extent = this.calculateBoundingExtent(
            points.map(point => ({
              longitude: point.geometry.longitude,
              latitude: point.geometry.latitude,
            })),
          );

          if (extent) {
            setTimeout(() => {
              try {
                // console.log('Animating to new extent:', extent);

                const mapView = this.mapComponentInstance.mapViewInstance;
                if (mapView) {
                  mapView.when(() => {
                    const targetExtent = extent.expand(1.5);

                    const goToOptions = {
                      duration: 2000, // Duration in milliseconds
                      easing: 'ease-in-out', // Easing style
                    };

                    mapView.goTo(targetExtent, goToOptions)
                      .then(() => {
                        // console.log('Animation to extent complete!');
                      })
                      .catch((error) => {
                        console.log('Error during animation:', error.message, error);

                        // Fallback: Set center and zoom
                        const { longitude, latitude } = extent.center;
                        mapView.center = [longitude, latitude];
                        mapView.zoom = 15;
                      });
                  });
                } else {
                  console.error('MapViewInstance is not initialized or undefined.');
                }
              } catch (error) {
                console.error('Error animating extent:', error.message, error);
              }
            }, 100);
          } else {
            // Zoom to the first point as a fallback
            const firstPoint = points[0];
            if (firstPoint && firstPoint.geometry) {
              const { longitude, latitude } = firstPoint.geometry;
              setTimeout(() => {
                try {
                  // console.log('Zooming to fallback point using center and zoom:', longitude, latitude);

                  // Set the center and zoom directly
                  if (this.mapComponentInstance.mapViewInstance) {
                    this.mapComponentInstance.mapViewInstance.center = [longitude, latitude]; // Longitude and latitude
                    this.mapComponentInstance.mapViewInstance.zoom = 20; // Adjust zoom level as per your requirement
                  } else {
                    console.error('MapViewInstance is not initialized or undefined.');
                  }
                } catch (error) {
                  console.error('Error zooming to fallback point using center and zoom:', error.message, error);
                }
              }, 100);


            }
          }
        }
      }
    } catch (error) {
      console.error('Error in drawPointsOnMap:', error.message, error);
    }
  }

  // Helper function to extract coordinates from GeoJSON
  extractCoordinatesFromGeoJson(geoJson) {
    const coordinates = [];

    // Handle FeatureCollection
    if (geoJson.type === 'FeatureCollection') {
      geoJson.features.forEach((feature) => {
        coordinates.push(...this.extractCoordinatesFromGeoJson(feature.geometry));
      });
    }
    // Handle Feature
    else if (geoJson.type === 'Feature') {
      coordinates.push(...this.extractCoordinatesFromGeoJson(geoJson.geometry));
    }
    // Handle GeometryCollection
    else if (geoJson.type === 'GeometryCollection') {
      geoJson.geometries.forEach((geometry) => {
        coordinates.push(...this.extractCoordinatesFromGeoJson(geometry));
      });
    }
    // Handle MultiPolygon
    else if (geoJson.type === 'MultiPolygon') {
      geoJson.coordinates.forEach((polygon) => {
        polygon.forEach((ring) => {
          ring.forEach((coord) => {
            coordinates.push({ longitude: coord[0], latitude: coord[1] });
          });
        });
      });
    }
    // Handle Polygon
    else if (geoJson.type === 'Polygon') {
      geoJson.coordinates.forEach((ring) => {
        ring.forEach((coord) => {
          coordinates.push({ longitude: coord[0], latitude: coord[1] });
        });
      });
    }
    // Handle MultiLineString
    else if (geoJson.type === 'MultiLineString') {
      geoJson.coordinates.forEach((line) => {
        line.forEach((coord) => {
          coordinates.push({ longitude: coord[0], latitude: coord[1] });
        });
      });
    }
    // Handle LineString
    else if (geoJson.type === 'LineString') {
      geoJson.coordinates.forEach((coord) => {
        coordinates.push({ longitude: coord[0], latitude: coord[1] });
      });
    }
    // Handle MultiPoint
    else if (geoJson.type === 'MultiPoint') {
      geoJson.coordinates.forEach((point) => {
        coordinates.push({ longitude: point[0], latitude: point[1] });
      });
    }
    // Handle Point
    else if (geoJson.type === 'Point') {
      coordinates.push({ longitude: geoJson.coordinates[0], latitude: geoJson.coordinates[1] });
    }

    return coordinates;
  }


  // Helper function to calculate bounding extent
  calculateBoundingExtent(coordinates) {
    if (!coordinates.length) {
      return null;
    }

    const extent = coordinates.reduce(
      (acc, coord) => ({
        xmin: Math.min(acc.xmin, coord.longitude),
        ymin: Math.min(acc.ymin, coord.latitude),
        xmax: Math.max(acc.xmax, coord.longitude),
        ymax: Math.max(acc.ymax, coord.latitude),
      }),
      {
        xmin: Number.MAX_SAFE_INTEGER,
        ymin: Number.MAX_SAFE_INTEGER,
        xmax: Number.MIN_SAFE_INTEGER,
        ymax: Number.MIN_SAFE_INTEGER,
      },
    );

    if (
      extent.xmin < extent.xmax &&
      extent.ymin < extent.ymax &&
      !isNaN(extent.xmin) &&
      !isNaN(extent.ymin)
    ) {
      return new Extent(extent);
    }
    return null;
  }


  removeAllMarkers() {
    this.mapComponentInstance?.mapSiteMarkersGraphicsLayer.removeAll();
    this.mapComponentInstance?.mapStationMarkersGraphicsLayer.removeAll();
  }

  toggleFiltersSection() {
    this.showFilters = !this.showFilters;
    this.commonService.setIsExpanded(false);
  }

  toggleSidenav() {
    this.showSidenav = !this.showSidenav;
  }

  zoomIn() {
    if (this.mapComponentInstance?.mapViewInstance?.zoom) {
      this.mapComponentInstance.mapViewInstance.zoom = this.mapComponentInstance?.mapViewInstance?.zoom + 1;
    }
  }

  zoomOut() {
    if (this.mapComponentInstance?.mapViewInstance?.zoom) {
      this.mapComponentInstance.mapViewInstance.zoom = this.mapComponentInstance?.mapViewInstance?.zoom - 1;
    }
  }

  closeMap() {
    const mapData = this.dataLevels.pop();
    if (this.dataLevels.length > 1) {
      this.markersList = mapData.markersList;
      this.ghostLocations = mapData.ghostLocations;
      this.drawPointsOnMap([], [], false, true);
      
      this.mapComponentInstance.removeAllGeoGroup();
    } else if (this.dataLevels.length === 1) {
      this.noLocatioImageList = [];
      this.mapComponentInstance.removeAllGeoGroup();
      this.createLevelOneMapData();
    } else {
      this.mapService.triggerFullScreenToggle.next(false);
    }

    this.resetLegend();
  }

  resetLegend() {
    this.legendData.forEach(o => {
      o.children.forEach(i => {
        i.isSelected = true;
      });
    });
  }

  onMapLoaded($event: __esri.Map) {
    this.mapComponentInstance = $event;
    // console.log('Map Loaded :: ', $event);
  }

  onViewLoaded($event: __esri.MapView) {
    // console.log('Map View Loaded :: ', $event);

  }

  showPopupChange($event) {
    this.showFilters = $event;
    this.showPopup = $event;
  }

  drawMapPolygons() {
    if (this.mapPolygons?.length && this.mapComponentInstance) {
      this.mapPolygons?.forEach(polygonDetails => {
        this.mapComponentInstance?.drawPolygon({
          geoJson: polygonDetails.geoJson,
          category: 'boundary',
          isLine: false,
          isDashed: false,
        });
      });

      this.drawMapLayers();
    }
  }

  drawMapLayers() {
    if (this.mapLayers?.length && this.mapComponentInstance) {
      this.mapLayers?.forEach(eachLayer => {
        const features = eachLayer.geometry?.features?.filter(o => o.geometry.type === 'LineString');

        features.forEach(o => {
          this.mapComponentInstance?.drawPolygon({
            geoJson: o.geometry?.coordinates,
            category: eachLayer.type,
            isLine: false,
            isDashed: false,
          });
        });
      });
    }
  }

  updateLegendDataSelection(data) {
    this.legendData = data;
  }

  onShowTooltip($event) {
    if ($event) {
      this.showTooltip = true;
      const category = $event?.graphic?.attributes?.category;

      const data = {
        ...$event?.graphic?.attributes?.data,
        markerCategory: category,
      };

      this.screenPoint = $event?.screenPoint;

      this.tooltipContent = this.mapService.getTooltipContent(data);
    } else {
      this.showTooltip = false;
    }
  }

  // drawGeoMetries(data: any) {
  //   const areGeometriesEqual = (geometry1, geometry2) => {
  //     return (
  //       geometry1.type === geometry2.type &&
  //       JSON.stringify(geometry1.coordinates) === JSON.stringify(geometry2.coordinates)
  //     );
  //   };
  
  //   // Filter out duplicate geometries
  //   const uniqueGeometries = [];
  //  data?.child?.sources
  //     .map(source => source.source_geometry)
  //     .filter(geometry => {
  //       if (geometry && !uniqueGeometries.some(existing => areGeometriesEqual(existing, geometry))) {
  //         uniqueGeometries.push(geometry);
  //         return true;
  //       }
  //       return false;
  //     });

  //     data?.children?.flatMap(child => child.sources || [])
  //     .map(source => source.source_geometry)
  //     .filter(geometry => {
  //       if (geometry && !uniqueGeometries.some(existing => areGeometriesEqual(existing, geometry))) {
  //         uniqueGeometries.push(geometry);
  //         return true;
  //       }
  //       return false;
  //     });

  //     if(data?.child === undefined &&  data?.children == undefined) {

  //       data?.sources
  //       .map(source => source.source_geometry)
  //       .filter(geometry => {
  //         if (geometry && !uniqueGeometries.some(existing => areGeometriesEqual(existing, geometry))) {
  //           uniqueGeometries.push(geometry);
  //           return true;
  //         }
  //         return false;
  //       });

  //     }
    
  
  
  //   // Check if this is a valid level1Geometry and if it's not already added
  //   if (this.mapComponentInstance) {
  //     // Draw the Level 3 Geometry
  //     if (uniqueGeometries) {
  //       this.mapService.drawGeoJsonGeometries(this.mapComponentInstance, uniqueGeometries,true);
  //     }
  
  // }
  // return uniqueGeometries;
  // }

  drawGeoMetries(data: any) {
    const areGeometriesEqual = (geometry1, geometry2) => {
      return (
        geometry1.type === geometry2.type &&
        JSON.stringify(geometry1.coordinates) === JSON.stringify(geometry2.coordinates)
      );
    };
  
    let points = [];
    const uniqueGeometries = [];
  
    const processGeometries = (sources) => {
      sources?.forEach(source => {
        const geometry = source.source_geometry;
        if (geometry && !uniqueGeometries.some(existing => areGeometriesEqual(existing, geometry))) {
          uniqueGeometries.push(geometry);
  
          // Extract coordinates and add to points
          const geometryCoordinates = this.extractCoordinatesFromGeoJson(geometry);
          if (geometryCoordinates.length) {
            points = [...points, ...geometryCoordinates.map(coord => ({ geometry: coord }))];
          }
        }
      });
    };
  
    // Process different levels of data
    processGeometries(data?.child?.sources);
    processGeometries(data?.children?.flatMap(child => child.sources || []));
    
    if (data?.child === undefined && data?.children === undefined) {
      processGeometries(data?.sources);
    }
  
    // Draw unique geometries
    if (this.mapComponentInstance && uniqueGeometries.length) {
      this.mapService.drawGeoJsonGeometries(this.mapComponentInstance, uniqueGeometries, true);
    }
  
    return points; // Ensure returning an array
  }
  
  toggleFullScreen() {
    this.mapService.triggerFullScreenToggle.next(!this.isFullscreen);
  }

  ngOnDestroy() {
    this.mapService.showWrapperButtons.next(true);
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
