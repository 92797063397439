import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Parser } from 'expr-eval';
import { DailyReportService } from '../../../../daily-report.service';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';
import * as moment from 'moment';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import * as $ from 'jquery';
import { AppService } from '../../../../../../../../../../shared/services';
import { dailyReport } from '../../../../../../../../core/projects.selectors';
import { DeleteVideoFromMuxDbRequest } from '../../../../../../../../core/projects.actions';

@Component({
  selector: 'app-multiline-bid-item-details',
  templateUrl: './multiline-bid-item-details.component.html',
  styleUrls: ['../../input-component-styles.scss', './multiline-bid-item-details.component.scss'],
})
export class MultilineBidItemDetailsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() lineItems: any[];
  @Input() bidItem: any;
  @Input() disabled = false;
  @Input() clickId?: string;

  quantityTotal: unknown = null;
  form: FormGroup = new FormGroup({});
  formSubscription: Subscription = new Subscription();
  isFormReady = false;
  autoCalculateOptions = [
    {
      label: 'Dynamic rollup',
      value: 'dynamic_rollup',
    },
    {
      label: 'Manual entry',
      value: 'manual_entry',
    },
  ];
  selectedLineItemId = '';
  selectedLineItemMediaFiles = [];
  videoToBeDeletedFromMuxDb = [];

  lineItemTotals: any = {};

  calculatedValues: any = {};

  formSubscriptions: any[] = [];

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private dailyReportService: DailyReportService,
    private commonService: CommonService,
    private projectsService: ProjectsService,
    protected appService: AppService,
  ) {
    this.dailyReportService.add
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.store.select(dailyReport)
            .subscribe((report) => {
              const details = report.reportDetails;

              if (details) {
                const { bidItems } = details;

                const selectedElement = bidItems.find((o) => this.bidItem.id === o.bidItem.id);

                if (selectedElement) {
                  this.bidItem = selectedElement.bidItem;
                  this.lineItems = JSON.parse(JSON.stringify(selectedElement.lineItems));
                }
              }
            });


          const id = `new_${new Date().getTime()}`;
          this.selectedLineItemId = id;
          this.dailyReportService.disableAddMenu.next(false);

          const newElement = {
            id,
            bid_item_id: this.bidItem.id,
            created_at: moment().toISOString(),
            bid_item: this.bidItem,
            quantity: null,
            comment: '',
            station_id: null,
            latitude: '',
            longitude: '',
            heading_id: null,
            start_of_work: false,
            station_break: false,
            updated_at: null,
            pictures: [],
            videos: [],
            station: null,
            heading: null,
            annotations: [],
            sources: [],
            ...(this.bidItem.rollup ?
              {
                field_values: this.bidItem?.fields?.map(o => ({
                    id: o.id,
                    value: null,
                  }),
                ),
              } : {}),

          };

          this.addNewLineItem(newElement);
        }

      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.clickId !== undefined && this.clickId !== null) {
        this.selectLineItem(this.clickId);
      }
    }, 100);

  }

  ngOnInit() {
    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        const items = Array.isArray(data) ? data : [data];
        const formGroup = this.getFormGroup(this.form, this.selectedLineItemId);

        items.forEach(eachItem => {
          if (eachItem?.['fileType'] === 'picture') {
            let pictures = formGroup?.get('pictures')?.value || '[]';
            pictures = JSON.parse(pictures);
            pictures.push(eachItem);
            formGroup?.get('pictures')?.setValue(JSON.stringify(pictures));
          }

          if (eachItem?.['fileType'] === 'video') {
            let videos = formGroup?.get('videos')?.value || '[]';
            videos = JSON.parse(videos);
            videos.push(eachItem);
            formGroup?.get('videos')?.setValue(JSON.stringify(videos));
          }
        });

        const tempPictureData = this.getSelectedLineItemPictures() ?? [];
        const tempVideosData = this.getSelectedLineItemVideos() ?? [];
        const allNewItems = [...tempPictureData, ...tempVideosData];

        allNewItems.forEach(item => {
          const existingItem = this.selectedLineItemMediaFiles.find(ei => ei.id === item.id);

          if (!existingItem) {
            this.selectedLineItemMediaFiles.push(item);
          }
        });
      });

    this.dailyReportService.addComment
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          const tempControl = this.getFormGroup(this.form, this.selectedLineItemId).get('comment');

          if (!tempControl) {
            this.getFormGroup(this.form, this.selectedLineItemId)?.addControl('comment', new FormControl(null));

            setTimeout(() => {
              $(`#${this.selectedLineItemId} textarea`).focus();
            }, 100);
          }
        }
      });

    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.dailyReportService.formatAndSaveMultilineBidItemPayload(this.form, this.bidItem, this.lineItems);
        }
        if (this.videoToBeDeletedFromMuxDb.length) {
          this.videoToBeDeletedFromMuxDb.forEach(videoId => {
            this.store.dispatch(DeleteVideoFromMuxDbRequest({ payload: videoId }));
          });
        }
      });

    this.dailyReportService.triggerDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.bidItem) {
          this.dailyReportService.delete.next({
            type: 'bidItems',
            id: this.bidItem.id,
          });
        }
      });

    this.dailyReportService.selectedLocation
      .pipe(takeUntil(this.onDestroy))
      .subscribe((location) => {
        if (this.form) {
          this.getFormGroup(this.form, this.selectedLineItemId)?.patchValue(location);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formSubscriptions.map(o => o?.unsubscribe());
    this.formSubscriptions = [];

    this.dailyReportService.disableAddMenu.next(true);
    this.projectsService.isAnyFormDirty.next(false);
    this.formSubscription?.unsubscribe();

    this.isFormReady = false;

    if (this.lineItems && this.lineItems.length > 0) {
      this.form = this.createLineItemForm(this.bidItem, this.lineItems);
      this.calculateLineItemQuantity();
    } else {
      this.dailyReportService.add.next();
    }

    if (this.disabled) {
      this.form.disable();
      this.projectsService.isAnyFormDirty.next(false);
    }
    this.isFormReady = true;
  }

  calculateLineItemQuantity(controlName = null) {
    if (controlName) {
      this.lineItemTotals = {
        ...this.lineItemTotals,
        [controlName]: 0,
      };
      this.calculateQuantity(controlName);
    } else {
      this.lineItemTotals = {};
      Object.keys(this.form.controls).forEach(c => {
        this.calculateQuantity(c);
      });
    }

    this.calculateTotalQuantity();
  }

  calculateQuantity(controlName) {
    const parentName = !controlName.includes('new') ? controlName.split('_')[0] : controlName;
    const parent = this.form.controls[parentName];


    const parentValueType = parent['controls'].valueType?.value;

    const hasChildren = (Object.keys(this.form.controls).filter(o => o.includes(parentName))).length > 1;

    if (parentName !== controlName || hasChildren) {
      // The totals need to be handled for the parent here

      const childrenControls = Object.keys(this.form.controls).filter(o => o.includes(parentName) && o.includes('_'));

      let groupTotal = 0;
      childrenControls.forEach(childControlName => {
        let qty = 0;
        if (this.bidItem.rollup) {
          qty = this.calculateDynamicRollupValue(this.form.controls[childControlName].getRawValue());
        } else {
          qty = this.form.controls[childControlName]?.['controls'].quantity.value;
        }

        this.form.controls[childControlName]?.['controls'].quantity.setValue(qty, { emitEvent: false });
        qty = isNaN(qty) ? 0 : Number(qty);
        groupTotal += qty;
      });

      if (this.bidItem.rollup) {
        if (parentValueType === 'dynamic_rollup') {
          parent['controls'].quantity.setValue(groupTotal, { emitEvent: false });
          this.lineItemTotals[parentName] = groupTotal;
        } else {
          const qty = parent['controls'].quantity.value;
          this.lineItemTotals[parentName] = isNaN(qty) ? 0 : Number(qty);
        }
        this.calculatedValues[parentName] = groupTotal;
      } else {
        this.lineItemTotals[parentName] = groupTotal;
      }
    } else {
      if (this.bidItem.rollup) {
        if (parentValueType === 'dynamic_rollup') {
          const qty = this.calculateDynamicRollupValue(this.form.controls[controlName].getRawValue());
          parent['controls'].quantity.setValue(qty, { emitEvent: false });
          if (this.lineItemTotals[parentName]) {
            this.lineItemTotals[parentName] += qty;
          } else {
            this.lineItemTotals[parentName] = qty;
          }
        } else {
          const qty = this.form.controls[parentName]?.['controls'].quantity.value;
          this.lineItemTotals[parentName] = isNaN(qty) ? 0 : Number(qty);
        }
      } else {
        const qty = this.form.controls[parentName]?.['controls'].quantity.value;
        this.lineItemTotals[parentName] = isNaN(qty) ? 0 : Number(qty);
      }
    }
  }

  calculateDynamicRollupValue(values) {
    const mapObj = this.getFieldValuesMap(values);
    return Number((Parser.evaluate(this.bidItem.rollup_formula, mapObj)).toFixed(2));
  }

  getFormGroup(form, controlName) {
    if (form && form.get(controlName)) {
      return form.get(controlName) as FormGroup;
    }

    return new FormGroup({}) as FormGroup;
  }

  getFieldValuesMap(values) {
    const mapObj = {};

    this.bidItem.fields.forEach(field => {
      mapObj[field.name] = Number(values[field.id]);
    });

    return mapObj;
  }

  selectLineItem(lineItemId) {
    this.selectedLineItemId = this.selectedLineItemId === lineItemId ? '' : lineItemId;
    const pictures = this.getSelectedLineItemPictures() ?? [];
    const videos = this.getSelectedLineItemVideos() ?? [];
    const allNewItems = [...pictures, ...videos];

    allNewItems.forEach(item => {
      const existingItem = this.selectedLineItemMediaFiles.find(ei => ei.id === item.id);

      if (!existingItem) {
        this.selectedLineItemMediaFiles.push(item);
      }
    });

    if (this.selectedLineItemId) {
      this.dailyReportService.disableAddMenu.next(false);
    } else {
      this.dailyReportService.disableAddMenu.next(true);
    }
  }

  createLineItemForm(bidItem, lineItems) {

    this.formSubscriptions.map(o => o?.unsubscribe());
    this.formSubscriptions = [];

    const parentFormBuilder = {};

    lineItems?.map(lineItem => {
      let controlName = lineItem.id;
      parentFormBuilder[controlName] = this.fb.group({});

      parentFormBuilder[controlName].addControl('quantity', new FormControl(lineItem.quantity));

      if (lineItem.comment) {
        parentFormBuilder[controlName].addControl('comment', new FormControl(lineItem.comment));
      }

      parentFormBuilder[controlName].addControl('pictures', new FormControl(JSON.stringify(lineItem.pictures || [])));
      parentFormBuilder[controlName].addControl('videos', new FormControl(JSON.stringify(lineItem.videos || [])));

      parentFormBuilder[controlName].addControl('latitude', new FormControl(lineItem.latitude));
      parentFormBuilder[controlName].addControl('longitude', new FormControl(lineItem.longitude));


      if (bidItem.rollup === true) {
        lineItem.field_values?.map(field => {
          parentFormBuilder[controlName].addControl(field.id, new FormControl(field.value));
        });

        if (bidItem.rollup_quantity_editable === true) {
          const tempObj = {};
          lineItem.field_values.forEach(field => {
            tempObj[field.id] = field.value;
          });

          const qty = lineItem.quantity;
          let valueTypeValue;
          if (lineItem.rollup_quantity_manual === true) {
            valueTypeValue = 'manual_entry';
          } else {
            valueTypeValue = 'dynamic_rollup';
          }

          parentFormBuilder[controlName].addControl('initialQuantity', new FormControl(qty));
          parentFormBuilder[controlName].addControl('valueType', new FormControl(valueTypeValue));
        }
      }

      if (lineItem.sources?.length) {
        lineItem.sourcesSection?.sourcesList?.forEach(source => {
          if (lineItem.sources?.length > 1) {
            controlName = `${lineItem.id}_${source.id}`;
          } else {
            parentFormBuilder[controlName].removeControl('quantity');
            parentFormBuilder[controlName].removeControl('initialQuantity');
            parentFormBuilder[controlName].removeControl('valueType');
          }


          parentFormBuilder[controlName] = this.fb.group({});

          if (lineItem.comment) {
            parentFormBuilder[controlName].addControl('comment', new FormControl(lineItem.comment));
          }

          parentFormBuilder[controlName].addControl('pictures', new FormControl(JSON.stringify(lineItem.pictures || [])));
          parentFormBuilder[controlName].addControl('videos', new FormControl(JSON.stringify(lineItem.videos || [])));

          parentFormBuilder[controlName].addControl('latitude', new FormControl(lineItem.latitude));
          parentFormBuilder[controlName].addControl('longitude', new FormControl(lineItem.longitude));

          parentFormBuilder[controlName].addControl('quantity',
            new FormControl(source.modified_quantity || source.source_quantity),
          );

          if (bidItem.rollup === true) {
            source.fieldValues?.forEach(field => {
              parentFormBuilder[controlName].addControl(field.id, new FormControl(field.value));
            });
            if (bidItem.rollup_quantity_editable === true) {

              let valueTypeValue;
              if (lineItem.rollup_quantity_manual === true) {
                valueTypeValue = 'manual_entry';
              } else {
                valueTypeValue = 'dynamic_rollup';
              }

              const qty = source.modified_quantity || source.source_quantity;
              parentFormBuilder[controlName].addControl('initialQuantity', new FormControl(qty));
              parentFormBuilder[controlName].addControl('valueType', new FormControl(valueTypeValue));
            }
          }
        });
      }
    });

    // tslint:disable-next-line:forin
    for (const key in parentFormBuilder) {
      if (!key.includes('_') || key.includes('new')) {
        this.lineItemTotals[key] = parentFormBuilder[key].controls.quantity.value;
      }
    }

    this.calculateTotalQuantity();

    const form = this.fb.group(parentFormBuilder);
    const { controls } = form;
    // @ts-ignore
    // tslint:disable-next-line:prefer-for-of
    for (const controlName in controls) {
      if (controlName) {
        this.formSubscriptions.push(controls[controlName].valueChanges
          .subscribe(o => {
            this.calculateLineItemQuantity(controlName);
            this.projectsService.isAnyFormDirty.next(true);
          }));
      }
    }
    return form;
  }

  calculateTotalQuantity() {
    this.quantityTotal = Object.values(this.lineItemTotals).reduce((arr, v) => {
      // @ts-ignore
      arr += v;
      return arr;
    }, 0);
  }

  getSelectedLineItemPictures() {
    const pictures = this.getFormGroup(this.form, this.selectedLineItemId)?.get('pictures')?.value || '[]';
    return JSON.parse(pictures);
  }

  getSelectedLineItemVideos() {
    const videos = this.getFormGroup(this.form, this.selectedLineItemId)?.get('videos')?.value || '[]';
    return JSON.parse(videos);
  }

  addNewLineItem(newElement) {
    this.lineItems.push(newElement);
    const values = this.form.getRawValue();

    this.form = this.createLineItemForm(this.bidItem, this.lineItems);

    this.form.patchValue(values);

    this.calculateLineItemQuantity();


    this.formSubscription = this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.calculateLineItemQuantity();


      });
    this.selectedLineItemMediaFiles = [];


    if (this.disabled) {
      this.form.disable();
      this.projectsService.isAnyFormDirty.next(false);
    }
  }

  deleteLineItem(lineItemId) {
    this.form.removeControl(lineItemId);
    this.lineItems = this.lineItems.filter(o => o.id !== lineItemId);

    if (this.selectedLineItemId === lineItemId) {
      this.selectedLineItemId = null;
    }
  }

  deletePicture(pic) {
    if (pic.fileType === 'picture') {
      let pictures = this.getSelectedLineItemPictures();

      pictures = pictures?.filter(o => o.id !== pic.id);
      this.selectedLineItemMediaFiles = this.selectedLineItemMediaFiles?.filter(o => o.id !== pic.id);
      this.getFormGroup(this.form, this.selectedLineItemId)?.get('pictures').setValue(JSON.stringify(pictures));
    }

    if (pic.fileType === 'video') {
      let videos = this.getSelectedLineItemVideos();

      videos = videos?.filter(o => o.id !== pic.id);
      this.selectedLineItemMediaFiles = this.selectedLineItemMediaFiles?.filter(o => o.id !== pic.id);
      this.getFormGroup(this.form, this.selectedLineItemId)?.get('videos').setValue(JSON.stringify(videos));
      this.videoToBeDeletedFromMuxDb.push(pic.id);
    }
  }

  deleteLocation(lineItemId) {
    this.getFormGroup(this.form, lineItemId)?.get('latitude').setValue(null);
    this.getFormGroup(this.form, lineItemId)?.get('longitude').setValue(null);
  }

  showLocationSection(lineItemId) {
    return this.getFormGroup(this.form, lineItemId)?.controls?.latitude?.value !== null &&
      this.getFormGroup(this.form, lineItemId)?.controls?.longitude?.value !== null &&
      this.getFormGroup(this.form, lineItemId)?.controls?.latitude?.value !== '' &&
      this.getFormGroup(this.form, lineItemId)?.controls?.longitude?.value !== '';
  }

  createAvatar(name) {
    return name.split('')[0];
  }

  getCleanFormValues(form) {
    const lineItemValues = JSON.parse(JSON.stringify(form.getRawValue() || {}));

    Object.keys(lineItemValues).forEach(key => {
      // Check if the key contains an underscore
      if (key.includes('_')) {
        // Extract the part before the underscore
        const baseKey = key.split('_')[0];

        // If the base key exists in the object, delete the one without the underscore
        if (lineItemValues[baseKey] !== undefined) {
          delete lineItemValues[baseKey];
        }
      }
    });

    return lineItemValues;
  }

  ngOnDestroy() {
    this.formSubscriptions.map(o => o?.unsubscribe());
    this.formSubscriptions = [];
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
