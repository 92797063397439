<div class="wrapper">
  <ng-container *ngFor="let itd of data?.list">
    <div
      [class.is-expanded]="selectedItdId === itd.id"
      [id]="itd.id"

      class="itd-accordion"
    >
      <div class="itd-header flex justify-between items-center">
        <div
          (click)="selectItd(itd.id)"
          class="left flex justify-start items-center gap-[12px] grow"
        >
          <div class="details text-type-8">
            {{ itd?.['record_time'] }} <span *ngIf="itd?.['fieldValueSummary']" class="text-type-10">({{ itd?.['fieldValueSummary'] }}
            )</span>
          </div>
        </div>
        <div class="right flex justify-center items-center gap-[10px]">
          <div class="chevron">
            <mat-icon
              (click)="selectItd(itd.id)"
              [svgIcon]="selectedItdId === itd.id ? 'chevron-up' : 'chevron-down'"
              class="chevron-icon"
            />
          </div>
          <div class="menu-section">
            <mat-icon
              [matMenuTriggerFor]="menu"
              class="menu-icon"
            >more_horiz
            </mat-icon>
            <mat-menu #menu="matMenu">
              <button (click)="deleteItdItem(itd.id)" mat-menu-item>
                <mat-icon svgIcon="trash" />
                Delete Time
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div
        *ngIf="selectedItdId === itd.id"
        class="itd-content"
      >
        <app-internal-test-details-form
          (onDeletePicture)="deletePicture($event)"
          [fields]="itd?.['field_values']"
          [form]="getFormGroup(form, selectedItdId)"
          [selectedMediaFiles]="selectedMediaFiles"
        />
      </div>
    </div>
  </ng-container>
</div>
