import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../../services/common.service';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['../../styles.scss', './text-input.component.scss'],
})
export class TextInputComponent implements OnChanges, OnDestroy {
  @ViewChild('input') input: ElementRef | undefined;

  @Input() label = '';
  @Input() focus = false;
  @Input() placeholder = '';
  @Input() parentFormGroup: FormGroup = new FormGroup<any>({});
  @Input() controlName = '';
  @Input() error = '';
  @Input() warning = '';
  @Input() hint = '';
  @Input() maxLength: string | number = 50;
  @Input() max = 9999999999;
  @Input() min = 0;
  @Input() prefixIcon = '';
  @Input() suffixIcon = '';
  @Input() suffixText = '';
  @Output() checkForErrors = new EventEmitter();

  fieldSubscription: Subscription | undefined;
  matcher = new CustomErrorStateMatcher();

  isRequired = false;
  isDisabled = false;

  constructor(
    private commonService: CommonService,
  ) {
  }

  // Do not show field as touched and having error when clicked outside the field
  @HostListener('focusout', ['$event'])
  onBlur(event: Event) {
    if (!this.error) {
      this.parentFormGroup.controls[this.controlName].markAsUntouched();
    }
  }

  @HostListener('focusin', ['$event'])
  setInputFocus(): void {
    this.commonService.focusedIn.next(true);
  }

  ngOnChanges(): void {
    const control = this.parentFormGroup?.controls[this.controlName];
    this.isDisabled = control?.status === 'DISABLED';
    this.fieldSubscription = control?.valueChanges.subscribe((value) => {
      // If field is marked as touched, mark it as untouched
      if (this.parentFormGroup.controls[this.controlName].touched) {
        this.parentFormGroup.controls[this.controlName].markAsUntouched();
      }

      // Get errors on the form field
      const errors = this.parentFormGroup.controls[this.controlName].errors;

      // If errors exist, handle it
      if (errors) {
        // Clear errors on the form field
        this.parentFormGroup.controls[this.controlName].setErrors(null);

        // Clear out the error messages on the screen (parent component)
        this.checkForErrors.emit(this.controlName);

        // Add errors again to the form field for showing messages in the future
        this.parentFormGroup.controls[this.controlName].setErrors(errors);
      } else {
        // Clear out the error messages on the screen (parent component)
        this.checkForErrors.emit(this.controlName);
      }
    });

    // Check if field is required
    if (control?.validator) {
      const validator = control?.validator({} as AbstractControl);
      this.isRequired = !!(validator && validator['required']);
    }
  }

  ngOnDestroy() {
    this.fieldSubscription?.unsubscribe();
  }
}

class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}
