import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { DailyReportService } from '../../../../daily-report.service';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-internal-test-details-without-station',
  templateUrl: './internal-test-details-without-station.component.html',
  styleUrls: ['./internal-test-details-without-station.component.scss'],
})
export class InternalTestDetailsWithoutStationComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup = new FormGroup({});
  @Input() data: any;
  selectedItdId: string;
  selectedMediaFiles = [];
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private dailyReportService: DailyReportService,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.dailyReportService.disableAddMenu.next(true);

    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        const items = Array.isArray(data) ? data : [data];
        const formGroup = this.getFormGroup(this.form, this.selectedItdId);

        items.forEach(eachItem => {
          if (eachItem?.['fileType'] === 'picture') {
            let pictures = formGroup?.get('pictures')?.value || '[]';
            pictures = JSON.parse(pictures);
            pictures.push(eachItem);
            formGroup?.get('pictures')?.setValue(JSON.stringify(pictures));
          }

          if (eachItem?.['fileType'] === 'video') {
            let videos = formGroup?.get('videos')?.value || '[]';
            videos = JSON.parse(videos);
            videos.push(eachItem);
            formGroup?.get('videos')?.setValue(JSON.stringify(videos));
          }
        });

        const tempPictureData = this.getSelectedPictures() ?? [];
        const tempVideosData = this.getSelectedVideos() ?? [];
        const allNewItems = [...tempPictureData, ...tempVideosData];

        allNewItems.forEach(item => {
          const existingItem = this.selectedMediaFiles.find(ei => ei.id === item.id);

          if (!existingItem) {
            this.selectedMediaFiles.push(item);
          }
        });

        this.cdRef.detectChanges();
      });

    this.dailyReportService.addComment
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          const tempControl = this.getFormGroup(this.form, this.selectedItdId).get('comment');

          if (!tempControl) {
            this.getFormGroup(this.form, this.selectedItdId)?.addControl('comment', new FormControl(null));
          }
        }
      });

    this.dailyReportService.selectedLocation
      .pipe(takeUntil(this.onDestroy))
      .subscribe((location) => {
        if (this.form) {
          this.getFormGroup(this.form, this.selectedItdId)?.patchValue(location);
        }
      });

    this.dailyReportService.triggerDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        const elementIds = [];
        this.data.internalTestDetails?.list?.forEach((detail: any) => {
          elementIds.push(detail.id);
        });

        this.dailyReportService.delete.next({
          type: 'internalTest',
          id: elementIds,
        });
      });
  }

  selectItd(data) {
    this.selectedItdId = this.selectedItdId === data ? this.data.id : data;

    const tempPictureData = this.getSelectedPictures() ?? [];
    const tempVideosData = this.getSelectedVideos() ?? [];
    this.selectedMediaFiles = [...tempPictureData, ...tempVideosData];

    setTimeout(() => {
      const ele = $(`#${data}`)[0];

      if (ele) {
        ele.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 100);

    if (this.selectedItdId) {
      this.dailyReportService.disableAddMenu.next(false);
    } else {
      this.dailyReportService.disableAddMenu.next(true);
    }
  }

  getSelectedPictures() {
    const pictures = this.getFormGroup(this.form, this.selectedItdId)?.get('pictures')?.value || '[]';
    return JSON.parse(pictures);
  }

  getSelectedVideos() {
    const videos = this.getFormGroup(this.form, this.selectedItdId)?.get('videos')?.value || '[]';
    return JSON.parse(videos);
  }

  deleteItdItem(data) {
    if (!data.includes('new_')) {
      this.dailyReportService.itdToDelete.next(data);
    }
    this.form.removeControl(data);
    this.data.list = this.data.list.filter(o => o.id !== data);
    this.cdRef.detectChanges();
  }

  getFormGroup(form, controlName) {
    if (form && form.get(controlName)) {
      return form.get(controlName) as FormGroup;
    }
    return new FormGroup({}) as FormGroup;
  }

  deletePicture(pic) {
    if (pic.fileType === 'picture') {
      let pictures = this.getSelectedPictures();

      pictures = pictures?.filter(o => o.id !== pic.id);
      this.selectedMediaFiles = this.selectedMediaFiles?.filter(o => o.id !== pic.id);
      this.getFormGroup(this.form, this.selectedItdId)?.get('pictures').setValue(JSON.stringify(pictures));
    }

    if (pic.fileType === 'video') {
      let videos = this.getSelectedVideos();

      videos = videos?.filter(o => o.id !== pic.id);
      this.selectedMediaFiles = this.selectedMediaFiles?.filter(o => o.id !== pic.id);
      this.getFormGroup(this.form, this.selectedItdId)?.get('videos').setValue(JSON.stringify(videos));
      this.dailyReportService.videoToBeDeletedFromMuxDb.next(pic.id);
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
