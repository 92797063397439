<mat-form-field
  *ngIf="parentFormGroup.controls[controlName]"
  [formGroup]="parentFormGroup"
  appearance="legacy"
  class="form-field w-full"
  floatLabel="always"
  hideRequiredMarker
>
  <mat-label class="text-type-13">{{ label }}:</mat-label>
  <mat-icon
    *ngIf="prefixIcon"
    class="prefix"
    matPrefix
    svgIcon="{{prefixIcon}}"
  />
  <mat-icon
    *ngIf="suffixIcon"
    class="suffix"
    matSuffix
    svgIcon="{{suffixIcon}}"
  />
  <div *ngIf="warning || suffixText" class="suffix flex justify-end items-center" matSuffix>
    <mat-icon
      *ngIf="error"
      class="suffix"
      svgIcon="error"
    />
    <mat-icon
      *ngIf="!error && warning"
      class="suffix"
      svgIcon="warning"
    />
    <div
      *ngIf="suffixText"
      class="suffix uppercase"
    >
      ({{ suffixText }})
    </div>
  </div>
  <input
    #input
    [formControlName]="controlName"
    [max]="max"
    [maxlength]="maxLength"
    [min]="min"
    class="input number-input"
    matInput
    placeholder="{{placeholder}}"
    type="text"
  >
  <mat-error *ngIf="error">{{ error }}</mat-error>
  <mat-hint *ngIf="!error && warning" class="text-type-14 warning">{{ warning }}</mat-hint>
  <mat-hint *ngIf="!error && !warning && hint" class="text-type-14">{{ hint }}</mat-hint>
</mat-form-field>

