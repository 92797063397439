import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { SiteMaterialTestService } from '../../../../../../../../../project/material-test/services';
import { MapService } from '../../../../../../../../../../shared/services/map.service';
import { EsriMapComponent } from '../../../../../../../../../../shared/components';
import Extent from '@arcgis/core/geometry/Extent';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-survey-data-map',
  templateUrl: './survey-data-map.component.html',
  styleUrls: ['./survey-data-map.component.scss'],
  animations: [
    trigger('legendAnimation', [
      state(
        'visible',
        style({
          transform: 'translate(0%, 0%) scale(1)',
          opacity: 1,
        })
      ),
      state(
        'void, hidden',
        style({
          transform: 'translate(-30%, 25%) scale(0.4)',
          opacity: 0,
        })
      ),
      transition('* => visible', animate('250ms')),
      transition('* => void, * => hidden', animate('250ms')),
    ]),
  ],
})
export class SurveyDataMapComponent implements OnInit, OnDestroy {
  @ViewChild('projectMap') projectMap: ElementRef;
  @Input() data: any;
  initialized = true;
  mapComponentInstance: any;
  x: number;
  y: number;

  alignmentPath: any[] = [];
  screenPoint = {
    x: 0,
    y: 0,
  };
  mapPolygons = [];
  dataLevels = [];
  project: any;
  site: any;
  dailyReports: any[];
  isFullscreen = false;
  mapLayers = [];
  isBaseLayerAdded = false;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private mapService: MapService
  ) {
    this.drawPointsOnMap();
  }

  ngOnInit() {}

  onMapComponentLoaded($event: EsriMapComponent) {
    this.mapComponentInstance = $event;

    // this.setInitialZoomAndCenter();
    this.mapComponentInstance.mapViewInstance.watch('zoom', () => {});
    this.drawPointsOnMap();
  }

  drawPointsOnMap() {
    try {
      let points = [];

      // Function to check if two geometries are the same
      const areGeometriesEqual = (geometry1, geometry2) => {
        return (
          geometry1.type === geometry2.type &&
          JSON.stringify(geometry1.coordinates) === JSON.stringify(geometry2.coordinates)
        );
      };

      // Filter out duplicate geometries
      const uniqueGeometries = [];
      this.data.sourcesList
        .map(source => source.source_geometry)
        .filter(geometry => {
          if (geometry && !uniqueGeometries.some(existing => areGeometriesEqual(existing, geometry))) {
            uniqueGeometries.push(geometry);
            return true;
          }
          return false;
        });
      const geometryCoordinates = uniqueGeometries
        .map(geometry => this.extractCoordinatesFromGeoJson(geometry)) // Extract nested arrays
        .reduce((acc, curr) => acc.concat(curr), []); // Flatten the nested arrays

      // Add extracted coordinates to points
      if (geometryCoordinates.length) {
        points = [...points, ...geometryCoordinates.map(coord => ({ geometry: coord }))];
      }

      // Check if this is a valid level1Geometry and if it's not already added
      if (this.mapComponentInstance) {
        // Draw the Level 1 Geometry
        if (!this.isBaseLayerAdded && uniqueGeometries) {
          this.isBaseLayerAdded = this.mapService.drawGeoJsonGeometries(this.mapComponentInstance, uniqueGeometries);
        }

        // Adjust zoom based on collected points
        if (points.length) {
          const extent = this.calculateBoundingExtent(
            points.map(point => ({
              longitude: point.geometry.longitude,
              latitude: point.geometry.latitude,
            }))
          );

          if (extent) {
            setTimeout(() => {
              try {
                const mapView = this.mapComponentInstance.mapViewInstance;
                if (mapView) {
                  mapView.when(() => {
                    const targetExtent = extent.expand(1.5);

                    const goToOptions = {
                      duration: 2000, // Duration in milliseconds
                      easing: 'ease-in-out', // Easing style
                    };

                    mapView
                      .goTo(targetExtent, goToOptions)
                      .then(() => {})
                      .catch(() => {
                        // Fallback: Set center and zoom
                        const { longitude, latitude } = extent.center;
                        mapView.center = [longitude, latitude];
                        mapView.zoom = 15;
                      });
                  });
                } else {
                }
              } catch (error) {}
            }, 100);
          } else {
            // Zoom to the first point as a fallback
            const firstPoint = points[0];
            if (firstPoint && firstPoint.geometry) {
              const { longitude, latitude } = firstPoint.geometry;
              setTimeout(() => {
                try {
                  // Set the center and zoom directly
                  if (this.mapComponentInstance.mapViewInstance) {
                    this.mapComponentInstance.mapViewInstance.center = [longitude, latitude]; // Longitude and latitude
                    this.mapComponentInstance.mapViewInstance.zoom = 20; // Adjust zoom level as per your requirement
                  } else {
                  }
                } catch (error) {}
              }, 100);
            }
          }
        }
      }
    } catch (error) {}
  }

  // Helper function to extract coordinates from GeoJSON
  extractCoordinatesFromGeoJson(geoJson) {
    const coordinates = [];

    // Handle FeatureCollection
    if (geoJson.type === 'FeatureCollection') {
      geoJson.features.forEach(feature => {
        coordinates.push(...this.extractCoordinatesFromGeoJson(feature.geometry));
      });
    }
    // Handle Feature
    else if (geoJson.type === 'Feature') {
      coordinates.push(...this.extractCoordinatesFromGeoJson(geoJson.geometry));
    }
    // Handle GeometryCollection
    else if (geoJson.type === 'GeometryCollection') {
      geoJson.geometries.forEach(geometry => {
        coordinates.push(...this.extractCoordinatesFromGeoJson(geometry));
      });
    }
    // Handle MultiPolygon
    else if (geoJson.type === 'MultiPolygon') {
      geoJson.coordinates.forEach(polygon => {
        polygon.forEach(ring => {
          ring.forEach(coord => {
            coordinates.push({ longitude: coord[0], latitude: coord[1] });
          });
        });
      });
    }
    // Handle Polygon
    else if (geoJson.type === 'Polygon') {
      geoJson.coordinates.forEach(ring => {
        ring.forEach(coord => {
          coordinates.push({ longitude: coord[0], latitude: coord[1] });
        });
      });
    }
    // Handle MultiLineString
    else if (geoJson.type === 'MultiLineString') {
      geoJson.coordinates.forEach(line => {
        line.forEach(coord => {
          coordinates.push({ longitude: coord[0], latitude: coord[1] });
        });
      });
    }
    // Handle LineString
    else if (geoJson.type === 'LineString') {
      geoJson.coordinates.forEach(coord => {
        coordinates.push({ longitude: coord[0], latitude: coord[1] });
      });
    }
    // Handle MultiPoint
    else if (geoJson.type === 'MultiPoint') {
      geoJson.coordinates.forEach(point => {
        coordinates.push({ longitude: point[0], latitude: point[1] });
      });
    }
    // Handle Point
    else if (geoJson.type === 'Point') {
      coordinates.push({ longitude: geoJson.coordinates[0], latitude: geoJson.coordinates[1] });
    }

    return coordinates;
  }

  // Helper function to calculate bounding extent
  calculateBoundingExtent(coordinates) {
    if (!coordinates.length) {
      return null;
    }

    const extent = coordinates.reduce(
      (acc, coord) => ({
        xmin: Math.min(acc.xmin, coord.longitude),
        ymin: Math.min(acc.ymin, coord.latitude),
        xmax: Math.max(acc.xmax, coord.longitude),
        ymax: Math.max(acc.ymax, coord.latitude),
      }),
      {
        xmin: Number.MAX_SAFE_INTEGER,
        ymin: Number.MAX_SAFE_INTEGER,
        xmax: Number.MIN_SAFE_INTEGER,
        ymax: Number.MIN_SAFE_INTEGER,
      }
    );

    if (
      !isNaN(extent.xmin) &&
      !isNaN(extent.xmax) &&
      !isNaN(extent.ymin) &&
      !isNaN(extent.ymax) && // Check all values are valid numbers
      isFinite(extent.xmin) &&
      isFinite(extent.xmax) &&
      isFinite(extent.ymin) &&
      isFinite(extent.ymax) // Ensure all values are finite
    ) {
      if (extent.xmin === extent.xmax && extent.ymin === extent.ymax) {
        // Case: Single point
        console.warn('Extent represents a single point.');
        return null; // Or handle as a valid point
      } else if (extent.xmin === extent.xmax) {
        // Case: Vertical line
        return new Extent(extent);
      } else if (extent.ymin === extent.ymax) {
        // Case: Horizontal line
        return new Extent(extent);
      } else if (extent.xmin < extent.xmax && extent.ymin < extent.ymax) {
        // Case: Valid extent
        return new Extent(extent);
      } else {
        // Case: Invalid extent
        console.error('Invalid extent: Extent boundaries are malformed.');
        return null;
      }
    } else {
      console.error('Invalid extent: Contains non-numeric or infinite values.');
      return null;
    }
  }

  onMapLoaded($event: __esri.Map) {
    this.mapComponentInstance = $event;
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
