import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-internal-test-details-form',
  templateUrl: './internal-test-details-form.component.html',
  styleUrls: ['../../input-component-styles.scss',
    './internal-test-details-form.component.scss'],
})
export class InternalTestDetailsFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup = new FormGroup({});
  @Input() fields: any[] = [];
  @Input() selectedMediaFiles: any[] = [];
  @Output() onDeletePicture: EventEmitter<void> = new EventEmitter();
  errorMessages = {};
  warningMessages = {};
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        () => {
          this.validateForm();
        },
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.validateForm();
    this.cdRef.detectChanges();
  }

  deletePicture(id) {
    this.onDeletePicture.emit(id);
  }

  deleteLocation() {
    this.form?.get('latitude').setValue(null);
    this.form?.get('longitude').setValue(null);
  }

  showLocationSection() {
    return this.form?.controls?.latitude?.value !== null &&
      this.form?.controls?.longitude?.value !== null &&
      this.form?.controls?.latitude?.value !== '' &&
      this.form?.controls?.longitude?.value !== '';
  }

  validateForm() {
    this.errorMessages = {};
    this.warningMessages = {};

    for (const key in this.form.controls) {
      if (this.form.controls[key]?.errors) {
        if (this.form.controls[key]?.errors.required) {
          this.errorMessages[key] = 'Required';
        } else if (this.form.controls[key]?.errors.min) {
          this.errorMessages[key] = `Min: ${this.form.controls[key]?.['validation_rules'].min}`;
        } else if (this.form.controls[key]?.errors.max) {
          this.errorMessages[key] = `Max: ${this.form.controls[key]?.['validation_rules'].max}`;
        }
      }
    }

    this.fields.forEach(field => {
      const formField = this.form.get(field.id);
      const warningRules = formField?.['warning_rules'];

      if (formField.value && warningRules) {
        const value = Number(formField.value);
        const { min, max } = warningRules;

        const isBelowMin = min != null && value < min;
        const isAboveMax = max != null && value > max;

        if (isBelowMin || isAboveMax) {
          const messages = [];
          if (isBelowMin) {
            messages.push(`Min ${min}`);
          }
          if (isAboveMax) {
            messages.push(`Max ${max}`);
          }
          this.warningMessages[field.id] = `Range: ${messages.join(', ')}`;
        }
      }

    });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
