import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';
import * as fromRoot from '../../../state/app.state';
import {
  AddContractorStatusFailure,
  AddContractorStatusRequest,
  AddContractorStatusSuccess,
  AddIssuesDetailsFailure,
  AddIssuesDetailsRequest,
  AddIssuesDetailsSuccess,
  ChangeIssueStatusFailure,
  ChangeIssueStatusRequest,
  ChangeIssueStatusSuccess,
  CheckIfDailyReportCanBeCreatedFailure,
  CheckIfDailyReportCanBeCreatedRequest,
  CheckIfDailyReportCanBeCreatedSuccess,
  CreateDailyReportFailure,
  CreateDailyReportRequest,
  CreateDailyReportSuccess,
  DeletePayappFailure,
  DeletePayappRequest,
  DeletePayappSuccess,
  DeleteVideoFromMuxDbFailure,
  DeleteVideoFromMuxDbRequest,
  DeleteVideoFromMuxDbSuccess,
  ExecuteCurrentActionStateFailure,
  ExecuteCurrentActionStateRequest,
  ExecuteCurrentActionStateSuccess,
  FetchAllBidItemsFailure,
  FetchAllBidItemsRequest,
  FetchAllBidItemsSuccess,
  FetchBidItemListWithChangeOrderFailure,
  FetchBidItemListWithChangeOrderInSideNavFailure,
  FetchBidItemListWithChangeOrderInSideNavRequest,
  FetchBidItemListWithChangeOrderInSideNavSuccess,
  FetchBidItemListWithChangeOrderRequest,
  FetchBidItemListWithChangeOrderSuccess,
  FetchBidItemsListFailure,
  FetchBidItemsListRequest,
  FetchBidItemsListSuccess,
  FetchBidItemsSitesListFailure,
  FetchBidItemsSitesListRequest,
  FetchBidItemsSitesListSuccess,
  FetchBidSheetFailure,
  FetchBidSheetRequest,
  FetchBidSheetSuccess,
  FetchChangeOrderFilterFailure,
  FetchChangeOrderFilterInSideNavFailure,
  FetchChangeOrderFilterInSideNavRequest,
  FetchChangeOrderFilterInSideNavSuccess,
  FetchChangeOrderFilterRequest,
  FetchChangeOrderFilterSuccess,
  FetchDailyReportFailure,
  FetchDailyReportRequest,
  FetchDailyReportsByDateFailure,
  FetchDailyReportsByDateRequest,
  FetchDailyReportsByDateSuccess,
  FetchDailyReportSuccess,
  FetchEquipmentFilterFailure,
  FetchEquipmentFilterRequest,
  FetchEquipmentFilterSuccess,
  FetchExternalTestsFailure,
  FetchExternalTestsRequest,
  FetchExternalTestsSuccess,
  FetchIssuesFailure,
  FetchIssuesRequest,
  FetchIssuesSuccess,
  FetchIssueTypesFailure,
  FetchIssueTypesRequest,
  FetchIssueTypesSuccess,
  FetchLaborFilterFailure,
  FetchLaborFilterRequest,
  FetchLaborFilterSuccess,
  FetchPayappDetailsFailure,
  FetchPayappDetailsRequest,
  FetchPayappDetailsSuccess,
  FetchPayappItemsFailure,
  FetchPayappItemsRequest,
  FetchPayappItemsSuccess,
  FetchPayappsFailure,
  FetchPayappsRequest,
  FetchPayappsSuccess,
  FetchProjectDetailsFailure,
  FetchProjectDetailsRequest,
  FetchProjectDetailsSuccess,
  FetchProjectMembersFailure,
  FetchProjectMembersRequest,
  FetchProjectMembersSuccess,
  FetchProjectReportFailure,
  FetchProjectReportRequest,
  FetchProjectReportsListFailure,
  FetchProjectReportsListRequest,
  FetchProjectReportsListSuccess,
  FetchProjectReportSuccess,
  FetchProjectsListFailure,
  FetchProjectsListRequest,
  FetchProjectsListSuccess,
  FetchSiteDetailsFailure,
  FetchSiteDetailsRequest,
  FetchSiteDetailsSuccess,
  FetchSiteWiseDailyReportSummaryFailure,
  FetchSiteWiseDailyReportSummaryRequest,
  FetchSiteWiseDailyReportSummarySuccess,
  FetchSubContractorFilterFailure,
  FetchSubContractorFilterRequest,
  FetchSubContractorFilterSuccess,
  GeneratePayappFailure,
  GeneratePayappRequest,
  GeneratePayappSuccess,
  SaveDailyReportFailure,
  SaveDailyReportRequest,
  SaveDailyReportSuccess,
  UpdateIssuesDetailsFailure,
  UpdateIssuesDetailsRequest,
  UpdateIssuesDetailsSuccess,
  UpdatePayappStatusFailure,
  UpdatePayappStatusRequest,
  UpdatePayappStatusSuccess,
  UploadChangeOrderFileFailure,
  UploadChangeOrderFileRequest,
  UploadChangeOrderFileSuccess,
} from './projects.actions';
import { ProjectsService } from './projects.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/services';
import { DailyReportService } from '../pages/site-details-page/tab-screens/daily-report/daily-report.service';
import { DatePipe } from '@angular/common';
import { dailyReport, projectDetails } from './projects.selectors';
import { IssueService } from '../../issue/issue.service';
import { materialTestStatusMap } from '../../../shared/constants/commonConstants';
import { Parser } from 'expr-eval';

@Injectable()
export class ProjectsEffects {
  fetchProjectsList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProjectsListRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectsList(payload).pipe(
          map(response => {
            const { data } = response;

            const projectsFilterList = [];
            const sitesFilterData = {};

            data.map(d => {
              d['durationProgress'] = (100 * d.duration_used) / d.duration;
              d['valueProgress'] = (100 * d.earned_value) / d.project_value;

              projectsFilterList.push({ value: d.id, label: d.name });

              const sitesFilterList = [];
              d.sites?.map(s => {
                sitesFilterList.push({ value: s.id, label: s.name });
              });

              sitesFilterData[d.id] = sitesFilterList;
            });

            return FetchProjectsListSuccess({
              payload: {
                projectsList: data,
                projectsFilterList,
                sitesFilterData,
              },
            });
          }),
          catchError(() => {
            return of(FetchProjectsListFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchProjectsListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchProjectsListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchProjectDetails$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProjectDetailsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectDetails(payload).pipe(
          map(response => {
            const { data } = response;
            data['durationProgress'] = (100 * data.duration_used) / data.duration;
            data['valueProgress'] = (100 * data.earned_value) / data.project_value;
            data['internal_tests'] = data?.['internal_tests']?.map((test) => {
              return {
                ...test,
                value: test.id,
                label: test.name,
              };
            }) || [];

            data.internal_tests.forEach((test) => {
              test.fields?.map((field: any) => {
                if (field.range_values && Object.keys(field.range_values).length) {
                  let hintText = '';
                  const { min, max } = field.range_values;

                  if (min !== null && max !== null) {
                    hintText = `Range: min ${min} - max ${max}`;
                  } else if (min !== null) {
                    hintText = `Range min: ${min}`;
                  } else if (max !== null) {
                    hintText = `Range max: ${max}`;
                  }
                  field['hintText'] = hintText;
                }
              });
            });

            return FetchProjectDetailsSuccess({
              projectDetails: data,
            });
          }),
          catchError((e) => {
            return of(FetchProjectDetailsFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchProjectDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchProjectDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchSiteWiseDailyReportsSummary: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchSiteWiseDailyReportSummaryRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchSiteWiseDailyReportSummary(payload).pipe(
          map(response => {
            const { data } = response;

            return FetchSiteWiseDailyReportSummarySuccess({
              siteWiseDailyReportsSummary: data,
            });
          }),
          catchError(() => {
            return of(FetchSiteWiseDailyReportSummaryFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchSiteWiseDailyReportSummarySuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchSiteWiseDailyReportSummaryFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchProjectMembers$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProjectMembersRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectMembers(payload).pipe(
          map(response => {
            const { data } = response;

            const currentUser = this.authService.getCurrentUser();

            const projectMembersFilterList = data.map(o => {
              const name = `${o.first_name} ${o.last_name}`;

              return {
                value: o.id,
                label: o.id === currentUser.id ? `Me (${name})` : name,
              };
            });

            const index = projectMembersFilterList.findIndex(o => o.label.includes('Me ('));

            if (index > -1) {
              const element = projectMembersFilterList.splice(index, 1);
              projectMembersFilterList.unshift(...element);
            }

            return FetchProjectMembersSuccess({ projectMembersFilterList });
          }),
          catchError(() => {
            return of(FetchProjectMembersFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchProjectMembersSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchProjectMembersFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  createDailyReport$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(CreateDailyReportRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.createDailyReport(payload).pipe(
          map(response => {
            return CreateDailyReportSuccess({ data: response.data });
          }),
          catchError(() => {
            return of(CreateDailyReportFailure());
          }),
          tap((action: any) => {
            if (action.type === CreateDailyReportSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeCreateDailyReportDialog.next();
              this.commonService.notification('Daily report created successfully', 'success');
              const { data } = action;
              const url = `/projects/${data.project.id}/sites/${data.site.id}`;
              const qp = {
                tab: 'daily-report',
                reportId: data.id,
                date: data.report_date,
              };
              const pageUrl = this.router.url.split('?')[0];
              if (url === pageUrl) {
                this.router.navigate(['/'])
                  .then(() => {
                    this.router.navigate([url], {
                      queryParams: qp,
                    });
                  });
              } else {
                this.router.navigate([url], {
                  queryParams: qp,
                });
              }
            } else if (action.type === CreateDailyReportFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  checkIfDailyReportCanBeCreated$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(CheckIfDailyReportCanBeCreatedRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchDailyReports(payload).pipe(
          map(response => {
            const { data } = response;

            const temp = data.filter(o => o.createdBy.id === payload.createdBy && o.site.id === payload.site);

            return CheckIfDailyReportCanBeCreatedSuccess({ existingReportId: temp[0]?.id });
          }),
          catchError(() => {
            return of(CheckIfDailyReportCanBeCreatedFailure());
          }),
          tap((action: any) => {
            if (action.type === CheckIfDailyReportCanBeCreatedSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === CheckIfDailyReportCanBeCreatedFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchProjectReports$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProjectReportsListRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectReports(payload).pipe(
          map(response => {
            const { data } = response;

            return FetchProjectReportsListSuccess({ projectReportsList: data });
          }),
          catchError(() => {
            return of(FetchProjectReportsListFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchProjectReportsListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchProjectReportsListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchProjectReport$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchProjectReportRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectReports(payload).pipe(
          map(response => {
            const { data } = response;
            const cprIsPresent = !!data.length;
            const bidItemsArr = [];
            let baseBidItemsArr = [];
            const consolidatedWeathersList = [];
            const consolidatedActivityList = [];


            data.map(d => {
              d.daily_reports.forEach(dailyReport => {

                consolidatedWeathersList.push({
                  ...dailyReport?.weather,
                  dailyReportCreatedBy: dailyReport?.createdBy,
                  dailyReportId: dailyReport?.id,
                  site: dailyReport?.site,
                });

                dailyReport?.activities.forEach(eachItem => {
                  consolidatedActivityList.push({
                    ...eachItem,
                    dailyReportCreatedBy: dailyReport.createdBy,
                    dailyReportId: dailyReport.id,
                    site: dailyReport.site,
                  });
                });

                dailyReport?.bid_items.forEach(eachItem => {
                  bidItemsArr.push({
                    ...eachItem,
                    dailyReportCreatedBy: dailyReport.createdBy,
                    dailyReportId: dailyReport.id,
                    site: dailyReport.site,
                  });
                });
              });
            });

            const reducedBidItems = bidItemsArr.reduce((acc, current) => {
              if (acc[current.bid_item.id] !== undefined) {
                acc[current.bid_item.id].push(current);
              } else {
                acc[current.bid_item.id] = [current];
                baseBidItemsArr.push(JSON.parse(JSON.stringify(current.bid_item)));
              }

              return acc;
            }, {});

            baseBidItemsArr = baseBidItemsArr.map((o) => {
              o.children = reducedBidItems[o.id];

              o.totalQuantity = 0;
              o.totalAmount = 0;

              o.status = {
                agree: 0,
                disagree: 0,
              };

              const sites = [];
              const createdBy = [];

              o.children = o.children.map(m => {
                m.status = m.contractor_status || 1;

                if (!m.contractor_status) {
                  o.status.agree = o.status.agree + 1;
                } else {
                  if (m.contractor_status === 'agree') {
                    m.status = 1;
                    o.status.agree = o.status.agree + 1;
                  } else if (m.contractor_status === 'disagree') {
                    m.status = 0;
                    o.status.disagree = o.status.disagree + 1;
                  }
                }

                o.totalQuantity += m.quantity;

                if (m.quantity > 0) {
                  m.totalAmount = m.quantity * m.bid_item.unit_price;
                  o.totalAmount += m.totalAmount;
                } else if (m.bid_item?.rollup && m.bid_item?.rollup_formula) {
                  const fields = m.bid_item?.fields || [];
                  const fieldValues = m?.field_values || [];
                  const rollupFormula = m.bid_item?.rollup_formula;

                  m.quantity = this.calculateRollupQuantity(fields, fieldValues, rollupFormula);
                  m.totalAmount = m.quantity * m.bid_item.unit_price;
                  o.totalAmount += m.totalAmount;
                  o.totalQuantity += +m.quantity;
                } else {
                  m.totalAmount = 0;
                }

                if (!sites.includes(m.site.id)) {
                  sites.push(m.site.id);
                }

                if (!createdBy.includes(m.dailyReportCreatedBy.id)) {
                  createdBy.push(m.dailyReportCreatedBy.id);
                }
                return m;
              });

              o.totalSites = sites.length;
              o.createdByCount = createdBy.length;

              return o;
            });

            baseBidItemsArr.forEach((baseBidItem) => {
              baseBidItem.children.forEach((eachChild) => {
                const combinedMedia = [];
                if (eachChild.contractor_pictures && eachChild.contractor_pictures.length > 0) {
                  eachChild.contractor_pictures.forEach((picture) => {
                    picture.fileType = 'picture';
                    combinedMedia.push(picture);
                  });
                }
                if (eachChild.contractor_videos && eachChild.contractor_videos.length > 0) {
                  eachChild.contractor_videos.forEach((video) => {
                    video.fileType = 'video';
                    combinedMedia.push(video);
                  });
                }

                eachChild.media = combinedMedia;
              });
            });

            return FetchProjectReportSuccess({
              projectReport: data[0],
              consolidatedBidItemsList: baseBidItemsArr,
              consolidatedWeathersList,
              consolidatedActivityList,
              cprIsPresent,
            });
          }),
          catchError(() => {
            return of(FetchProjectReportFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchProjectReportSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeRightPanel.next();
              if (action?.cprIsPresent) {
                const fetchIssuesRequestPayload = {
                  siteId: action?.dailyReport?.site_id,
                  qp: {
                    start: 0,
                    total: 1000,
                    projectId: payload?.qp?.projectId,
                  },
                };

                this.store.dispatch(FetchIssuesRequest({ payload: fetchIssuesRequestPayload }));
              }

            } else if (action.type === FetchProjectReportFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchPayappItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(FetchPayappItemsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchPayappItems(payload).pipe(
          map(response => {
            const payAppItems = response.data;
            payAppItems.forEach((eachChild) => {
              const combinedMedia = [];
              if (eachChild.contractor_pictures && eachChild.contractor_pictures.length > 0) {
                eachChild.contractor_pictures.forEach((picture) => {
                  picture.fileType = 'picture';
                  combinedMedia.push(picture);
                });
              }
              if (eachChild.contractor_videos && eachChild.contractor_videos.length > 0) {
                eachChild.contractor_videos.forEach((video) => {
                  video.fileType = 'video';
                  combinedMedia.push(video);
                });
              }

              eachChild.media = combinedMedia;
            });

            return FetchPayappItemsSuccess({ payappItems: payAppItems });
          }),
          catchError(() => of(FetchPayappItemsFailure())),
          tap(action => {
            this.commonService.stopLoading();

            if (action.type === FetchPayappItemsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchPayappItemsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          }),
        ),
      ),
    ),
  );

  fetchPayapps$ = createEffect(() =>
    this.actions.pipe(
      ofType(FetchPayappsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchPayapps(payload).pipe(
          map(response => FetchPayappsSuccess({ payapps: response.data })),
          catchError(() => of(FetchPayappsFailure())),
          tap(action => {
            this.commonService.stopLoading();

            if (action.type === FetchPayappsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchPayappsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          }),
        ),
      ),
    ),
  );

  fetchPayappdetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(FetchPayappDetailsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchPayappDetails(payload).pipe(
          map(response => {
            const { bid_items, id, name } = response.data;
            return FetchPayappDetailsSuccess({
              payappItems: bid_items,
              payappId: id,
              payappName: name,
            });
          }),
          catchError(() => of(FetchPayappDetailsFailure())),
          tap(action => {
            this.commonService.stopLoading();

            if (action.type === FetchPayappDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchPayappDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          }),
        ),
      ),
    ),
  );

  generatePayapp$ = createEffect(() =>
    this.actions.pipe(
      ofType(GeneratePayappRequest),
      mergeMap(action => {
        this.commonService.startLoading();
        return this.projectsService.generatePayapp(action.payload).pipe(
          map(response => GeneratePayappSuccess({ data: response.data })),
          catchError(() => of(GeneratePayappFailure())),
          tap((action: any) => {
            this.commonService.stopLoading();
            if (action.type === GeneratePayappSuccess.type) {
              this.commonService.notification('Payapp generated successfully', 'success');
            } else if (action.type === GeneratePayappFailure.type) {
              console.error('Failed to generate pay app');
            }
          }),
          withLatestFrom(this.store.select(projectDetails)),  // Combine with latest project details from store
          concatMap(([action, projectDetails]) => {
            if (action.type === GeneratePayappSuccess.type) {
              const projectId = projectDetails.id; // Retrieve projectId from store
              const generatedPayappId = action.data.id;

              // Step 1: Dispatch FetchPayappsRequest
              this.store.dispatch(FetchPayappsRequest({
                payload: {
                  projectId, // Use projectId from store
                },
              }));

              // Return an object that keeps projectId and generatedPayappId in scope
              return of({ projectId, generatedPayappId });
            } else {
              // If the action is not GeneratePayappSuccess, return an empty observable
              return EMPTY;
            }
          }),
          concatMap(({ projectId, generatedPayappId }) => {
            const qp = {
              includeDeferred: true,
              includeRejected: true,
            };

            this.store.dispatch(FetchPayappItemsRequest({
              payload: {
                projectId,
                qp,
              },
            }));
            return of({ projectId, generatedPayappId });

          }),
          concatMap(({ projectId, generatedPayappId }) => {
            // Step 2: Once prior requests completes, dispatch FetchPayappDetailsRequest
            return of(FetchPayappDetailsRequest({
              payload: {
                projectId,
                payappId: generatedPayappId,
                qp: {
                  include: ['bid_items'],
                },
              },
            }));
          }),
        );
      }),
    ),
  );

  siteDetailsReport$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchSiteDetailsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchSiteDetails(payload).pipe(
          map(response => {
            const { data } = response;
            data.headings = data?.headings.map(item => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              };
            });
            const siteDetails = {
              id: data?.id,
              name: data?.name,
              project: {
                id: data?.project?.id,
                name: data?.project?.name,
                owner: data?.project?.owner_organization?.name,
              },
              latitude: data?.latitude,
              longitude: data?.longitude,
              imageUrl: data?.project?.cover_photo?.thumb_url,
              headings: data?.headings || [],
            };

            return FetchSiteDetailsSuccess({ siteDetails });
          }),
          catchError(() => {
            return of(FetchSiteDetailsFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchSiteDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchSiteDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchDailyReportsByDate$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchDailyReportsByDateRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchDailyReports(payload).pipe(
          map(response => {
            const { data } = response;

            return FetchDailyReportsByDateSuccess({ dailyReportsByDate: data });
          }),
          catchError(() => {
            return of(FetchDailyReportsByDateFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchDailyReportsByDateSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchDailyReportsByDateFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchDailyReportById$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchDailyReportRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchDailyReport(payload).pipe(
          map(response => {
            const { data } = response;
            const {
              activities,
              weather,
              labor,
              equipment,
              internal_tests,
            } = data;

            let { issues } = data;

            // Restructure bid items so that they are grouped by bid item id and heading and
            // can be used further as the basis of creating the UI

            const bidItems: any = {};

            for (const currentBidItem of data.bid_items) {
              if (bidItems[`${currentBidItem.bid_item.id}_${currentBidItem.heading_id}`] === undefined) {
                bidItems[`${currentBidItem.bid_item.id}_${currentBidItem.heading_id}`] = [currentBidItem];
              } else {
                bidItems[`${currentBidItem.bid_item.id}_${currentBidItem.heading_id}`].push(currentBidItem);
              }
            }

            const bidItemsArr = [];

            for (const key in bidItems) {
              if (bidItems.hasOwnProperty(key)) {
                bidItemsArr.push({
                  id: bidItems?.[key]?.[0]?.bid_item?.id,
                  bidItem: bidItems?.[key]?.[0]?.bid_item,
                  heading: bidItems?.[key]?.[0]?.heading,
                  ...(bidItems?.[key]?.[0]?.bid_item?.record_by_station ?
                    { stations: bidItems?.[key] } :
                    { lineItems: bidItems?.[key] }),
                });
              }
            }

            bidItemsArr.forEach((bidItem) => {
              bidItem.lineItems.forEach((lineItem) => {
                const combinedMedia = [];
                if (lineItem.pictures && lineItem.pictures.length > 0) {
                  lineItem.pictures.forEach((picture) => {
                    picture.fileType = 'picture';
                    combinedMedia.push(picture);
                  });
                }
                if (lineItem.videos && lineItem.videos.length > 0) {
                  lineItem.videos.forEach((video) => {
                    video.fileType = 'video';
                    combinedMedia.push(video);
                  });
                }

                lineItem.pictures = combinedMedia;

                if (lineItem.sources.length > 0) {
                  if (lineItem.sources.length > 1) {
                    lineItem.sourcesSection = {
                      heading: `${lineItem.created_at}`,
                      subheading: `${lineItem.sources.length} Inspectors`,
                      sourcesList: [],
                    };
                  } else {
                    lineItem.sourcesSection = {
                      heading: `${lineItem.created_at}`,
                      subheading: `${lineItem.sources[0].source_inspector} (${lineItem.sources[0].source_name})`,
                      sourcesList: [],
                    };
                  }

                  const { fields } = lineItem.bid_item;

                  lineItem.sources.forEach(o => {
                    const fieldValues = {};
                    fields.forEach((field) => {
                      fieldValues[field.id] = {
                        id: field.id,
                        value: null
                      };

                      o.source_fields?.forEach((sourceField) => {
                        for (const key in sourceField) {
                          if (field.name === key) {
                            fieldValues[field.id] = {
                              id: field.id,
                              value: sourceField[key],
                            };
                          }
                        }
                      });

                      o.modified_fields?.forEach((modifiedField) => {
                        for (const key in modifiedField) {
                          if (field.name === key) {
                            fieldValues[field.id] = {
                              id: field.id,
                              value: modifiedField[key],
                            };
                          }
                        }
                      });
                    });

                    lineItem.sourcesSection.sourcesList.push({
                      ...o,
                      fieldValues: Object.values(fieldValues),
                    });
                  });
                }
              });
            });

            // Restructure material_deliveries into delivery and usage sections
            const formattedMaterialDelivery = this.dailyReportService.formatDeliveriesAndUsages(data.material_deliveries);

            formattedMaterialDelivery.deliveries.forEach((eachDelivery) => {
              const combinedMedia = [];
              if (eachDelivery.pictures && eachDelivery.pictures.length > 0) {
                eachDelivery.pictures.forEach((picture) => {
                  picture.fileType = 'picture';
                  combinedMedia.push(picture);
                });
              }
              if (eachDelivery.videos && eachDelivery.videos.length > 0) {
                eachDelivery.videos.forEach((video) => {
                  video.fileType = 'video';
                  combinedMedia.push(video);
                });
              }

              eachDelivery.media = combinedMedia;
            });

            formattedMaterialDelivery.usages.forEach((eachUsage) => {
              const combinedMedia = [];
              if (eachUsage.pictures && eachUsage.pictures.length > 0) {
                eachUsage.pictures.forEach((picture) => {
                  picture.fileType = 'picture';
                  combinedMedia.push(picture);
                });
              }
              if (eachUsage.videos && eachUsage.videos.length > 0) {
                eachUsage.videos.forEach((video) => {
                  video.fileType = 'video';
                  combinedMedia.push(video);
                });
              }

              eachUsage.media = combinedMedia;
            });


            let issuesScopeOptions = [
              {
                ...data.project,
                type: 'project',
              },
              {
                ...data.site,
                type: 'site',
              },
            ];
            issuesScopeOptions = issuesScopeOptions.map(scope => {
              return {
                label: scope.name,
                value: scope.id,
                type: scope.type,
              };
            });

            issues = issues.map(eachIssue => {
              return {
                ...eachIssue,
                issuesScopeOptions,
                projectId: data?.project?.id,
              };
            });

            // Restructure material_deliveries into delivery and usage sections

            const delivery = [];
            const usage = [];

            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < data?.material_deliveries?.length; i++) {
              const element = data?.material_deliveries[i];

              if (
                (element.bid_item !== null && element.material === null) ||
                (element.bid_item === null && element.material !== null && element.received !== null) ||
                (element.bid_item !== null && element.material !== null && element.received !== null)
              ) {
                delivery.push(element);
              }

              if (
                (element.bid_item === null && element.material !== null && element.used !== null) ||
                (element.bid_item !== null && element.material !== null && element.used !== null)
              ) {
                usage.push(element);
              }
            }

            activities.forEach((activity) => {
              const combinedMedia = [];
              if (activity.pictures && activity.pictures.length > 0) {
                activity.pictures.forEach((picture) => {
                  picture.fileType = 'picture';
                  combinedMedia.push(picture);
                });
              }
              if (activity.videos && activity.videos.length > 0) {
                activity.videos.forEach((video) => {
                  video.fileType = 'video';
                  combinedMedia.push(video);
                });
              }

              activity.media = combinedMedia;
            });

            issues.forEach((issue) => {
              const combinedMedia = [];
              if (issue.pictures && issue.pictures.length > 0) {
                issue.pictures.forEach((picture) => {
                  picture.fileType = 'picture';
                  combinedMedia.push(picture);
                });
              }
              if (issue.videos && issue.videos.length > 0) {
                issue.videos.forEach((video) => {
                  video.fileType = 'video';
                  combinedMedia.push(video);
                });
              }

              issue.media = combinedMedia;
            });

            let pDInternalTests: any = {};

            this.store.select(projectDetails)
              .subscribe(details => {
                pDInternalTests = details.internal_tests;
              }).unsubscribe();

            internal_tests.forEach((test) => {
              const combinedMedia = [];
              if (test.pictures && test.pictures.length > 0) {
                test.pictures.forEach((picture) => {
                  picture.fileType = 'picture';
                  combinedMedia.push(picture);
                });
              }
              if (test.videos && test.videos.length > 0) {
                test.videos.forEach((video) => {
                  video.fileType = 'video';
                  combinedMedia.push(video);
                });
              }

              test.media = combinedMedia;
            });


            internal_tests.forEach((it: any) => {
              const filteredPdInternalTestsFields = pDInternalTests.find((pdTest: any) => pdTest.id === it.internal_test_id).fields;

              it.field_values = it.field_values.map((fieldValue: any) => {
                const matchingField = filteredPdInternalTestsFields.find((field: any) => field.id === fieldValue.id);

                if (matchingField) {
                  return {
                    ...matchingField,
                    value: fieldValue.value,
                  };
                }

                return fieldValue;
              });
            });

            internal_tests.forEach((it: any) => {
              const fieldValueSummaryArray = [];
              it.field_values.forEach((field: any) => {
                const value = `${field.value} ${field.uom || ''}`.trim();
                fieldValueSummaryArray.push(value);
              });
              it.fieldValueSummary = fieldValueSummaryArray.join(', ');
            });

            const groupedByInternalTestId = {};
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < internal_tests.length; i++) {
              const key = `${internal_tests[i].internal_test_id}`;
              if (groupedByInternalTestId[key]) {
                groupedByInternalTestId[key].push(internal_tests[i]);
              } else {
                groupedByInternalTestId[key] = [internal_tests[i]];
              }
            }

            const groupedByInternalTestIdCount = Object.keys(groupedByInternalTestId).length;

            let internalTestsWithStations = internal_tests.filter(o => !!o.station);
            let internalTestsWithoutStations = internal_tests.filter(o => !o.station);

            internalTestsWithoutStations = internalTestsWithoutStations.reduce((rv, x) => {
              if (rv[`${x.internal_test_id}_${x.heading?.id}`]) {
                rv[`${x.internal_test_id}_${x.heading?.id}`].push(x);
              } else {
                rv[`${x.internal_test_id}_${x.heading?.id}`] = [x];
              }

              return rv;
            }, {});

            internalTestsWithStations = internalTestsWithStations.reduce((rv, x) => {
              if (rv[`${x.internal_test_id}_${x.heading?.id}`]) {
                rv[`${x.internal_test_id}_${x.heading?.id}`].push(x);
              } else {
                rv[`${x.internal_test_id}_${x.heading?.id}`] = [x];
              }

              return rv;
            }, {});

            const groupings = {};

            // tslint:disable-next-line:forin
            for (const key in internalTestsWithStations) {
              const arr = [...internalTestsWithStations[key]];

              groupings[key] = arr.reduce((rv, x) => {
                if (rv[`${key}_${x.station.id}`]) {
                  rv[`${key}_${x.station.id}`].push(x);
                } else {
                  rv[`${key}_${x.station.id}`] = [x];
                }

                return rv;
              }, {});
            }

            // tslint:disable-next-line:forin
            for (const key in groupings) {
              groupings[key] = Object.values(groupings[key]) || [];
            }

            internalTestsWithStations = Object.values(groupings) || [];
            internalTestsWithoutStations = Object.values(internalTestsWithoutStations) || [];

            internalTestsWithStations = internalTestsWithStations.map((tests: any) => {
              const testData: any = {
                type: 'withStations',
              };

              tests.forEach(o => {
                testData.id = `${o[0].heading?.id}_${o[0].internal_test.id}`;
                testData.testId = o[0].internal_test.id;
                testData.testName = o[0].internal_test.name;
                testData.bidItemId = o[0].bid_item ? o[0].bid_item?.id : null;
                testData.bidItemName = o[0].bid_item ? `Bid Item ${o[0].bid_item?.item} ${o[0].bid_item?.description}` : null;
                testData.headingId = o[0].heading?.id;
                testData.headingName = o[0].heading?.name;
              });

              testData.list = tests.map(l => {
                const childData: any = {};

                childData.stationId = l[0].station.id;
                childData.stationName = l[0].station.name;
                childData.station = l[0].station;
                childData.list = l;

                return childData;
              });


              return testData;
            });

            internalTestsWithoutStations = internalTestsWithoutStations.map((tests: any) => {
              const testData: any = {
                type: 'withoutStations',
              };

              testData.id = `${tests[0].heading?.id}_${tests[0].internal_test.id}`;
              testData.testId = tests[0].internal_test.id;
              testData.testName = tests[0].internal_test.name;
              testData.bidItemId = tests[0].bid_item ? tests[0].bid_item?.id : null;
              testData.bidItemName = tests[0].bid_item ? `Bid Item ${tests[0].bid_item?.item} ${tests[0].bid_item?.description}` : null;
              testData.headingId = tests[0].heading?.id;
              testData.headingName = tests[0].heading?.name;

              testData.list = tests;

              return testData;
            });

            return FetchDailyReportSuccess({
              dailyReport: {
                ...data,
                reportDetails: {
                  bidItems: this.dailyReportService.formatBidItems(data.bid_items),
                  activities,
                  weather,
                  deliveries: formattedMaterialDelivery.deliveries,
                  usages: formattedMaterialDelivery.usages,
                  issues,
                  labors: labor,
                  equipments: equipment,
                  materialDelivery: {
                    delivery,
                    usage,
                  },
                  materialTests: {
                    internal: {
                      groupedInternalTests: [
                        ...internalTestsWithStations,
                        ...internalTestsWithoutStations,
                      ],
                      groupedByInternalTestIdCount,
                    },
                  },
                },
              },
            });
          }),
          catchError(() => {
            return of(FetchDailyReportFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchDailyReportSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeRightPanel.next();

              const fetchExternalTestsRequestPayload = {
                siteId: action?.dailyReport?.site_id,
                qp: {
                  start: 0,
                  total: 1000,
                },
              };

              this.store.dispatch(FetchExternalTestsRequest({ payload: fetchExternalTestsRequestPayload }));
            } else if (action.type === FetchDailyReportFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  saveDailyReport$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SaveDailyReportRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.saveDailyReport(payload).pipe(
          map(() => {
            return SaveDailyReportSuccess({});
          }),
          catchError(() => {
            return of(SaveDailyReportFailure());
          }),
          tap((action: any) => {
            if (action.type === SaveDailyReportSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.dailyReportService.fetchDailyReportById.next();
              this.projectsService.isAnyFormDirty.next(false);
              this.commonService.notification('Report saved successfully.', 'success');
            } else if (action.type === SaveDailyReportFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchAllBidItems$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchAllBidItemsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchProjectDetails(payload).pipe(
          map(response => {
            const { data } = response;
            const tempData = data.bid_items.map((bidItem: any) => {
              return {
                ...bidItem,
                label: bidItem.description,
                value: bidItem.id,
              };
            });
            return FetchAllBidItemsSuccess({
              allBidItems: data.bid_items,
              allBidItemsFilter: tempData,
            });
          }),
          catchError(() => {
            return of(FetchAllBidItemsFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchAllBidItemsSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchAllBidItemsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchBidSheet$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchBidSheetRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchBidSheet(payload).pipe(
          map(response => {
            const { data } = response;
            return FetchBidSheetSuccess({
              bidSheet: data,
            });
          }),
          catchError(() => {
            return of(FetchBidSheetFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchBidSheetSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchBidSheetFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchBidItems$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchBidItemsListRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchBidItems(payload).pipe(
          map(response => {
            const { data, meta } = response;
            return FetchBidItemsListSuccess({
              bidItemsListInfo: { list: data, totalRecords: meta.totalRecords },
            });
          }),
          catchError(() => {
            return of(FetchBidItemsListFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchBidItemsListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchBidItemsListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  uploadChangeOrderFile$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UploadChangeOrderFileRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.uploadChangeOrderFile(payload).pipe(
          map(response => {
            const { data } = response;
            return UploadChangeOrderFileSuccess({
              changeOrderList: data,
            });
          }),
          catchError((error) => {
            let displayError: any;
            if (error.error.status === 422) {
              displayError = Object.entries(error?.error?.error).map(
                ([key, value]) => {
                  const rowNumber = +value['param']?.split('.')[0] + 1;
                  const columnName = value['param']?.split('.')[1];
                  return {
                    rowNumber,
                    columnName,
                    msg: value['msg'],
                  };
                },
              );
            }
            if (error.error.status === 500) {
              displayError = 'An Unknown error occurred, please check you file type';
            }
            this.projectsService.error.next(displayError);
            return of(UploadChangeOrderFileFailure({}));
          }),
          tap((action: any) => {
            if (action.type === UploadChangeOrderFileSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog.next();
              this.commonService.openSuccessDialog.next();
            } else if (action.type === UploadChangeOrderFileFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchBidItemsSitesList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchBidItemsSitesListRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchBidItemsSitesList(payload).pipe(
          map(response => {
            const { data } = response;
            const { site_quantities, ...rest } = data;
            return FetchBidItemsSitesListSuccess({
              bidItemsSiteListInfo: {
                list: site_quantities,
                bidItemInfo: rest,
              },
            });
          }),
          catchError(() => {
            return of(FetchBidItemsSitesListFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchBidItemsSitesListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchBidItemsSitesListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchBidItemListWithChangeOrder$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchBidItemListWithChangeOrderRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchBidItemListWithChangeOrder(payload).pipe(
          map(response => {
            const { data } = response;
            const { bid_items } = data;
            const totalRecords = bid_items?.length;
            return FetchBidItemListWithChangeOrderSuccess({
              bidItemsListInfo: { list: bid_items, totalRecords },
            });
          }),
          catchError(() => {
            return of(FetchBidItemListWithChangeOrderFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchBidItemListWithChangeOrderSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchBidItemListWithChangeOrderFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchBidItemListWithChangeOrderInSideNav$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchBidItemListWithChangeOrderInSideNavRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchBidItemListWithChangeOrderInSideNav(payload).pipe(
          map(response => {
            const { data } = response;
            const { bid_items } = data;
            const { sites, ...rest } = (bid_items.filter(o => o.bid_item_id === payload.bidItemId))[0];
            const sitesList = bid_items?.filter(bi => bi.bid_item_id === payload?.bidItemId)[0]?.sites;
            return FetchBidItemListWithChangeOrderInSideNavSuccess({
              bidItemsSiteListInfo: {
                list: sitesList,
                bidItemInfo: rest,
              },
            });
          }),
          catchError(() => {
            return of(FetchBidItemListWithChangeOrderInSideNavFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchBidItemListWithChangeOrderInSideNavSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchBidItemListWithChangeOrderInSideNavFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  deletePayapp$ = createEffect(() =>
    this.actions.pipe(
      ofType(DeletePayappRequest),
      mergeMap(action => {
        this.commonService.startLoading();
        return this.projectsService.deletePayapp(action.payload.projectId, action.payload.payappId).pipe(
          map(response => DeletePayappSuccess({ data: response })),
          catchError(() => of(DeletePayappFailure())),
          tap((action: any) => {
            this.commonService.stopLoading();
            if (action.type === DeletePayappFailure.type) {
              this.commonService.notification('Failed to recall pay app', 'error');
            }
          }),
          withLatestFrom(this.store.select(projectDetails)),
          concatMap(([action, projectDetails]) => {
            if (action.type === DeletePayappSuccess.type) {
              const projectId = projectDetails?.id;

              // Step 1: Dispatch FetchPayappsRequest
              this.store.dispatch(FetchPayappsRequest({
                payload: {
                  projectId,
                },
              }));
              return of({ projectId });
            } else {
              return EMPTY;
            }
          }),
          concatMap(({ projectId }) => {
            const qp = {
              includeDeferred: true,
              includeRejected: true,
            };
            this.store.dispatch(FetchPayappItemsRequest({
              payload: {
                projectId,
                qp,
              },
            }));
            return of({ projectId });
          }),
          concatMap(({ projectId }) => {
            this.commonService.notification('Payapp recalled successfully', 'success');
            return of(DeletePayappSuccess({ data: projectId }));
          }),
        );

      }),
    ),
  );

  updatePayappStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdatePayappStatusRequest),
      mergeMap(action => {
        return this.projectsService.updatePayappStatus(action.payload).pipe(
          map(response => UpdatePayappStatusSuccess({ data: response.data })),
          catchError(() => of(UpdatePayappStatusFailure())),
        );
      }),
    ),
  );

  fetchIssueTypes$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchIssueTypesRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchIssueTypes(payload).pipe(
          map(response => {
            const { data } = response;

            const issueTypesLabelMap = {};

            data.forEach((item) => {
              issueTypesLabelMap[item.type] = item.label;
            });

            return FetchIssueTypesSuccess({
              issueTypesLabelMap,
              issueTypesFilter: data.map(o => ({ value: o.id, label: o.label, ...o })),
            });
          }),
          catchError(() => {
            return of(FetchIssueTypesFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchIssueTypesSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchIssueTypesFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchSubContractorFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchSubContractorFilterRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.projectsService.fetchSubContractorFilter(payload).pipe(
          map(response => {
            const { data } = response;
            const tempData = data.map((item) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              };
            });
            return FetchSubContractorFilterSuccess({
              subContractorFilter: tempData,
            });
          }),
          catchError(() => {
            return of(FetchSubContractorFilterFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchSubContractorFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchSubContractorFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchLaborFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchLaborFilterRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.projectsService.fetchLaborFilter(payload).pipe(
          map(response => {
            const { data } = response;
            const tempData = data.map((item) => {
              return {
                ...item,
                value: item.value,
                label: item.value,
              };
            });
            return FetchLaborFilterSuccess({
              laborFilter: tempData,
            });
          }),
          catchError(() => {
            return of(FetchLaborFilterFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchLaborFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchLaborFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchEquipmentFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchEquipmentFilterRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.projectsService.fetchEquipmentFilter(payload).pipe(
          map(response => {
            const { data } = response;
            const tempData = data.map((item) => {
              return {
                ...item,
                value: item.value,
                label: item.value,
              };
            });
            return FetchEquipmentFilterSuccess({
              equipmentFilter: tempData,
            });
          }),
          catchError(() => {
            return of(FetchEquipmentFilterFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchEquipmentFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchEquipmentFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  updateIssueDetails$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateIssuesDetailsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.updateIssuesDetails(payload).pipe(
          map(() => {
            return UpdateIssuesDetailsSuccess({});
          }),
          catchError(() => {
            return of(UpdateIssuesDetailsFailure());
          }),
          tap((action: any) => {
            if (action.type === UpdateIssuesDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeRightPanel.next(true);
              this.projectsService.isAnyFormDirty.next(false);
              this.issueService.fetchIssuesList.next(true);
              this.dailyReportService.fetchDailyReportById.next(true);
            } else if (action.type === UpdateIssuesDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  addIssueDetails$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AddIssuesDetailsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.addIssuesDetails(payload).pipe(
          map(() => {
            return AddIssuesDetailsSuccess({});
          }),
          catchError(() => {
            return of(AddIssuesDetailsFailure());
          }),
          tap((action: any) => {
            if (action.type === AddIssuesDetailsSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeRightPanel.next(true);
              this.projectsService.isAnyFormDirty.next(false);
              this.issueService.fetchIssuesList.next(true);
              this.dailyReportService.fetchDailyReportById.next(true);
            } else if (action.type === AddIssuesDetailsFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  changeIssueStatus$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(ChangeIssueStatusRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.changeIssueStatus(payload).pipe(
          map(() => {
            return ChangeIssueStatusSuccess({});
          }),
          catchError(() => {
            return of(ChangeIssueStatusFailure());
          }),
          tap((action: any) => {
            if (action.type === ChangeIssueStatusSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.closeRightPanel.next(true);
              this.projectsService.isAnyFormDirty.next(false);
              this.issueService.fetchIssuesList.next(true);
              this.dailyReportService.fetchDailyReportById.next(true);
            } else if (action.type === ChangeIssueStatusFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchChangeOrderFilter$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchChangeOrderFilterRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchChangeOrderFilter(payload).pipe(
          map(response => {
            const { data } = response;
            const tempData = data.map((item) => {
              return {
                ...item,
              };
            });
            const sortedTempData = tempData.sort((a, b) => {
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
            return FetchChangeOrderFilterSuccess({
              list: sortedTempData,
            });
          }),
          catchError(() => {
            return of(FetchChangeOrderFilterFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchChangeOrderFilterSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchChangeOrderFilterFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  executeCurrentActionStateRequest$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(ExecuteCurrentActionStateRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.executeCurrentActionStateRequest(payload?.executeCurrentActionStatePayload).pipe(
          map(response => {
            const { data } = response;
            return ExecuteCurrentActionStateSuccess({ data });
          }),
          catchError(() => {
            return of(ExecuteCurrentActionStateFailure());
          }),
          tap((action: any) => {
            if (action.type === ExecuteCurrentActionStateSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.projectsService.isAnyFormDirty.next(false);
              const projectId = payload.fetchNextActionsStatePayload.qp.project_id;
              const siteId = payload.fetchNextActionsStatePayload.qp.site_id;
              const url = `/projects/${projectId}/sites/${siteId}`;
              const qp = {
                tab: 'daily-report',
                reportId: action.data.id,
                date: action.data.report_date,
              };

              this.router.navigate(['/'])
                .then(() => {
                  this.router.navigate([url], {
                    queryParams: qp,
                  });
                });
            } else if (action.type === ExecuteCurrentActionStateFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchExternalTests$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchExternalTestsRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchExternalTests(payload).pipe(
          map(response => {
            // Check if data is an array and log its length
            const { data } = response;
            const openTests = [];
            const closedTests = [];

            // Iterate over each item and categorize it based on status
            data.forEach((item) => {
              const statusInfo = materialTestStatusMap[item.status];

              // Categorize into open or closed tests based on status group
              if (statusInfo.group === 'open') {
                openTests.push({ ...item, statusInfo });
              } else if (statusInfo.group === 'closed') {
                closedTests.push({ ...item, statusInfo });
              }
            });
            let dr: any = {};

            // Fetch daily report and clone it
            this.store.select(dailyReport)
              .subscribe(report => {
                dr = JSON.parse(JSON.stringify(report)); // Clone the report to avoid mutation
              }).unsubscribe();

            dr.reportDetails = {
              ...dr.reportDetails,
              materialTests: {
                ...dr.reportDetails.materialTests,
                external: {
                  openTests,
                  closedTests,
                },
              },
            };

            // Return the success action with the updated daily report
            return FetchExternalTestsSuccess({
              dailyReport: dr,
            });
          }),
          catchError(() => {
            return of(FetchExternalTestsFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchExternalTestsSuccess.type) {
              // Code to execute on API Success Action dispatch

            } else if (action.type === FetchExternalTestsFailure.type) {
              // Code to execute on API Failure Action dispatch

            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  addContractorStatus$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AddContractorStatusRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.addContractorStatus(payload).pipe(
          map(() => {

            return AddContractorStatusSuccess({});
          }),
          catchError(() => {
            return of(AddContractorStatusFailure());
          }),
          tap((action: any) => {
            if (action.type === AddContractorStatusSuccess.type) {
              // Code to execute on API Success Action dispatch
              // this.commonService.closeDialog.next();
              this.projectsService.addContractorStatusSuccess.next(true);
            } else if (action.type === AddContractorStatusFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  fetchIssues$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchIssuesRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchIssues(payload).pipe(
          map(response => {
            const { data } = response;
            const consolidatedIssues = data.filter(o => o.entity_type === 'site');

            return FetchIssuesSuccess({
              consolidatedIssues,
            });
          }),
          catchError(() => {
            return of(FetchIssuesFailure());
          }),
          tap((action: any) => {
            if (action.type === FetchIssuesSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchIssuesFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  deleteVideoFromMuxDb$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(DeleteVideoFromMuxDbRequest),
      map((action: any) => {
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.deleteVideoFromMuxDb(payload).pipe(
          map(() => {
            return DeleteVideoFromMuxDbSuccess({});
          }),
          catchError(() => {
            return of(DeleteVideoFromMuxDbFailure());
          }),
          tap((action: any) => {
            if (action.type === DeleteVideoFromMuxDbSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === DeleteVideoFromMuxDbFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          }),
        ),
      ),
    ),
  );

  private datePipe: DatePipe;
  fetchChangeOrderFilterInSideNav$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchChangeOrderFilterInSideNavRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.projectsService.fetchChangeOrderFilterInSideNav(payload).pipe(
          map(response => {
            const { data } = response;
            const { change_orders } = data;
            const tempData = change_orders?.map((item) => {
              const formatedDate = this.datePipe.transform(item.created_at, 'MMM d, y');
              return {
                ...item,
                key: item.id,
                value: item.order_number ? `${item.order_number} (${formatedDate})` : `${formatedDate}`,
              };
            });

            const sortedTempData = tempData.sort((a, b) => {
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
            return FetchChangeOrderFilterInSideNavSuccess({
              list: sortedTempData,
            });
          }),
          catchError(() => {
            return of(FetchChangeOrderFilterInSideNavFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchChangeOrderFilterInSideNavSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchChangeOrderFilterInSideNavFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private projectsService: ProjectsService,
    private issueService: IssueService,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private injector: Injector,
    private dailyReportService: DailyReportService,
  ) {
    this.datePipe = this.injector.get(DatePipe);
  }

  calculateRollupQuantity(fields, fieldValues, rollupFormula) {
    const fieldValuesMap = {};

    fields.forEach(field => {
      const fieldValue = fieldValues.find(fv => fv?.id === field?.id);
      if (fieldValue && fieldValue.value !== null && fieldValue.value !== undefined) {
        fieldValuesMap[field.name] = fieldValue.value;
      }
    });

    const hasValidFields = fieldValuesMap.hasOwnProperty('length_f') && fieldValuesMap.hasOwnProperty('width_f');

    if (hasValidFields) {
      const evaluatedQuantity = Parser.evaluate(rollupFormula, fieldValuesMap);
      return !isNaN(evaluatedQuantity) ? evaluatedQuantity.toFixed(2) : 0;
    }

    return 0;
  }
}
