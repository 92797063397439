<div class="wrapper">
  <div class="total-section flex flex-col">
    <div class="quantity-total-label text-type-14">QUANTITY:</div>
    <div class="quantity-total-value text-type-11">{{ quantityTotal | number: '1.2-2' }}</div>
  </div>
  <div class="line-items-section">
    <ng-container *ngFor="let lineItem of lineItems">
      <div
        [class.is-expanded]="selectedLineItemId === lineItem.id"
        class="line-item-accordion"
      >
        <!--        LINE ITEM HEADER SECTION       -->
        <div class="line-item-header flex justify-between items-center">
          <div
            (click)="selectLineItem(lineItem.id)"
            class="left flex flex-row justify-start items-center gap-[12px] grow"
          >
            <div class="details">
              <div
                *ngIf="!lineItem.sources?.length; else sourcesHeader"
                class="text-type-8"
              >
                {{ lineItem.created_at | date: 'MMMM dd, YYYY, hh:mm a' }}
              </div>
              <ng-template #sourcesHeader>
                <div
                  class="left flex flex-row justify-start items-center gap-[12px] grow"
                >
                  <div *ngIf="lineItem?.sources && lineItem?.sources.length;"
                       class="profile-image-section flex flex-row justify-center items-center">
                    <ng-container *ngIf="lineItem?.sources.length === 1;else multipleProfileImages">
                      <ng-container *ngIf="lineItem?.sources[0]?.source_inspector?.url;else avatar">
                        <img [src]="lineItem?.sources[0]?.source_inspector?.url" alt="image" class="image" />
                      </ng-container>
                      <ng-template #avatar>
                        <div
                          class="image avatar text-type-7 flex justify-center items-center">
                          {{ createAvatar(lineItem?.sources[0]?.source_inspector) }}
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-template #multipleProfileImages>
                      <div
                        class="multiple-profile-image-container flex flex-row justify-center items-center gap-[10px]">
                        <ng-container *ngIf="lineItem?.sources[0]?.source_inspector?.url;else avatar">
                          <img [src]="lineItem?.sources[0]?.source_inspector?.url" alt="image"
                               class="image first-profile image" />
                        </ng-container>
                        <ng-template #avatar>
                          <div
                            class="image first-profile image avatar text-type-7 flex justify-center items-center">
                            {{ createAvatar(lineItem?.sources[0]?.source_inspector) }}
                          </div>
                        </ng-template>
                        <div
                          class="image profile-counter text-type-7 flex justify-center items-center">
                          +{{ lineItem?.sources.length - 1 }}
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <div class="details text-type-8">
                    <div class="heading">
                      {{ lineItem.sourcesSection.heading | date: 'MMMM dd, YYYY, hh:mm a' }}
                    </div>
                    <div class="subheading">
                      {{ lineItem.sourcesSection.subheading }}
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="right flex justify-center items-center gap-[10px]">
            <div class="chevron">
              <mat-icon
                (click)="selectLineItem(lineItem.id)"
                [svgIcon]="selectedLineItemId === lineItem.id ? 'chevron-up' : 'chevron-down'"
                class="chevron-icon"
              />
            </div>
            <div *ngIf="!disabled" class="menu-section">
              <mat-icon
                [matMenuTriggerFor]="menu"
                class="menu-icon"
              >more_horiz
              </mat-icon>
              <mat-menu #menu="matMenu">
                <button (click)="deleteLineItem(lineItem.id)" mat-menu-item>
                  <mat-icon svgIcon="trash" />
                  Delete line item
                </button>
              </mat-menu>
            </div>
          </div>
        </div>

        <ng-container *ngIf="lineItem.sources?.length <= 1">

          <!--        LINE ITEM BODY        -->
          <div *ngIf="lineItem?.sourcesSection" class="total-section flex flex-col">
            <div
              class="survey_data-map border h-[80px] my-[40px] rounded-[12px]"
            >
              <app-survey-data-map
                [data]="lineItem?.sourcesSection"
              />
            </div>
          </div>
          <div [id]="lineItem.id" class="line-item-content">
            <!--          ROLL UP FALSE SECTION          -->
            <ng-container *ngIf="bidItem?.rollup === false; else allowRollup">
              <form [formGroup]="getFormGroup(form, lineItem.id)">
                <app-number-input
                  [isDecimalAllowed]="true"
                  [label]="'Quantity (' + bidItem.uom + ')'"
                  [parentFormGroup]="getFormGroup(form, lineItem.id)"
                  class="each-item-quantity-block"
                  controlName="quantity"
                />
              </form>
            </ng-container>
            <!--          ROLL UP SECTION          -->
            <ng-template #allowRollup>
              <!--          ROLL UP QUANTITY EDITABLE FALSE SECTION          -->
              <ng-container *ngIf="bidItem?.rollup_quantity_editable === false; else allowEditableRollup">

                <ng-container *ngIf="isFormReady">
                  <form
                    [formGroup]="getFormGroup(form, lineItem.id)"
                  >
                    <div class="quantity-section each-item-quantity-block">
                      <div class="disabled-quantity-label text-type-13">QUANTITY ({{ bidItem.uom }}):</div>
                      <div class="disabled-quantity-value text-type-11">{{
                          getFormGroup(form,
                            lineItem.id).controls?.quantity.value
                        }}
                      </div>
                    </div>
                    <div class="fields-row input-block">
                      <ng-container *ngFor="let field of bidItem.fields">
                        <ng-container *ngIf="field.type === 'number' || false">
                          <app-number-input
                            [controlName]="field.id"
                            [isDecimalAllowed]="true"
                            [label]="field.label + ' (' + field.uom + ')'"
                            [parentFormGroup]="getFormGroup(form, lineItem.id)"
                          />
                        </ng-container>
                      </ng-container>
                    </div>
                  </form>
                </ng-container>
              </ng-container>
              <!--          ROLL UP QUANTITY EDITABLE SECTION          -->
              <ng-template #allowEditableRollup>
                <ng-container *ngIf="isFormReady">
                  <form [formGroup]="getFormGroup(form, lineItem.id)">
                    <div class="fields-row each-item-quantity-block">
                      <app-dropdown
                        [label]="'QUANTITY (' + bidItem.uom + ')'"
                        [options]="autoCalculateOptions"
                        [parentFormGroup]="getFormGroup(form, lineItem.id)"
                        controlName="valueType"
                      />
                      <ng-container
                        *ngIf="getFormGroup(form, lineItem.id)?.controls?.valueType?.value ===
                      'dynamic_rollup'; else manualEntry"
                      >
                        <div class="quantity-section">
                          <div class="spacer h-[30px]"></div>
                          <div class="disabled-quantity-value text-type-11">{{
                              getFormGroup(form,
                                lineItem.id).controls?.quantity?.value
                            }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-template #manualEntry>
                        <app-number-input
                          [isDecimalAllowed]="true"
                          [parentFormGroup]="getFormGroup(form, lineItem.id)"
                          controlName="quantity"
                        />
                      </ng-template>
                    </div>
                    <div class="fields-row input-block">
                      <ng-container *ngFor="let field of bidItem.fields">
                        <ng-container *ngIf="field.type === 'number' || false">
                          <app-number-input
                            [controlName]="field.id"
                            [isDecimalAllowed]="true"
                            [label]="field.label + ' (' + field.uom + ')'"
                            [parentFormGroup]="getFormGroup(form, lineItem.id)"
                          />
                        </ng-container>
                      </ng-container>
                    </div>
                  </form>
                </ng-container>
              </ng-template>
            </ng-template>
          </div>
        </ng-container>


        <div *ngIf="lineItem.sources?.length > 1">
          <!--        LINE ITEM BODY        -->
          <div class="total-section flex flex-col">
            <div class="survey_data-map border h-[80px] my-[40px] rounded-[12px]">
              <app-survey-data-map
                [data]="lineItem.sourcesSection"
              />
            </div>
          </div>

          <form
            *ngIf="bidItem?.rollup_quantity_editable === true"
            [formGroup]="getFormGroup(form, lineItem.id)">
            <div class="fields-row each-item-quantity-block">
              <app-dropdown
                [label]="'QUANTITY (' + bidItem.uom + ')'"
                [options]="autoCalculateOptions"
                [parentFormGroup]="getFormGroup(form, lineItem.id)"
                controlName="valueType"
              />
              <ng-container
                *ngIf="getFormGroup(form, lineItem.id)?.controls?.valueType?.value ===
                      'dynamic_rollup'; else manualEntry"
              >
                <div class="quantity-section">
                  <div class="spacer h-[30px]"></div>
                  <div class="disabled-quantity-value text-type-11">{{
                      getFormGroup(form,
                        lineItem.id).controls?.quantity?.value
                    }}
                  </div>
                </div>
              </ng-container>
              <ng-template #manualEntry>
                <app-number-input
                  [hint]="'Calculated: ' + calculatedValues[lineItem.id]"
                  [isDecimalAllowed]="true"
                  [parentFormGroup]="getFormGroup(form, lineItem.id)"
                  controlName="quantity"
                />
              </ng-template>
            </div>
          </form>
          <div
            *ngIf="bidItem?.rollup_quantity_editable === false">
            <div class="total-section flex flex-col">
              <div class="quantity-total-label text-type-14">QUANTITY:</div>
              <div class="quantity-total-value text-type-11">{{ quantityTotal | number: '1.2-2' }}</div>
            </div>
          </div>
          <ng-container *ngFor="let source of lineItem.sourcesSection.sourcesList">

            <div
              [class.rollup-false]='bidItem?.rollup === false'
              [id]="source.id"
              class="line-item-content"
            >
              <!--          ROLL UP FALSE SECTION          -->
              <ng-container *ngIf="bidItem?.rollup === false; else allowRollup">
                <div class="inspector-info flex flex-row justify-start items-center gap-[12px] grow mb-[20px]">
                  <div class="profile-image-section flex flex-row justify-center items-center">
                    <ng-container *ngIf="source?.source_inspector?.url;else avatar">
                      <img [src]="source?.source_inspector?.url" alt="image" class="image" />
                    </ng-container>
                    <ng-template #avatar>
                      <div
                        class="image avatar text-type-7 flex justify-center items-center">
                        {{ createAvatar(source?.source_inspector) }}
                      </div>
                    </ng-template>
                  </div>
                  <div class="details text-type-14">
                    <div class="source-heading">
                      {{ source.source_created_at| date: 'hh:mm a' }}
                    </div>
                    <div class="source-subheading">
                      {{ source?.source_inspector }}
                    </div>
                  </div>
                </div>
                <form [formGroup]="getFormGroup(form, lineItem.id+ '_' +source.id)">
                  <app-number-input
                    [isDecimalAllowed]="true"
                    [label]="'Quantity (' + bidItem.uom + ')'"
                    [parentFormGroup]="getFormGroup(form, lineItem.id+ '_' +source.id)"
                    class="each-item-quantity-block"
                    controlName="quantity"
                  />
                </form>
              </ng-container>
              <!--          ROLL UP SECTION          -->
              <ng-template #allowRollup>
                <!--          ROLL UP QUANTITY EDITABLE FALSE SECTION          -->
                <ng-container *ngIf="bidItem?.rollup_quantity_editable === false; else allowEditableRollup">

                  <ng-container *ngIf="isFormReady">
                    <form
                      [formGroup]="getFormGroup(form, lineItem.id+ '_' +source.id)"
                    >
                      <div class="fields-row input-block">
                        <ng-container *ngFor="let field of bidItem.fields">
                          <ng-container *ngIf="field.type === 'number' || false">
                            <app-number-input
                              [controlName]="field.id"
                              [isDecimalAllowed]="true"
                              [label]="field.label + ' (' + field.uom + ')'"
                              [parentFormGroup]="getFormGroup(form, lineItem.id+ '_' +source.id)"
                            />
                          </ng-container>
                        </ng-container>
                      </div>
                    </form>
                  </ng-container>
                </ng-container>
                <!--          ROLL UP QUANTITY EDITABLE SECTION          -->
                <ng-template #allowEditableRollup>
                  <ng-container *ngIf="isFormReady">
                    <form [formGroup]="getFormGroup(form, lineItem.id+ '_' +source.id)">
                      <div class="fields-row input-block">
                        <div class="quantity-section">
                          <div class="disabled-quantity-label text-type-13 !text-[11px] mt-[5px] !pb-[7px] "
                          >
                            QUANTITY ({{ bidItem.uom }}):
                          </div>
                          <div class="disabled-quantity-value text-type-11">{{
                              getFormGroup(form,
                                lineItem.id + '_' + source.id).controls?.quantity?.value
                            }}
                          </div>
                        </div>
                        <ng-container *ngFor="let field of bidItem.fields">
                          <ng-container *ngIf="field.type === 'number' || false">
                            <app-number-input
                              [controlName]="field.id"
                              [isDecimalAllowed]="true"
                              [label]="field.label + ' (' + field.uom + ')'"
                              [parentFormGroup]="getFormGroup(form, lineItem.id+ '_' +source.id)"
                            />
                          </ng-container>
                        </ng-container>
                      </div>
                    </form>
                  </ng-container>
                </ng-template>
              </ng-template>
            </div>
          </ng-container>
        </div>

        <form [formGroup]="getFormGroup(form, lineItem.id)">
          <div
            *ngIf="!!getFormGroup(form, lineItem.id).controls?.comment"
            class="comment-section"
          >
            <app-text-area
              [parentFormGroup]="getFormGroup(form, lineItem.id)"
              controlName="comment"
              label="COMMENT"
            />
          </div>
          <app-pictures-section
            (onDelete)="!disabled && deletePicture($event)"
            *ngIf="selectedLineItemMediaFiles.length"
            [isEditable]="!disabled"
            [pictures]="selectedLineItemMediaFiles"
            class="pictures-section"
          />
          <app-location-section
            (onDelete)="!disabled && deleteLocation(lineItem.id)"
            *ngIf="showLocationSection(lineItem.id)"
            [isEditable]="!disabled"
            [latitude]="getFormGroup(form, lineItem.id)?.controls?.latitude?.value"
            [longitude]="getFormGroup(form, lineItem.id)?.controls?.longitude?.value"
            class="location-section"
          />
        </form>
      </div>
    </ng-container>
  </div>
</div>
