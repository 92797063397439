import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DailyReportService } from '../../../../daily-report.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bid-item-details-wrapper',
  templateUrl: './bid-item-details-wrapper.component.html',
  styleUrls: ['./bid-item-details-wrapper.component.scss'],
})
export class BidItemDetailsWrapperComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() options: any = {
    showActionsSection: true,
  };
  disableAddMenu = false;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private dailyReportService: DailyReportService,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.dailyReportService.disableAddMenu
      .pipe(takeUntil(this.onDestroy))
      .subscribe((d) => {
        this.disableAddMenu = d;
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
