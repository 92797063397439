<div class="wrapper">
  <div *ngIf="fields?.length > 0" class="fields-row input-block grid gap-4 grid-cols-2">
    <ng-container *ngFor="let field of fields">
      <ng-container *ngIf="field?.type === 'string' || false">
        <app-text-input
          [controlName]="field?.id"
          [hint]="field?.hintText"
          [label]="field?.label"
          [parentFormGroup]="form"
          [warning]="warningMessages[field?.id]"
          [error]="errorMessages[field?.id]"
        />
      </ng-container>
      <ng-container *ngIf="field?.type === 'number' || false">
        <app-number-input
          [controlName]="field?.id"
          [hint]="field?.hintText"
          [label]="field?.label"
          [parentFormGroup]="form"
          [suffixText]="field?.uom"
          [warning]="warningMessages[field?.id]"
          [error]="errorMessages[field?.id]"
        />
      </ng-container>
    </ng-container>
  </div>
  <div
    *ngIf="!!form?.controls['comment']"
    class="comment-section"
  >
    <app-text-area
      [parentFormGroup]="form"
      controlName="comment"
      label="COMMENT"
    />
  </div>
  <app-pictures-section
    (onDelete)="deletePicture($event)"
    *ngIf="selectedMediaFiles?.length"
    [pictures]="selectedMediaFiles"
    class="pictures-section"
  />
  <app-location-section
    (onDelete)="deleteLocation()"
    *ngIf="showLocationSection()"
    [latitude]="form?.controls?.latitude?.value"
    [longitude]="form?.controls?.longitude?.value"
    class="location-section"
  />
</div>
