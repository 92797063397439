<div class="wrapper">
  <ng-container *ngFor="let station of data?.list">
    <div
      [class.is-expanded]="selectedStationId === station?.['stationId']"
      [id]="station?.['stationId']"

      class="itd-accordion"
    >
      <div class="itd-header flex justify-between items-center">
        <div
          (click)="selectStation(station?.['stationId'])"
          class="left flex justify-start items-center gap-[12px] grow"
        >
          <div class="details text-type-8">
            {{ station?.['stationName'] }}
          </div>
        </div>
        <div class="right flex justify-center items-center gap-[10px]">
          <div class="chevron">
            <mat-icon
              (click)="selectStation(station?.['stationId'])"
              [svgIcon]="selectedStationId === station?.['stationId'] ? 'chevron-up' : 'chevron-down'"
              class="chevron-icon"
            />
          </div>
          <div class="menu-section">
            <mat-icon
              [matMenuTriggerFor]="menu"
              class="menu-icon"
            >more_horiz
            </mat-icon>
            <mat-menu #menu="matMenu">
              <button (click)="addTime(station)" mat-menu-item>
                <mat-icon svgIcon="plus" />
                Add Time
              </button>
              <button (click)="deleteStationItem(station)" mat-menu-item>
                <mat-icon svgIcon="trash" />
                Delete Station
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div
        *ngIf="selectedStationId === station?.['stationId']"
        class="station-content"
      >
        <ng-container *ngFor="let eachItem of station?.['list']">
          <div
            [class.is-expanded]="selectedItdId === eachItem.id"
            [id]="eachItem.id"
            class="itd-accordion"
          >
            <div class="itd-header flex justify-between items-center">
              <div
                (click)="selectItd(eachItem.id)"
                class="left flex justify-start items-center gap-[12px] grow"
              >
                <div class="details text-type-8">
                  {{ eachItem?.['record_time'] }}
                  <span *ngIf="eachItem?.['fieldValueSummary']" class="text-type-10">
                    ({{ eachItem?.['fieldValueSummary'] }}                  )
                  </span>
                </div>
              </div>
              <div class="right flex justify-center items-center gap-[10px]">
                <div class="chevron">
                  <mat-icon
                    (click)="selectItd(eachItem.id)"
                    [svgIcon]="selectedItdId === eachItem.id ? 'chevron-up' : 'chevron-down'"
                    class="chevron-icon"
                  />
                </div>
                <div class="menu-section">
                  <mat-icon
                    [matMenuTriggerFor]="menu"
                    class="menu-icon"
                  >more_horiz
                  </mat-icon>
                  <mat-menu #menu="matMenu">
                    <button (click)="deleteItdItem(eachItem)" mat-menu-item>
                      <mat-icon svgIcon="trash" />
                      Delete Time
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
            <div
              *ngIf="selectedItdId === eachItem.id"
              class="itd-content"
            >
              <app-internal-test-details-form
                (onDeletePicture)="deletePicture($event)"
                [fields]="eachItem?.['field_values']"
                [form]="getFormGroup(form,selectedStationId,selectedItdId)"
                [selectedMediaFiles]="selectedMediaFiles"
              />
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div *ngIf="addNewStation" class="station-dropdown">
    <div class="search-section mt-[40px]">
      <form [formGroup]="searchStationForm">
        <app-text-input
          [parentFormGroup]="searchStationForm"
          controlName="searchStation"
          label="STATION"
          placeholder="Search for station"
          suffixIcon="search"
        />
      </form>

      <div *ngIf="filteredStationList?.length > 0 && focusedIn" class="station-list">
        <div
          (click)="addStation(eachStation)"
          *ngFor="let eachStation of filteredStationList; let first = first; let last = last"
          [class.first]="first"
          [class.last]="last"
          class="each-item flex justify-start items-center gap-[16px]"
        >
          {{ eachStation?.name }}
        </div>
      </div>
    </div>
  </div>
</div>
