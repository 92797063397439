import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import * as $ from 'jquery';
import { DailyReportService } from '../../../../daily-report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';
import { takeUntil } from 'rxjs/operators';
import { siteDetails } from '../../../../../../../../core/projects.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-internal-test-details-with-station',
  templateUrl: './internal-test-details-with-station.component.html',
  styleUrls: ['./internal-test-details-with-station.component.scss'],
})
export class InternalTestDetailsWithStationComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup = new FormGroup({});
  @Input() data: any;
  selectedStationId: string;
  selectedItdId: string;
  selectedMediaFiles: any[];
  stationList: any[];

  searchStationForm = new FormGroup({
    searchStation: new FormControl(''),
  });
  filteredStationList = [];
  focusedIn = false;
  addNewStation = false;
  createNewStation = false;
  headingId: any;
  searchText: string;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private dailyReportService: DailyReportService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(params => {
        if (params && params['selectedId']) {
          this.headingId = params['selectedId'].split('_')[0];
        }
      });

    this.dailyReportService.addNewStation
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.addNewStation = true;
      });

    this.dailyReportService.add
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.addNewStation = true;
      });

    this.store.select(siteDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data) {
          this.stationList = data?.headings?.filter((heading: any) => heading?.id === this.headingId)[0]?.stations;
          this.filteredStationList = data?.headings?.filter((heading: any) => heading?.id === this.headingId)[0]?.stations;
        }
      });

    this.dailyReportService.stationsList
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.stationList = data;
        this.filteredStationList = data;
      });
  }

  ngOnInit(): void {
    this.dailyReportService.disableAddMenu.next(true);

    this.searchStationForm.controls.searchStation.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(searchText => {
        this.searchText = searchText;
        if (searchText) {
          this.filteredStationList = this.stationList.filter(item => {
            const name = item?.name;

            if (name?.toLowerCase().includes(searchText.toLowerCase())) {
              return true;
            }
          });
        } else {
          this.searchText = '';
          this.filteredStationList = this.stationList;
        }
      });

    this.commonService.focusedIn
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data) {
          this.focusedIn = data;
          if (this.searchText) {
            this.filteredStationList = this.stationList.filter(item => {
              const name = item.name;

              if (name?.toLowerCase().includes(this.searchText.toLowerCase())) {
                return true;
              }
            });
          } else {
            this.filteredStationList = this.stationList;
          }
        } else {
          this.filteredStationList = [];
          this.focusedIn = false;
        }
      });

    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        const items = Array.isArray(data) ? data : [data];
        const formGroup = this.getFormGroup(this.form, this.selectedStationId, this.selectedItdId);

        items.forEach(eachItem => {
          if (eachItem?.['fileType'] === 'picture') {
            let pictures = formGroup?.get('pictures')?.value || '[]';
            pictures = JSON.parse(pictures);
            pictures.push(eachItem);
            formGroup?.get('pictures')?.setValue(JSON.stringify(pictures));
          }

          if (eachItem?.['fileType'] === 'video') {
            let videos = formGroup?.get('videos')?.value || '[]';
            videos = JSON.parse(videos);
            videos.push(eachItem);
            formGroup?.get('videos')?.setValue(JSON.stringify(videos));
          }
        });

        const tempPictureData = this.getSelectedPictures() ?? [];
        const tempVideosData = this.getSelectedVideos() ?? [];
        const allNewItems = [...tempPictureData, ...tempVideosData];

        allNewItems.forEach(item => {
          const existingItem = this.selectedMediaFiles.find(ei => ei.id === item.id);

          if (!existingItem) {
            this.selectedMediaFiles.push(item);
          }
        });

        this.cdRef.detectChanges();
      });

    this.dailyReportService.addComment
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          const tempControl = this.getFormGroup(this.form, this.selectedStationId, this.selectedItdId).get('comment');

          if (!tempControl) {
            this.getFormGroup(this.form, this.selectedStationId, this.selectedItdId)?.addControl('comment', new FormControl(null));
          }
        }
      });

    this.dailyReportService.selectedLocation
      .pipe(takeUntil(this.onDestroy))
      .subscribe((location) => {
        if (this.form) {
          this.getFormGroup(this.form, this.selectedStationId, this.selectedItdId)?.patchValue(location);
        }
      });
  }

  selectStation(id: string) {
    this.selectedItdId = null;
    this.selectedStationId = this.selectedStationId === id ? null : id;
  }

  deleteStationItem(station) {
    this.dailyReportService.deleteStation.next(station);
  }

  selectItd(id) {
    this.selectedItdId = this.selectedItdId === id ? this.data.id : id;

    const tempPictureData = this.getSelectedPictures() ?? [];
    const tempVideosData = this.getSelectedVideos() ?? [];
    this.selectedMediaFiles = [...tempPictureData, ...tempVideosData];

    setTimeout(() => {
      const ele = $(`#${id}`)[0];

      if (ele) {
        ele.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 100);

    if (this.selectedItdId && this.selectedStationId) {
      this.dailyReportService.disableAddMenu.next(false);
    } else {
      this.dailyReportService.disableAddMenu.next(true);
    }
  }

  deleteItdItem(item) {
    this.dailyReportService.deleteTimeFromStation.next(item);
  }

  deletePicture() {

  }

  getFormGroup(form: FormGroup, stationId: string, controlName: string): FormGroup {
    if (form && form.get(stationId) instanceof FormGroup) {
      const stationGroup = form.get(stationId) as FormGroup;

      if (stationGroup.get(controlName)) {
        return stationGroup.get(controlName) as FormGroup;
      }
    }

    return new FormGroup({});
  }

  getSelectedPictures() {
    const pictures = this.getFormGroup(this.form, this.selectedStationId, this.selectedItdId)?.get('pictures')?.value || '[]';
    return JSON.parse(pictures);
  }

  getSelectedVideos() {
    const videos = this.getFormGroup(this.form, this.selectedStationId, this.selectedItdId)?.get('videos')?.value || '[]';
    return JSON.parse(videos);
  }

  addTime(station) {
    this.selectedStationId = station.stationId;
    this.dailyReportService.addNewTimeToStation.next(station.station);

    this.router.navigate([], {
      queryParams: {
        selectedStationId: this.selectedStationId,
      },
      queryParamsHandling: 'merge',
    });
  }

  addStation(eachStation: any) {
    this.addNewStation = false;
    this.dailyReportService.newStationSelected.next(eachStation);
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
