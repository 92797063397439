import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';
import { DailyReportService } from '../../../../daily-report.service';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { projectDetails, siteDetails } from '../../../../../../../../core/projects.selectors';
import * as moment from 'moment/moment';
import { DeleteVideoFromMuxDbRequest } from '../../../../../../../../core/projects.actions';

@Component({
  selector: 'app-create-internal-test',
  templateUrl: './create-internal-test.component.html',
  styleUrls: ['./create-internal-test.component.scss'],
})
export class CreateInternalTestComponent implements OnInit, OnDestroy {
  @Input() data: any;
  disableAddMenu = false;
  headerLevelForm = new FormGroup({
    onSiteTest: new FormControl({ value: null, disabled: false }),
    bidItem: new FormControl({ value: null, disabled: false }),
    heading: new FormControl({ value: null, disabled: false }),
  });

  form = new FormGroup({});
  errorMessages: any = {};
  errorMessageMap = {
    onSiteTest: {
      required: 'This field is required.',
    },
    bidItem: {
      required: 'This field is required.',
    },
    heading: {
      required: 'This field is required.',
    },
  };
  bidItemOptions = [];
  onSiteTestOptions = [];
  headingsOptions = [];
  itdToDelete: any = [];
  videoToBeDeletedFromMuxDb: any = [];
  itType: any;
  internalTestDetails: any;
  itWithStation = false;

  internalTestDetailsWithOutStations = {
    type: 'withoutStations',
    list: [],
  };

  internalTestDetailsWithStations = {
    type: 'withStations',
    list: [],
  };
  fields: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store,
    private commonService: CommonService,
    private dailyReportService: DailyReportService,
    private projectsService: ProjectsService,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private datePipe: DatePipe,
  ) {

  }

  ngOnInit() {
    this.dailyReportService.disableAddMenu
      .pipe(takeUntil(this.onDestroy))
      .subscribe((d) => {
        this.disableAddMenu = d;
        this.cdRef.detectChanges();
      });

    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.bidItemOptions = data?.bid_items;
        this.onSiteTestOptions = data?.internal_tests;
      });

    this.store.select(siteDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.headingsOptions = data?.headings;
      });

    this.headerLevelForm.controls.onSiteTest.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          const tempData = this.onSiteTestOptions.filter(o => o.id === data)[0];
          this.fields = tempData?.fields;
          this.itType = tempData?.record_by_station ? 'withStations' : 'withoutStations';
          this.dailyReportService.setItType.next(this.itType);
          this.itWithStation = this.itType === 'withStations';
        }
        this.cdRef.detectChanges();
      });

    this.headerLevelForm.controls.heading.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        const stationsList = this.headingsOptions.filter(o => o.id === data)[0].stations;
        this.dailyReportService.stationsList.next(stationsList);
      });

    this.dailyReportService.add
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.createForm();
      });

    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        let form = this.form;
        if (this.itType === 'withStations') {
          const fc: any = {};

          // tslint:disable-next-line:forin
          for (const fv in this.form.controls) {
            // tslint:disable-next-line:forin
            for (const fvc in this.form.controls[fv].controls) {
              fc[fvc] = this.form.controls[fv].get(fvc);
            }
          }

          form = new FormGroup<any>(fc);
        }

        this.dailyReportService.formatAndSaveInternalTest(this.headerLevelForm, form, this.itdToDelete);

        if (this.videoToBeDeletedFromMuxDb.length) {
          this.videoToBeDeletedFromMuxDb.forEach(videoId => {
            this.store.dispatch(DeleteVideoFromMuxDbRequest({ payload: videoId }));
          });
        }
      });

    this.dailyReportService.newStationSelected
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.createForm(data);
      });
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.headerLevelForm, this.errorMessageMap, currentField)),
    };
  }

  createForm(data?) {
    if (this.itType === 'withoutStations') {
      const id = `new_${new Date().getTime()}`;
      this.internalTestDetailsWithOutStations.list.push({
        id,
        internal_test_id: this.internalTestDetails?.list[0]?.internal_test_id,
        record_time: this.datePipe.transform(moment().toISOString(), 'HH:mm:ss'),
        comment: '',
        latitude: '',
        longitude: '',
        pictures: [],
        videos: [],
        station: null,
        fieldValueSummary: null,
        field_values: this.fields,
      });

      const parentFormBuilder = {};

      this.internalTestDetailsWithOutStations.list.forEach((field: any) => {

        const index = field?.field_values?.findIndex((fv: any) => fv?.id === 'time');

        if (index === -1) {
          field.field_values = [
            {
              id: 'time',
              value: field?.record_time,
              type: 'string',
              label: 'Time',
              validation_rules: { required: true },
            },
            ...field.field_values,
          ];
        } else {
          field.field_values[index] = {
            id: 'time',
            value: field?.record_time,
            type: 'string',
            label: 'Time',
            validation_rules: { required: true },
          };
        }

        parentFormBuilder[field.id] = this.fb.group({});

        field.field_values.map(o => {
          const validators = [];

          if (o?.validation_rules?.required) {
            validators.push(Validators.required);
          }
          if (o?.validation_rules?.min !== undefined) {
            validators.push(Validators.min(o?.validation_rules?.min));
          }
          if (o?.validation_rules?.max !== undefined) {
            validators.push(Validators.max(o?.validation_rules?.max));
          }

          parentFormBuilder[field.id].addControl(o.id, new FormControl(o.value, [...validators]));

          parentFormBuilder[field.id].controls[o.id].validation_rules = o.validation_rules;
          parentFormBuilder[field.id].controls[o.id].warning_rules = o.range_values;

        });

        if (field.comment) {
          parentFormBuilder[field.id].addControl('comment', new FormControl(field.comment));
        }

        parentFormBuilder[field.id].addControl('pictures', new FormControl(JSON.stringify(field.pictures)));
        parentFormBuilder[field.id].addControl('videos', new FormControl(JSON.stringify(field.videos)));

        parentFormBuilder[field.id].addControl('latitude', new FormControl(field.latitude));
        parentFormBuilder[field.id].addControl('longitude', new FormControl(field.longitude));
      });

      this.form = new FormGroup(parentFormBuilder);
    } else if (this.itType === 'withStations' && data) {
      const id = `new_${new Date().getTime()}`;
      this.internalTestDetailsWithStations.list.push({
        stationId: data.id,
        stationName: data.name,
        station: data,
        list: [{
          id,
          internal_test_id: this.internalTestDetails?.list[0]?.internal_test_id,
          record_time: this.datePipe.transform(moment().toISOString(), 'HH:mm:ss'),
          comment: '',
          latitude: '',
          longitude: '',
          pictures: [],
          videos: [],
          station: data,
          fieldValueSummary: null,
          field_values: this.fields,
        }],
      });

      const parentFormBuilder = this.fb.group({});
      this.internalTestDetailsWithStations.list.forEach((detail: any) => {
        const childFormBuilder = {};
        detail?.list?.forEach((eachDetail: any) => {
          const timeIndex = eachDetail?.field_values?.findIndex((fv: any) => fv?.id === 'time');

          if (timeIndex === -1) {
            eachDetail.field_values = [
              {
                id: 'time',
                value: eachDetail?.record_time,
                type: 'string',
                label: 'Time',
                validation_rules: { required: true },
              },
              ...eachDetail.field_values,
            ];
          } else {
            eachDetail.field_values[timeIndex] = {
              id: 'time',
              value: eachDetail?.record_time,
              type: 'string',
              label: 'Time',
              validation_rules: { required: true },
            };
          }

          childFormBuilder[eachDetail.id] = this.fb.group({});
          eachDetail.field_values.map(o => {
            const validators = [];

            if (o?.validation_rules?.required) {
              validators.push(Validators.required);
            }
            if (o?.validation_rules?.min !== undefined) {
              validators.push(Validators.min(o?.validation_rules?.min));
            }
            if (o?.validation_rules?.max !== undefined) {
              validators.push(Validators.max(o?.validation_rules?.max));
            }

            childFormBuilder[eachDetail.id].addControl(o.id, new FormControl(o.value, [...validators]));

            childFormBuilder[eachDetail.id].controls[o.id].validation_rules = o.validation_rules;
            childFormBuilder[eachDetail.id].controls[o.id].warning_rules = o.range_values;
          });

          if (eachDetail.comment) {
            childFormBuilder[eachDetail.id].addControl('comment', new FormControl(eachDetail.comment));
          }

          childFormBuilder[eachDetail.id].addControl('pictures', new FormControl(JSON.stringify(eachDetail.pictures)));
          childFormBuilder[eachDetail.id].addControl('videos', new FormControl(JSON.stringify(eachDetail.videos)));

          childFormBuilder[eachDetail.id].addControl('latitude', new FormControl(eachDetail.latitude));
          childFormBuilder[eachDetail.id].addControl('longitude', new FormControl(eachDetail.longitude));

          childFormBuilder[eachDetail.id].addControl('station_id', new FormControl(eachDetail?.station.id));
        });
        parentFormBuilder.addControl(detail.stationId, new FormGroup(childFormBuilder));
      });
      this.form = parentFormBuilder;
    }

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.projectsService.isAnyFormDirty.next(true);
      });

    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

}
