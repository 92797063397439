import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../state/app.state';
import { SaveDailyReportRequest } from '../../../../../core/projects.actions';
import { dailyReport, projectDetails, siteDetails } from '../../../../../core/projects.selectors';
import { ProjectsService } from '../../../../../core/projects.service';
import {
  BidItemDetailsWrapperComponent,
} from '../daily-report-components/detail-components/bid-item-details/bid-item-details-wrapper/bid-item-details-wrapper.component';
import { DailyReportService } from '../daily-report.service';
import {
  ActivityItemDetailsComponent,
} from '../daily-report-components/detail-components/activitiy-details/activity-item-details/activity-item-details.component';
import {
  DeliveryDetailsWrapperComponent,
} from '../daily-report-components/detail-components/delivery-details/delivery-details-wrapper/delivery-details-wrapper.component';
import {
  UsageDetailsWrapperComponent,
} from '../daily-report-components/detail-components/usage-details/usage-details-wrapper/usage-details-wrapper.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../../../../../shared/components';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  LaborDetailsWrapperComponent,
} from '../daily-report-components/detail-components/labor-details/labor-details-wrapper/labor-details-wrapper.component';
import {
  EquipmentDetailsWrapperComponent,
} from '../daily-report-components/detail-components/equipment-details/equipment-details-wrapper/equipment-details-wrapper.component';
import * as $ from 'jquery';
import { MatTabGroup } from '@angular/material/tabs';
import {
  WeatherDetailsWrapperComponent,
} from '../daily-report-components/detail-components/weather-details/weather-details-wrapper/weather-details-wrapper.component';
import {
  IssueDetailsWrapperComponent,
} from '../daily-report-components/detail-components/issue-details/issue-details-wrapper/issue-details-wrapper.component';
import {
  InternalTestDetailsWrapperComponent,
} from '../daily-report-components/detail-components/internal-test-details/internal-test-details-wrapper/internal-test-details-wrapper.component';

@Component({
  selector: 'app-daily-report-two',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss'],
})
export class DailyReportComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('deliveryUsageTabs') deliveryUsageTabs: MatTabGroup;
  @ViewChild('laborEquipmentTabs') laborEquipmentTabs: MatTabGroup;
  weather: any = {};
  bidItems: any[] = [];
  deliveries: any[] = [];
  usages: any[] = [];
  materialTesting: any = {};
  issues: any[] = [];
  activities: any[] = [];
  labors: any[] = [];
  equipments: any[] = [];
  openIssues: any[] = [];
  closedIssues: any[] = [];
  expandedSection = '';
  dailyReport: any;
  reportId;
  site: any;
  project: any;
  deliveriesCount = 0;
  usagesCount = 0;
  internalMaterialTestingCount = 0;
  externalMaterialTestingCount = 0;
  laborCount = 0;
  equipmentCount = 0;
  selectedId: string;
  expandedSectionTabIndex = 0;
  isOpenIssuesPanelExpanded = false;
  isClosedIssuesPanelExpanded = false;
  openExternalTestCount = 0;
  closedExternalTestCount = 0;
  protected readonly Object = Object;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private projectsService: ProjectsService,
    private dailyReportService: DailyReportService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        this.expandedSection = qp.expandedSection || null;
        this.cdRef.detectChanges();

        if (qp.expandedSection === undefined) {
          this.projectsService.closeRightPanel.next();
          this.expandedSectionTabIndex = 0;
        } else {
          $(`#${qp.expandedSection}`)[0].scrollIntoView({
            behavior: 'smooth', // or "auto" or "instant"
            block: 'start', // or "end"
          });

          if (qp.idx !== undefined) {
            this.expandedSectionTabIndex = qp.idx;
          } else {
            this.expandedSectionTabIndex = 0;
          }
        }

        if (qp.selectedId) {
          this.selectedId = qp.selectedId;
          // this.cdRef.detectChanges();
        } else {
          this.selectedId = null;
          this.projectsService.closeRightPanel.next();
        }
      });

    this.store.select(dailyReport)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((report: any) => {
        this.dailyReport = JSON.parse(JSON.stringify(report));
        this.bidItems = this.dailyReport?.reportDetails?.bidItems || [];
        this.activities = this.dailyReport?.reportDetails?.activities || [];
        this.weather = this.dailyReport?.reportDetails?.weather || {};
        this.deliveries = this.dailyReport?.reportDetails?.deliveries || [];
        this.usages = this.dailyReport?.reportDetails?.usages || [];
        this.issues = this.dailyReport?.reportDetails?.issues || [];
        this.labors = this.dailyReport?.reportDetails?.labors || [];
        this.equipments = this.dailyReport?.reportDetails?.equipments || [];
        this.materialTesting = this.dailyReport?.reportDetails?.materialTests || [];

        if (this.materialTesting) {
          this.internalMaterialTestingCount = this.materialTesting?.internal?.groupedByInternalTestIdCount || 0;
          // TODO: DO NOT DELETE; NEEDED WHILE WORKING ON EXTERNAL TEST
          this.openExternalTestCount = this.materialTesting.external?.openTests?.length || 0;
          this.closedExternalTestCount = this.materialTesting.external?.closedTests?.length || 0;
          this.externalMaterialTestingCount = (this.openExternalTestCount + this.closedExternalTestCount) || 0;
        }

        let deliveriesCount = 0;
        let usagesCount = 0;

        this.deliveries?.forEach(delivery => {
          if (delivery.subType === 'bidItemWithMaterials') {
            deliveriesCount += delivery.materials.length;
          } else {
            deliveriesCount++;
          }
        });

        this.deliveriesCount = deliveriesCount;

        this.usages?.forEach(usage => {
          if (usage.subType === 'bidItemWithMaterials') {
            usagesCount += usage.materials.length;
          } else {
            usagesCount++;
          }
        });

        this.usagesCount = usagesCount;

        this.laborCount = 0;
        this.labors.forEach(o => this.laborCount += o.quantity);

        this.equipmentCount = 0;
        this.equipments.forEach(o => this.equipmentCount += o.quantity);

        this.openIssues = this.issues.filter(issue => issue.status === 'open').sort((a, b) => {
          return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
        });

        this.closedIssues = this.issues.filter(issue => issue.status === 'closed').sort((a, b) => {
          return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
        });

        this.cdRef.detectChanges();
      });

    this.dailyReportService.save
      .pipe(takeUntil(this.onDestroy))
      .subscribe((params) => {
        this.saveDailyReport(params.type, params.data, params.options);
      });

    this.dailyReportService.delete
      .pipe(takeUntil(this.onDestroy))
      .subscribe((params) => {
        this.deleteFromDailyReport(params.type, params.id);
      });

    this.store.select(siteDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(site => {
        this.site = site;
      });

    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(project => {
        this.project = project;
      });
  }

  handleExpansion(type) {
    if (this.expandedSection === type) {
      this.router.navigate([], {
        queryParams: {
          expandedSection: null,
          idx: null,
          selectedId: null,
        },
        queryParamsHandling: 'merge',
      }).then(() => {
        this.projectsService.isAnyFormDirty.next(false);

        if (this.deliveryUsageTabs) {
          this.deliveryUsageTabs._handleClick = this.onTabChange.bind(this);
        }

        if (this.laborEquipmentTabs) {
          this.laborEquipmentTabs._handleClick = this.onTabChange.bind(this);
        }
      });
    } else {
      const isFormDirty = this.projectsService.isAnyFormDirty.getValue();

      if (isFormDirty) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: true,
          data: new ConfirmDialogModel(
            'Unsaved changes',
            `There are unsaved changes.<br/>Are you sure you want to discard?`,
          ),
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.projectsService.closeRightPanel.next();

            this.router.navigate([], {
              queryParams: {
                expandedSection: type,
                idx: '0',
                selectedId: null,
              },
              queryParamsHandling: 'merge',
            }).then(() => {
              this.projectsService.isAnyFormDirty.next(false);

              if (this.deliveryUsageTabs) {
                this.deliveryUsageTabs._handleClick = this.onTabChange.bind(this);
              }

              if (this.laborEquipmentTabs) {
                this.laborEquipmentTabs._handleClick = this.onTabChange.bind(this);
              }
            });
          }
        });
      } else {
        this.router.navigate([], {
          queryParams: {
            expandedSection: type,
            idx: '0',
            selectedId: null,
          },
          queryParamsHandling: 'merge',
        }).then(() => {
          this.projectsService.isAnyFormDirty.next(false);

          if (this.deliveryUsageTabs) {
            this.deliveryUsageTabs._handleClick = this.onTabChange.bind(this);
          }

          if (this.laborEquipmentTabs) {
            this.laborEquipmentTabs._handleClick = this.onTabChange.bind(this);
          }
        });
      }


    }
  }

  triggerOpenDetailsPanel(type, data, index?) {
    const isFormDirty = this.projectsService.isAnyFormDirty.getValue();

    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes.<br/>Are you sure you want to discard?`,
        ),
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.openDetailsPanel(type, data, index);
          this.projectsService.isAnyFormDirty.next(false);
        }
      });
    } else {
      this.openDetailsPanel(type, data, index);
    }
  }

  openDetailsPanel(type, data, index?) {

    this.router.navigate([], {
      queryParams: {
        selectedId: type === 'internalTest'
          ? `${data.headingId}_${data.testId}` : data?.id,
        ...(type === 'internalTest' && { itType: data?.type }),
      },
      queryParamsHandling: 'merge',
    }).then(() => {
      this.projectsService.isAnyFormDirty.next(false);
      switch (type) {
        case 'weather':
          this.projectsService.openRightPanel.next({
            component: WeatherDetailsWrapperComponent,
            data: JSON.parse(JSON.stringify(data)),
          });
          break;

        case 'bidItem':
          this.projectsService.openRightPanel.next({
            component: BidItemDetailsWrapperComponent,
            data: JSON.parse(JSON.stringify(data)),
          });
          break;

        case 'activity':
          this.projectsService.openRightPanel.next({
            component: ActivityItemDetailsComponent,
            data: JSON.parse(JSON.stringify({ ...data, index })),
          });
          break;

        case 'issue':
          this.projectsService.openRightPanel.next({
            component: IssueDetailsWrapperComponent,
            data: JSON.parse(JSON.stringify(data)),
          });
          break;

        case 'delivery':
          this.projectsService.openRightPanel.next({
            component: DeliveryDetailsWrapperComponent,
            data: JSON.parse(JSON.stringify(data)),
          });
          break;

        case 'usage':
          this.projectsService.openRightPanel.next({
            component: UsageDetailsWrapperComponent,
            data: JSON.parse(JSON.stringify(data)),
          });
          break;

        case 'labor':
          this.projectsService.openRightPanel.next({
            component: LaborDetailsWrapperComponent,
            data: {
              laborDetails: JSON.parse(JSON.stringify(data)),
            },
          });
          break;

        case 'equipment':
          this.projectsService.openRightPanel.next({
            component: EquipmentDetailsWrapperComponent,
            data: {
              equipmentDetails: JSON.parse(JSON.stringify(data)),
            },
          });
          break;

        case 'internalTest':
          this.projectsService.openRightPanel.next({
            component: InternalTestDetailsWrapperComponent,
            data: {
              internalTestDetails: JSON.parse(JSON.stringify(data)),
            },
          });
          break;

        default:
          break;
      }
    });
  }

  saveDailyReport(type, data, options) {
    let baseDailyReport;
    if (Array.isArray(data)) {
      data = data.map(o => {

        if (o?.id?.includes('new_')) {
          o.id = null;
        }

        return o;
      });
    }

    this.store.select(dailyReport)
      .subscribe(dr => {
        const { reportDetails, ...rest } = JSON.parse(JSON.stringify(dr));
        baseDailyReport = rest;

        switch (type) {
          case 'weather':
            baseDailyReport.weather = {
              ...data,
            };
            break;

          case 'bidItems':
            const bidItemsList = baseDailyReport?.bid_items?.filter(o => o.bid_item_id !== options?.bidItem.id) || [];

            baseDailyReport.bid_items = [
              ...bidItemsList,
              ...data,
            ];

            break;

          case 'activities':
            const activitiesList = baseDailyReport?.activities?.filter(o => o.id !== data[0]?.id);

            baseDailyReport.activities = [
              ...activitiesList,
              ...data,
            ];

            break;

          case 'delivery':
            let deliveries = baseDailyReport?.material_deliveries?.map(o => ({
              ...o,
              material_id: o.material?.id || null,
            })) || [];

            // Remove elements which are to be updated by the new elements
            deliveries = deliveries?.filter(o => !data.map(m => m.id).includes(o?.id)) || [];

            baseDailyReport.material_deliveries = [
              ...deliveries,
              ...data,
            ];

            // Remove lines with have been deleted
            baseDailyReport.material_deliveries = baseDailyReport.material_deliveries.filter(o => !options.linesToDelete.includes(o.id));

            break;

          case 'usage':
            let usages = baseDailyReport?.material_deliveries?.map(o => ({
              ...o,
              material_id: o.material?.id || null,
            })) || [];

            usages = usages?.filter(o => !data.map(m => m.id).includes(o?.id)) || [];

            baseDailyReport.material_deliveries = [
              ...usages,
              ...data,
            ];

            break;

          case 'labor':
            const laborList = baseDailyReport?.labor?.filter(o => o.id !== data[0]?.id);

            data.forEach(o => {
              o.sub_contractor_id = o.sub_contractor?.id;
            });

            baseDailyReport.labor = [
              ...laborList,
              ...data,
            ];

            break;

          case 'equipment':
            const equipmentList = baseDailyReport?.equipment?.filter(o => o.id !== data[0]?.id);

            data.forEach(o => {
              o.sub_contractor_id = o.sub_contractor?.id;
            });

            baseDailyReport.equipment = [
              ...equipmentList,
              ...data,
            ];

            break;

          case 'issues':
            const issuesList = baseDailyReport?.issues?.filter(o => o.id !== data[0]?.id);

            baseDailyReport.issues = [
              ...issuesList,
              ...data,
            ];

            break;

          case 'internalTest':
            let internalTestsList = baseDailyReport?.internal_tests?.map(o => ({
              ...o,
              internal_test_id: o.internal_test?.id || null,
              station_id: o.station?.id || null,
              heading_id: o.heading?.id || null,
              bid_item_id: o.bid_item?.id || null,
            })) || [];
            internalTestsList = internalTestsList?.filter(o => !data.map(m => m.id).includes(o?.id)) || [];

            baseDailyReport.internal_tests = [
              ...internalTestsList,
              ...data,
            ];

            baseDailyReport.internal_tests = baseDailyReport.internal_tests.filter(o => !options.itdToDelete.includes(o.id));

            break;

          default:
            break;
        }

        baseDailyReport.material_deliveries =
          this.removeInvalidAndDuplicatesFromMaterialDeliveries(baseDailyReport.material_deliveries);

        baseDailyReport.internal_tests = this.manageInternalTests(baseDailyReport.internal_tests);

        baseDailyReport = this.manageMedia(baseDailyReport);

        this.store.dispatch(SaveDailyReportRequest({
          payload: {
            params: {
              siteId: this.route.snapshot.params.siteId,
            },
            dailyReport: baseDailyReport,
          },
        }));
      }).unsubscribe();
  }

  deleteFromDailyReport(type, id) {
    let baseDailyReport;

    this.store.select(dailyReport)
      .subscribe(dr => {
        const { reportDetails, ...rest } = JSON.parse(JSON.stringify(dr));
        baseDailyReport = rest;

        switch (type) {
          case 'bidItems':
            const bidItemsList = baseDailyReport?.bid_items?.filter(o => o.bid_item_id !== id);
            baseDailyReport.bid_items = bidItemsList;
            break;

          case 'activities':
            const activities = baseDailyReport?.activities?.filter(o => o.id !== id);
            baseDailyReport.activities = activities;
            break;

          case 'delivery':
          case 'usage':
            // Here id is a special case, where it will be used as an array
            const deliveryAndUsage = baseDailyReport?.material_deliveries?.filter(o => !id.includes(o.id));
            baseDailyReport.material_deliveries = deliveryAndUsage.map(o => ({
              ...o,
              ...(o.material ? { material_id: o.material?.id || null } : {}),
            }));
            break;

          case 'labor':
            const labor = baseDailyReport?.labor?.filter(o => o.id !== id);
            baseDailyReport.labor = labor;
            break;

          case 'equipment':
            const equipment = baseDailyReport?.equipment?.filter(o => o.id !== id);
            baseDailyReport.equipment = equipment;
            break;

          case 'internalTest':
            // Here id is a special case, where it will be used as an array
            const internalTests = baseDailyReport?.internal_tests?.filter(o => !id.includes(o.id));
            baseDailyReport.internal_tests = internalTests.map(o => ({
              ...o,
              ...(o.heading ? { heading_id: o.heading.id } : {}),
              ...(o.station ? { station_id: o.station.id } : {}),
            }));
            break;

          default:
            break;
        }

        baseDailyReport = this.manageMedia(baseDailyReport);
        this.store.dispatch(SaveDailyReportRequest({
          payload: {
            params: {
              siteId: this.route.snapshot.params.siteId,
            },
            dailyReport: baseDailyReport,
          },
        }));
      }).unsubscribe();
  }

  manageMedia(report) {
    report.bid_items = report.bid_items?.map(bidItem => {
      bidItem.pictures = bidItem.pictures?.map(o => o.id);
      bidItem.videos = bidItem.videos?.map(o => o.id);
      return bidItem;
    });

    report.activities = report.activities?.map(activity => {
      activity.pictures = activity.pictures?.map(o => o.id);
      activity.videos = activity.videos?.map(o => o.id);
      return activity;
    });

    report.material_deliveries = report.material_deliveries?.map(materialDelivery => {
      materialDelivery.pictures = materialDelivery.pictures?.map(o => o.id);
      materialDelivery.videos = materialDelivery.videos?.map(o => o.id);
      return materialDelivery;
    });

    report.internal_tests = report.internal_tests?.map((internalTest: any) => {
      internalTest.pictures = internalTest.pictures?.map(o => o.id);
      internalTest.videos = internalTest.videos?.map(o => o.id);
      return internalTest;
    });

    return report;
  }

  removeInvalidAndDuplicatesFromMaterialDeliveries(arr) {
    // Step 1: Remove objects with invalid data
    const filteredArray = arr.filter(item => {
      const hasInvalidData =
        (!item.latitude || item.longitude) &&
        item.quantity === 0 &&
        item.received === null &&
        item.used === null &&
        Array.isArray(item.pictures) && item.pictures.length === 0 &&
        !item.comment;

      return !hasInvalidData;
    });

    const duplicateHeaders = filteredArray.filter((item) => {
      return item.quantity === 0 &&
        item.received === null &&
        item.used === null; // Keep all other items
    }) || [];

    const remaining = filteredArray.filter((item) => {
      return item.quantity !== 0 ||
        item.received !== null ||
        item.used !== null;
    }) || [];

    return [
      ...remaining,
      ...(duplicateHeaders[duplicateHeaders.length - 1] ? [duplicateHeaders[duplicateHeaders.length - 1]] : []),
    ];
  }

  onTabChange(tab) {
    const isFormDirty = this.projectsService.isAnyFormDirty.getValue();

    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes.<br/>Are you sure you want to discard?`,
        ),
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate([], {
            queryParams: {
              idx: `${tab.position}`,
              selectedId: null,
            },
            queryParamsHandling: 'merge',
          }).then(() => {
            this.projectsService.isAnyFormDirty.next(false);
            this.projectsService.closeRightPanel.next(null);
          });
        }
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          idx: `${tab.position}`,
          selectedId: null,
        },
        queryParamsHandling: 'merge',
      }).then(() => {
        this.projectsService.isAnyFormDirty.next(false);
        this.projectsService.closeRightPanel.next(null);
      });
    }
  }

  ngAfterViewInit() {
    if (this.deliveryUsageTabs) {
      this.deliveryUsageTabs._handleClick = this.onTabChange.bind(this);
    }

    if (this.laborEquipmentTabs) {
      this.laborEquipmentTabs._handleClick = this.onTabChange.bind(this);
    }
  }


  manageInternalTests(internalTests) {
    internalTests.forEach((internalTest) => {
      if (internalTest.heading) {
        internalTest.heading_id = internalTest?.heading?.id;
      } else {
        internalTest.heading_id = null;
      }

      if (internalTest.station) {
        internalTest.station_id = internalTest.station?.id;
      } else {
        internalTest.station_id = null;
      }
    });
    return internalTests;
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
