import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../state/app.state';
import {
  ClearDailyReportRequest,
  FetchDailyReportRequest,
  FetchDailyReportsByDateRequest,
  FetchIssueTypesRequest,
  FetchProjectDetailsRequest,
} from '../../../../../core/projects.actions';
import { ProjectsService } from '../../../../../core/projects.service';
import { DailyReportService } from '../daily-report.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../../../../../shared/components';

@Component({
  selector: 'app-daily-report-wrapper',
  templateUrl: './daily-report-wrapper.component.html',
  styleUrls: ['./daily-report-wrapper.component.scss'],
})
export class DailyReportWrapperComponent implements OnInit, OnDestroy {
  date = '';
  reportId = '';
  triggerDateChange = 0;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private projectsService: ProjectsService,
    private dailyReportService: DailyReportService,
    public dialog: MatDialog,
  ) {
    this.store.dispatch(FetchIssueTypesRequest({
      payload: {
        projectId: this.route.snapshot.params['projectId'],
      },
    }));
  }

  ngOnInit() {
    this.store.dispatch(FetchProjectDetailsRequest({
      payload: {
        projectId: this.route.snapshot.params.projectId,
        qp: {
          include: [
            'cover_photo',
            'owner_organization',
            'delivery_tracking_fields',
            'materials',
            {
              bid_items: [
                'rollup_fields',
                'materials',
                {
                  fields: ['recent_fields'],
                },
                {
                  headings: [
                    'stations',
                    'alignment',
                  ],
                },
              ],
            },
            {
              internal_tests: [
                'bid_item_ids',
                'site_ids',
                'fields',
              ],
            },
            {
              sites: [
                'delivery_tracking_fields',
                {
                  headings: ['stations'],
                },
              ],
            },
          ],
        },
      },
    }));

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (
          qp.date === undefined ||
          (
            qp.date &&
            !moment(qp.date, 'YYYY-MM-DD').isValid()
          )
        ) {
          this.router.navigate([], {
            queryParams: {
              date: moment().format('YYYY-MM-DD'),
            },
            queryParamsHandling: 'merge',
          });
        } else if (
          qp.date &&
          moment(qp.date, 'YYYY-MM-DD').isValid() &&
          qp.date !== this.date
        ) {
          this.date = qp.date;
          this.fetchReportsList();
        }

        if (!qp.reportId) {
          this.reportId = '';
          if (qp.fs === undefined) {
            this.store.dispatch(ClearDailyReportRequest());
          }
        } else {
          if (qp.reportId !== this.reportId) {
            this.reportId = qp.reportId;
            this.fetchReportById(this.reportId);
          }
        }
      });

    this.dailyReportService.fetchDailyReportById
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.fetchReportById(this.reportId);
      });
  }

  fetchReportsList() {
    this.router.navigate([], {
      queryParams: {
        date: this.date,
        idx: null,
      },
      queryParamsHandling: 'merge',
    }).then(() => {
      if (this.route.snapshot.queryParams.fs === undefined) {
        this.store.dispatch(ClearDailyReportRequest());
      }
      this.store.dispatch(FetchDailyReportsByDateRequest({
        payload: {
          project: { id: this.route.snapshot.params.projectId },
          site: this.route.snapshot.params.siteId,
          qp: {
            date: this.date,
            include: [
              'createdBy',
              'statusText',
              'site_id',
              'next-actions',
            ],
            total: 1000,
          },
        },
      }));
    });
  }

  handleDateChange(date) {
    const isFormDirty = this.projectsService.isAnyFormDirty.getValue();

    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes.<br/>Are you sure you want to discard?`,
        ),
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.projectsService.closeRightPanel.next();
          this.projectsService.isAnyFormDirty.next(false);
          this.router.navigate([], {
            queryParams: {
              date: moment(date).format('YYYY-MM-DD'),
              reportId: null,
            },
            queryParamsHandling: 'merge',
          }).then(() => {
            this.projectsService.isAnyFormDirty.next(false);
          });
        } else {
          this.triggerDateChange = new Date().getTime();
        }
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          date: moment(date).format('YYYY-MM-DD'),
          reportId: null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  fetchReportById(reportId: string) {
    this.store.dispatch(ClearDailyReportRequest());
    this.store.dispatch(FetchDailyReportRequest({
      payload: {
        qp: {
          include: [
            'project_id',
            'site_id',
            'site',
            'project',
            'next-actions',
            {
              bid_items: [
                'pictures',
                'videos',
                {
                  sources: [
                    'source_group_id',
                  ],
                },
                {
                  bid_item: ['fields'],
                },
                'heading',
                {
                  annotations: ['drawing'],
                },
                'bid_item_id',
                'heading_id',
                'station_id',
                'field_values',
                'station',
              ],
            },
            {
              material_deliveries: [
                'pictures',
                'videos',
                {
                  annotations: ['drawing'],
                },
                'field_values',
                'bid_item_id',
                'bid_item',
                'material',
                'latitude',
                'longitude',
                'field_values',
              ],
            },
            {
              activities: [
                'pictures',
                'videos',
                'address',
                {
                  annotation: ['drawing'],
                },
              ],
            },
            {
              internal_tests: [
                'bid_item',
                'internal_test',
                'internal_test_id',
                'station',
                'heading',
                'field_values',
                'pictures',
                'videos',
                {
                  annotations: ['drawing'],
                },
              ],
            },
            {
              issues: [
                'entity',
                'pictures',
                'videos',
                'attachments',
                'bid_item',
                'annotation',
                {
                  createdBy: ['picture'],
                },
                {
                  closedBy: ['picture'],
                },
              ],
            },
            {
              labor: [
                'sub_contractor',
              ],
            },
            {
              equipment: [
                'sub_contractor',
              ],
            },
          ],
        },
        reportId,
      },
    }));
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
