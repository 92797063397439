<div class="wrapper">
  <div class="heading text-type-6">
    {{ data?.testName }}
  </div>
  <div class="subheading text-type-10">
    {{data.bidItemName || '&nbsp;'}}
  </div>
  <div
    *ngIf="data.headingName"
    class="subheading text-type-10"
  >
    {{ data.headingName }}
    <span *ngIf="data.type==='withStations'">({{ data?.list?.length }} station{{ data?.list?.length > 1 ? 's' : '' }})</span>
  </div>
</div>
