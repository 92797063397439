import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';
import { projectDetails, siteDetails } from '../../../../../../../../core/projects.selectors';
import { takeUntil } from 'rxjs/operators';
import { DailyReportService } from '../../../../daily-report.service';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import { DatePipe } from '@angular/common';
import { DeleteVideoFromMuxDbRequest } from '../../../../../../../../core/projects.actions';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-internal-test-details',
  templateUrl: './internal-test-details.component.html',
  styleUrls: ['./internal-test-details.component.scss'],
})
export class InternalTestDetailsComponent implements OnInit, OnDestroy {
  @Input() data: any;

  headerLevelForm: FormGroup = new FormGroup({
    onSiteTest: new FormControl({ value: null, disabled: false }),
    bidItem: new FormControl({ value: null, disabled: false }),
    heading: new FormControl({ value: null, disabled: false }),
  });

  form = new FormGroup({});
  errorMessages: any = {};
  errorMessageMap = {
    onSiteTest: {
      required: 'This field is required.',
    },
    bidItem: {
      required: 'This field is required.',
    },
    heading: {
      required: 'This field is required.',
    },
  };
  bidItemOptions = [];
  onSiteTestOptions = [];
  headingsOptions = [];
  itdToDelete: any = [];
  videoToBeDeletedFromMuxDb: any = [];
  itType: any;
  internalTestDetails: any;
  internalTestWithStation = false;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store,
    private commonService: CommonService,
    private dailyReportService: DailyReportService,
    private projectsService: ProjectsService,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(params => {
        this.itType = params.itType === 'withStations' || params.itType === 'withoutStations' ? params.itType : this.itType;
      });

    this.dailyReportService.add
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form && this.itType === 'withoutStations') {
          const id = `new_${new Date().getTime()}`;

          this.dailyReportService.disableAddMenu.next(false);
          const newElement = {
            id,
            internal_test_id: this.internalTestDetails?.list[0]?.internal_test_id,
            record_time: this.datePipe.transform(moment().toISOString(), 'HH:mm:ss'),
            comment: '',
            latitude: '',
            longitude: '',
            pictures: [],
            videos: [],
            station: null,
            fieldValueSummary: null,
            field_values: this.internalTestDetails?.list[0].field_values?.map(o => ({
                ...o,
                id: o.id,
                value: null,
              }),
            ),
          };
          this.addNew(newElement);
        } else if (this.form && this.itType === 'withStations') {
          this.dailyReportService.addNewStation.next(true);
        }
      });

    this.dailyReportService.newStationSelected
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        const index = this.internalTestDetails?.list.findIndex(itd => itd.stationId === data.id);
        if (index === -1) {
          const tempData = {
            stationId: data.id,
            stationName: data.name,
            list: [],
          };

          const id = `new_${new Date().getTime()}`;

          const newElement = {
            id,
            internal_test_id: this.internalTestDetails?.list[0].list[0]?.internal_test_id,
            station: data,
            record_time: this.datePipe.transform(moment().toISOString(), 'HH:mm:ss'),
            comment: '',
            latitude: '',
            longitude: '',
            pictures: [],
            videos: [],
            fieldValueSummary: null,
            field_values: this.internalTestDetails?.list[0].list[0]?.field_values?.map(o => ({
                ...o,
                id: o.id,
                value: null,
              }),
            ),
          };

          tempData.list.push(newElement);

          this.addNew(tempData);
        } else {
          this.commonService.notification('Station already exists!', 'warning');
        }
      });

    this.dailyReportService.addNewTimeToStation
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        const tempData = this.internalTestDetails?.list?.filter(o => o.stationId === data.id)[0];

        const id = `new_${new Date().getTime()}`;

        const newElement = {
          id,
          internal_test_id: this.internalTestDetails?.list[0]?.list[0]?.internal_test_id,
          station: data,
          record_time: this.datePipe.transform(moment().toISOString(), 'HH:mm:ss'),
          comment: '',
          latitude: '',
          longitude: '',
          pictures: [],
          videos: [],
          fieldValueSummary: null,
          field_values: this.internalTestDetails?.list[0]?.list[0]?.field_values?.map(o => ({
              ...o,
              id: o.id,
              value: null,
            }),
          ),
        };

        tempData.list.push(newElement);

        this.addNew(tempData, true);
      });

    this.dailyReportService.deleteStation
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.deleteStation(data);
      });

    this.dailyReportService.deleteTimeFromStation
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.deleteTimeFromStation(data);
      });
  }

  ngOnInit() {
    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.bidItemOptions = data?.bid_items;
        this.onSiteTestOptions = data?.internal_tests;
      });

    this.store.select(siteDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.headingsOptions = data?.headings;
      });

    this.headerLevelForm.patchValue({
      onSiteTest: this.data?.internalTestDetails?.testId,
      bidItem: this.data?.internalTestDetails?.bidItemId,
      heading: this.data?.internalTestDetails?.headingId,
    }, { emitEvent: false });

    this.internalTestWithStation = this.data?.internalTestDetails?.type === 'withStations';

    this.createForm(this.data?.internalTestDetails);

    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        let form = this.form;
        if (this.itType === 'withStations') {
          const fc: any = {};

          // tslint:disable-next-line:forin
          for (const fv in this.form.controls) {
            // tslint:disable-next-line:forin
            for (const fvc in this.form.controls[fv].controls) {
              fc[fvc] = this.form.controls[fv].get(fvc);
            }
          }

          form = new FormGroup<any>(fc);
        }

        this.dailyReportService.formatAndSaveInternalTest(this.headerLevelForm, form, this.itdToDelete);

        if (this.videoToBeDeletedFromMuxDb.length) {
          this.videoToBeDeletedFromMuxDb.forEach(videoId => {
            this.store.dispatch(DeleteVideoFromMuxDbRequest({ payload: videoId }));
          });
        }
      });

    this.dailyReportService.triggerDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        const elementIds = [];
        if (this.data.internalTestDetails?.type !== 'withStations') {
          this.data.internalTestDetails?.list?.forEach((detail: any) => {
            elementIds.push(detail.id);
          });
        } else {
          this.data.internalTestDetails?.list.forEach((detail: any) => {
            detail.list.forEach((o: any) => {
              elementIds.push(o.id);
            });
          });
        }

        this.dailyReportService.delete.next({
          type: 'internalTest',
          id: elementIds,
        });
      });

    this.dailyReportService.itdToDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.itdToDelete.push(data);
      });

    this.dailyReportService.videoToBeDeletedFromMuxDb
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.videoToBeDeletedFromMuxDb.push(data);
      });
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.headerLevelForm, this.errorMessageMap, currentField)),
    };
  }

  createForm(internalTestDetails: any) {
    this.internalTestDetails = internalTestDetails;

    if (internalTestDetails?.type === 'withoutStations') {
      internalTestDetails?.list.forEach((detail: any) => {
        const index = detail?.field_values?.findIndex((fv: any) => fv?.id === 'time');

        if (index === -1) {
          detail.field_values = [
            {
              id: 'time',
              value: detail?.record_time,
              type: 'string',
              label: 'Time',
              validation_rules: { required: true },
            },
            ...detail.field_values,
          ];
        } else {
          detail.field_values[index] = {
            id: 'time',
            value: detail?.record_time,
            type: 'string',
            label: 'Time',
            validation_rules: { required: true },
          };
        }
      });


      const parentFormBuilder = {};

      internalTestDetails?.list.forEach((itd: any) => {
        parentFormBuilder[itd.id] = this.fb.group({});
        itd.field_values.map(o => {
          const validators = [];

          if (o?.validation_rules?.required) {
            validators.push(Validators.required);
          }
          if (o?.validation_rules?.min !== undefined) {
            validators.push(Validators.min(o?.validation_rules?.min));
          }
          if (o?.validation_rules?.max !== undefined) {
            validators.push(Validators.max(o?.validation_rules?.max));
          }

          parentFormBuilder[itd.id].addControl(o.id, new FormControl(o.value, [...validators]));

          parentFormBuilder[itd.id].controls[o.id].validation_rules = o.validation_rules;
          parentFormBuilder[itd.id].controls[o.id].warning_rules = o.range_values;

        });

        if (itd.comment) {
          parentFormBuilder[itd.id].addControl('comment', new FormControl(itd.comment));
        }

        parentFormBuilder[itd.id].addControl('pictures', new FormControl(JSON.stringify(itd.pictures)));
        parentFormBuilder[itd.id].addControl('videos', new FormControl(JSON.stringify(itd.videos)));

        parentFormBuilder[itd.id].addControl('latitude', new FormControl(itd.latitude));
        parentFormBuilder[itd.id].addControl('longitude', new FormControl(itd.longitude));
      });

      this.form = new FormGroup(parentFormBuilder);

    } else if (internalTestDetails?.type === 'withStations') {
      const parentFormBuilder = this.fb.group({});
      internalTestDetails?.list?.forEach((detail: any) => {
        const childFormBuilder = {};
        detail?.list?.forEach((eachDetail: any) => {
          const timeIndex = eachDetail?.field_values?.findIndex((fv: any) => fv?.id === 'time');

          if (timeIndex === -1) {
            eachDetail.field_values = [
              {
                id: 'time',
                value: eachDetail?.record_time,
                type: 'string',
                label: 'Time',
                validation_rules: { required: true },
              },
              ...eachDetail.field_values,
            ];
          } else {
            eachDetail.field_values[timeIndex] = {
              id: 'time',
              value: eachDetail?.record_time,
              type: 'string',
              label: 'Time',
              validation_rules: { required: true },
            };
          }

          childFormBuilder[eachDetail.id] = this.fb.group({});
          eachDetail.field_values.map(o => {
            const validators = [];

            if (o?.validation_rules?.required) {
              validators.push(Validators.required);
            }
            if (o?.validation_rules?.min !== undefined) {
              validators.push(Validators.min(o?.validation_rules?.min));
            }
            if (o?.validation_rules?.max !== undefined) {
              validators.push(Validators.max(o?.validation_rules?.max));
            }

            childFormBuilder[eachDetail.id].addControl(o.id, new FormControl(o.value, [...validators]));

            childFormBuilder[eachDetail.id].controls[o.id].validation_rules = o.validation_rules;
            childFormBuilder[eachDetail.id].controls[o.id].warning_rules = o.range_values;
          });

          if (eachDetail.comment) {
            childFormBuilder[eachDetail.id].addControl('comment', new FormControl(eachDetail.comment));
          }

          childFormBuilder[eachDetail.id].addControl('pictures', new FormControl(JSON.stringify(eachDetail.pictures)));
          childFormBuilder[eachDetail.id].addControl('videos', new FormControl(JSON.stringify(eachDetail.videos)));

          childFormBuilder[eachDetail.id].addControl('latitude', new FormControl(eachDetail.latitude));
          childFormBuilder[eachDetail.id].addControl('longitude', new FormControl(eachDetail.longitude));

          childFormBuilder[eachDetail.id].addControl('station_id', new FormControl(eachDetail.station.id));
        });
        parentFormBuilder.addControl(detail.stationId, new FormGroup(childFormBuilder));
      });
      this.form = parentFormBuilder;
    }

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.projectsService.isAnyFormDirty.next(true);
      });

    this.cdRef.detectChanges();
  }

  addNew(newElement: any, addNewTimeToStation?: boolean) {
    if (!addNewTimeToStation) {
      this.internalTestDetails?.list.push(newElement);
    }

    const values = this.form.getRawValue();

    this.createForm(this.internalTestDetails);

    this.form.patchValue(values);

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.projectsService.isAnyFormDirty.next(true);
      });

    this.cdRef.detectChanges();
  }

  deleteStation(station: any) {
    const tempData = this.internalTestDetails?.list.filter(o => o.stationId === station.stationId);

    tempData.forEach((eachDetail: any) => {
      eachDetail.list?.forEach((o: any) => {
        if (!o.id.includes('new_')) {
          this.dailyReportService.itdToDelete.next(o.id);
        }
      });
    });

    this.form.removeControl(station.stationId);
    this.internalTestDetails.list = this.internalTestDetails.list.filter(item => item.stationId !== station.stationId);
    this.cdRef.detectChanges();
  }

  deleteTimeFromStation(data: any) {
    if (!data.id.includes('new_')) {
      this.dailyReportService.itdToDelete.next(data.id);
    }

    this.internalTestDetails.list.forEach(item => {
      const index = item.list.findIndex(o => o.id === data.id);
      if (index !== -1) {
        item.list.splice(index, 1);
      }
    });

    this.projectsService.isAnyFormDirty.next(true);
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

}
